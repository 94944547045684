import {createContext, useContext, useEffect, useState} from "react"
import useScreenSize from "../hooks/useScreenSize"

const ResponsiveContext = createContext()

export function useResponsive() {
  return useContext(ResponsiveContext)
}

export function ResponsiveProvider({children}) {
  const [isPhone, setIsPhone] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [isVerticalTablet, setIsVerticalTablet] = useState(false)
  const [dashboardLayout, setDashboardLayout] = useState({
    right: true,
    left: true,
  })
  const screenSize = useScreenSize()

  useEffect(() => {
    setDashboardLayout({
      right: screenSize.width > 900,
      left: true,
    })
  }, [screenSize])

  useEffect(() => {
    setIsPhone(screenSize.width < 600)
    setIsTablet(screenSize.width < 1080)
    setIsVerticalTablet(screenSize.width < 900)
  }, [screenSize])

  const value = {
    isPhone,
    isTablet,
    isVerticalTablet,
    dashboardLayout,
    setDashboardLayout,
  }

  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
}
