export class UserService {
  constructor(userRepository) {
    this.repo = userRepository
  }

  async storeUser(userData) {
    try {
      const storedUser = await this.repo.saveUserToDB(userData)
      return storedUser
    } catch (error) {
      console.error("Error in userService storing user:", error)
      throw error
    }
  }
}
