import React from "react"
import {useEffect, useState} from "react"
import DescriptionIcon from "../icons/DescriptionIcon"
import CloseIcon from "../icons/CloseIcon"
import {FormRow, SubmitButton} from "../forms/FormUtils"
import {DateInput} from "../inputs/DateInput"
import {TextInput} from "../inputs/TextInput"
import toast from "react-hot-toast"
import {useAuth} from "../../contexts/AuthContext"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useBudgetContext} from "../../contexts/BudgetsContext"
import {TransactionAccountInput} from "../inputs/TransationInputs"
import "./../forms/Forms.css"
import {
  BudgetLimitInput,
  BudgetSingleInput,
  BudgetMultiSelectInput,
} from "../inputs/BudgetInputs"
import {currentDate} from "../../utils/transactionUtils"
import {usePopup} from "../../contexts/PopupContext"
import {useAccounts} from "../../contexts/AccountContext"
import {validateBudget} from "../../utils/budgetUtils"

// TODO -> Improve to accept recurrent budgets
export function EditBudgetForm({budget}) {
  const {currentUser} = useAuth()
  const {accounts} = useAccounts()
  const {categories} = useTransactionContext()
  const {editBudget, getBudgetByID, getAllBudgets} = useBudgetContext()
  const {hidePopup} = usePopup()

  const [budgetName, setBudgetName] = useState()
  const [budgetDescription, setBudgetDescription] = useState()
  const [budgetLimit, setBudgetLimit] = useState()
  const [budgetDate, setBudgetDate] = useState(currentDate())
  const [budgetEndDate, setBudgetEndDate] = useState()
  const [budgetPeriod, setBudgetPeriod] = useState()
  const [budgetAccounts, setBudgetAccounts] = useState([])
  const [budgetCategories, setBudgetCategories] = useState([])
  const [showDescription, setShowDescription] = useState(false)

  const [budgetInfo, setBudgetInfo] = useState(undefined)

  const budgetPeriodOptions = [
    {value: "MONTHLY", name: "MONTHLY"},
    {value: "YEARLY", name: "YEARLY"},
  ]

  // TODO -> This is here for when implementing recurrent budgets
  const budgetRecurrenceOptions = [
    {value: "MONTHLY", name: "MONTHLY"},
    {value: "YEARLY", name: "YEARLY"},
  ]

  useEffect(() => {
    const fetchBudget = async () => {
      const fullBudget = await getBudgetByID(budget.id)
      setBudgetInfo(fullBudget)
    }
    fetchBudget()
  }, [currentUser])

  useEffect(() => {
    if (budgetInfo !== undefined) {
      console.log(budgetInfo)
      setBudgetName(budgetInfo.name)
      setBudgetDescription(budgetInfo.description)
      setBudgetLimit(budgetInfo.budgetLimit)
      setBudgetDate(budgetInfo.startDate)
      setBudgetEndDate(budgetInfo.endDate)
      setBudgetPeriod(budgetInfo.timeSpan)
      // setBudgetAccounts({
      //     target: {
      //         id: "budget_account",
      //         name: undefined,
      //         value: budgetInfo.accountID
      //     }
      // })
      if (budgetInfo.accountIDs !== "") {
        const actualBudgetAccounts = budgetInfo.accountIDs
          .split(",")
          .map((id) => parseInt(id, 10))

        const filteredAccounts = accounts.filter((account) =>
          actualBudgetAccounts.includes(parseInt(account.id, 10))
        )
        setBudgetAccounts(filteredAccounts)
      } else {
        setBudgetAccounts([])
      }
      console.log(budgetInfo.categoryIDs)
      if (budgetInfo.categoryIDs !== "") {
        const actualBudgetCategories = budgetInfo.categoryIDs
          .split(",")
          .map((id) => parseInt(id, 10))
        const filteredCategories = categories.filter((category) =>
          actualBudgetCategories.includes(parseInt(category.id, 10))
        )
        console.log(filteredCategories)
        setBudgetCategories(filteredCategories)
      } else {
        setBudgetCategories([])
      }
    }
  }, [budgetInfo])

  const updateBudgetName = (e) => {
    setBudgetName(e.target.value)
  }

  const updateBudgetDescription = (e) => {
    setBudgetDescription(e.target.value)
  }

  const updateBudgetLimit = (e) => {
    setBudgetLimit(e.target.value)
  }

  const updateBudgetDate = (e) => {
    setBudgetDate(e.target.value)
  }

  const updateBudgetEndDate = (e) => {
    setBudgetEndDate(e.target.value)
  }

  const updateBudgetPeriod = (e) => {
    console.log(e.target.value)
    setBudgetPeriod(e.target.value)
  }

  const updateBudgetAccounts = (value) => {
    setBudgetAccounts(value)
  }

  const updateBudgetCategories = (value) => {
    console.log(value)
    setBudgetCategories(value)
  }

  const sendBudgetToEdit = async (e) => {
    const categoryIds = budgetCategories
      .map((category) => category.id)
      .join(",")
    const accountIds = budgetAccounts.map((account) => account.id).join(",")
    let budgetBody = {}
    try {
      budgetBody = {
        name: budgetName,
        description: budgetDescription,
        budgetLimit: budgetLimit,
        accountIDs: accountIds,
        categoryIDs: categoryIds,
        temporalType: budgetInfo.temporalType,
        timeSpan: budgetPeriod,
        startDate: budgetDate,
        endDate: budgetInfo.temporalType === "ONE_TIME" ? budgetEndDate : null,
        userID: currentUser.uid,
        id: budget.id,
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong. Try again please :)")
    }

    if (!validateBudget(budgetBody)) return

    const editedBudget = await editBudget(budgetBody)

    if (editedBudget) {
      toast.success("Budget edited successfully")
      hidePopup()
    } else {
      toast.error("Something went wrong. Try again please :)")
    }
  }

  return (
    <section className="container " style={{paddingBottom: "20px"}}>
      <form className="create-budget-form">
        <FormRow>
          <TextInput
            labelText={"Name"}
            inputId={"budget_name"}
            inputName={"Name"}
            inputPlaceholder={"Name"}
            inputValue={budgetName}
            additionalClasses={"input-container"}
            textAlign={"center"}
            onChange={updateBudgetName}
          />
          <span
            style={{cursor: "pointer"}}
            onClick={() => {
              setShowDescription(!showDescription)
            }}
          >
            {!showDescription ? (
              <DescriptionIcon color={"#334050"} />
            ) : (
              <CloseIcon isFilled={false} color={"#334050"} />
            )}
          </span>
        </FormRow>

        {showDescription && (
          <FormRow>
            <TextInput
              labelText={"Description"}
              inputId={"budget_description"}
              inputName={"Description"}
              inputValue={budgetDescription}
              inputPlaceholder={"Description"}
              isRequired={false}
              additionalClasses={"input-container"}
              textAlign="center"
              onChange={updateBudgetDescription}
            />
          </FormRow>
        )}

        <FormRow>
          <BudgetLimitInput
            onChange={updateBudgetLimit}
            inputValue={budgetLimit}
          />
        </FormRow>
        <FormRow>
          <BudgetMultiSelectInput
            onChange={updateBudgetAccounts}
            selectedCategories={budgetAccounts}
            id={"budgets-category-autocomplete"}
            label={"Accounts"}
            use={"Accounts"}
          />
        </FormRow>
        <FormRow>
          <BudgetMultiSelectInput
            onChange={updateBudgetCategories}
            selectedCategories={budgetCategories}
            id={"budgets-category-autocomplete"}
            label={"Categories"}
            use={"Categories"}
          />
        </FormRow>
        <FormRow>
          {budgetInfo && budgetInfo.temporalType === "RECURRENT" && (
            <BudgetSingleInput
              inputId={"budget_period"}
              inputLabel={"Periodicity"}
              onChange={updateBudgetPeriod}
              selectedPeriod={budgetPeriod}
              options={budgetPeriodOptions}
              placeholder={"Period"}
              showLabel={true}
            />
          )}
          <DateInput
            inputValue={budgetDate}
            onChange={updateBudgetDate}
            inputLabel={"Start Date"}
          />

          {budgetInfo && budgetInfo.temporalType === "ONE_TIME" && (
            <DateInput
              inputValue={budgetEndDate}
              onChange={updateBudgetEndDate}
              inputLabel={"End date"}
            />
          )}
        </FormRow>
      </form>

      <SubmitButton buttonText={"Edit budget"} onSend={sendBudgetToEdit} />
    </section>
  )
}
