import React, {createContext, useState, useContext} from "react"
import {BrowserRouter as Router} from "react-router-dom"
import {Popup} from "../components/popups/SimplePopup"
import FeaturePopup from "../components/popups/FeaturePopup"
const PopupContext = createContext()

export const usePopup = () => useContext(PopupContext)

export const PopupProvider = ({children}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupContent, setPopupContent] = useState(null)
  const [isFeaturePopup, setIsFeaturePopup] = useState(false)
  const [styles, setStyles] = useState({})
  const [containerStyles, setContainerStyles] = useState({})

  const showPopup = ({content, styles, containerStyles}) => {
    setPopupContent(content)
    setIsPopupOpen(true)
    setStyles(styles)
    setContainerStyles(containerStyles)
  }

  const showFeaturePopup = (slug) => {
    setPopupContent(slug)
    setIsFeaturePopup(true)
  }

  const hidePopup = () => {
    setIsPopupOpen(false)
    setIsFeaturePopup(false)
    setPopupContent(null)
    setStyles({})
    setContainerStyles({})
  }

  const value = {
    showPopup,
    hidePopup,
    showFeaturePopup,
  }

  return (
    <PopupContext.Provider value={value}>
      {children}
      {isPopupOpen && (
        <Popup styles={styles} containerStyles={containerStyles}>
          {popupContent}
        </Popup>
      )}
      {isFeaturePopup && <FeaturePopup slug={popupContent} />}
    </PopupContext.Provider>
  )
}
