import {PostTags} from "../blog/PostTags"

export function PostMetadata({metadata, slug}) {
  const postDate = new Date(metadata.date).toISOString().split("T")[0]

  return (
    <section className="post-metadata">
      {metadata.featuredImage && (
        <div className="post-featured-image-container">
          <img
            src={`https://backend.finanzia.app/images/posts/${slug}/${metadata.featuredImage}`}
            alt={metadata.title}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            className="post-featured-image"
          />
        </div>
      )}
      <div style={{padding: "0rem 0.2rem"}}>
        <h1 className="post-title">{metadata.title}</h1>
        <p className="post-details">
          {metadata.author} ({metadata.authorPosition}) · {postDate}
        </p>
        {metadata.tags && <PostTags tags={metadata.tags} />}
      </div>
    </section>
  )
}
