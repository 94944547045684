import axios from "axios"
import {NewUser} from "../../types"
import {apiClient} from "./client"

export class UserRepository {
  async saveUserToDB(userData: NewUser) {
    try {
      const response = await apiClient.post("/users", userData)

      return response
    } catch (error) {
      console.error("Error in userRepository:", error)
      throw error
    }
  }
}
