import PrimaryButton from "../buttons/PrimaryButton"

function FormRow({
  children,
  justifyContent = "start",
  alignItems = "center",
  gap = 10,
  marginTop = "0px",
  additionalClasses = "",
}) {
  const styles = {
    justifyContent: justifyContent,
    alignItems: alignItems,
    gap: gap,
    marginTop: marginTop,
  }

  return (
    <div className={`form-row ${additionalClasses}`} style={styles}>
      {children}
    </div>
  )
}

function SubmitButton({
  onSend,
  buttonText,
  transType,
  buttonType = "primary",
  isDisabled = false,
}) {
  let backgroundColor
  switch (transType) {
    case "INCOME":
      backgroundColor = "#02dca2"
      break
    case "EXPENSE":
      backgroundColor = "#334050"
      break

    case "TRANSFER":
      backgroundColor = "rgba(51,60,80,0.7)"
      break

    default:
      backgroundColor = "#02dca2"
      break
  }
  switch (buttonType) {
    case "primary": {
      return (
        <div>
          <PrimaryButton
            backgroundColor={backgroundColor}
            buttonText={buttonText}
            onClick={onSend}
            isDisabled={isDisabled}
          />
        </div>
      )
    }
    default: {
      return <p> Ups...</p>
    }
  }
}

export {FormRow, SubmitButton}
