import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios"

interface IAPIClient {
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>
}

class APIClient implements IAPIClient {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: "https://backend.finanzia.app/api",
      headers: {
        "Content-Type": "application/json",
        "X-fia-api-key": `Bearer ${process.env.REACT_APP_FIA_API_KEY}`,
      },
    })

    this.setupInterceptors()
  }

  private setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // You can add authentication headers or other modifications here
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        // Handle global error responses here
        return Promise.reject(error)
      }
    )
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(url, config)
    return response.data
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.post(
        url,
        data,
        config
      )

      if (!this.isSucessful(response)) {
        throw new Error("Error in apiClient.post")
      }

      return response.data
    } catch (error) {
      console.log("Error in apiClient.post", error)
      throw error
    }
  }

  public async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(
      url,
      data,
      config
    )
    return response.data
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(
      url,
      config
    )
    return response.data
  }

  private isSucessful(response: AxiosResponse) {
    return response.status >= 200 && response.status < 300
  }
}

export const apiClient = new APIClient()
