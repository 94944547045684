import {AxiosResponse} from "axios"
import {ICategoryRepository} from "../../infraestructure/api/categoryRepository"
import {Category} from "../../types"

export interface ICategoryService {
  createCategory(category: Category): Promise<AxiosResponse>
}

export class CategoryService implements ICategoryService {
  repo: ICategoryRepository
  constructor(categoryRepo: ICategoryRepository) {
    this.repo = categoryRepo
  }

  async createCategory(category: Category) {
    return await this.repo.addCategory(category)
  }
}
