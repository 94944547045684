import {useEffect, useState} from "react"
import CloseIcon from "../icons/CloseIcon"
import "./Onboarding.css"
import PrimaryButton from "../buttons/PrimaryButton"
import SecondaryButton from "../buttons/SecondaryButton"
import {useResponsive} from "../../contexts/ResponsiveContext"
import WelcomeStep from "./knowledge_steps/0_WelcomeStep"
import AccountsStep from "./knowledge_steps/1_AccountsSteps"
import CreateAccountStep from "./knowledge_steps/2_CreatingAccount"
import ExistingAccountsStep from "./knowledge_steps/3_ExistingAccounts"
import CategoriesStep from "./knowledge_steps/4_CategoriesStep"
import CreatingCategoryStep from "./knowledge_steps/5_CreatingCategory"
import ExistingCategoriesStep from "./knowledge_steps/6_ExistingCategory"
import TransactionsStep from "./knowledge_steps/7_Transactions"
import CreatingTransactionStep from "./knowledge_steps/8_CreatingTransaction"
import ExistingTransactionsStep from "./knowledge_steps/9_ExistingTransactions"
import RecurrentTransactionsStep from "./knowledge_steps/10_RecurrentTransactions"
import ReportIssue from "../settings/ReportIssue"
import DashboardStep from "./knowledge_steps/11_Dashboard"
import FinancialSummaryStep from "./knowledge_steps/12_FinancialSummary"
import MonthlyOverviewStep from "./knowledge_steps/13_MonthlyOverview"
import CategoryBeakdownStep from "./knowledge_steps/14_CategoryBreakdown"
import BudgetsStep from "./knowledge_steps/15_BudgetsStep"
import CreateBudgetStep from "./knowledge_steps/16_CreateBudget"
import ExistingBudgetsStep from "./knowledge_steps/17_ExistingBudgets"

import {useKnowledge} from "../../contexts/KnowledgeContext"

const KnowledgePopup = () => {
  const {knowledgeStep, NUMBER_OF_STEPS} = useKnowledge()
  const {isTablet} = useResponsive()
  const [stepContent, setStepContent] = useState()

  useEffect(() => {
    switch (knowledgeStep) {
      case 0:
        setStepContent(<WelcomeStep />)
        break
      case 1:
        setStepContent(<AccountsStep />)
        break
      case 2:
        setStepContent(<CreateAccountStep />)
        break
      case 3:
        setStepContent(<ExistingAccountsStep />)
        break

      case 4:
        setStepContent(<CategoriesStep />)
        break
      case 5:
        setStepContent(<CreatingCategoryStep />)
        break

      case 6:
        setStepContent(<ExistingCategoriesStep />)
        break
      case 7:
        setStepContent(<TransactionsStep />)
        break
      case 8:
        setStepContent(<CreatingTransactionStep />)
        break
      case 9:
        setStepContent(<ExistingTransactionsStep />)
        break
      case 10:
        setStepContent(<RecurrentTransactionsStep />)
        break
      case 11:
        setStepContent(<DashboardStep />)
        break
      case 12:
        setStepContent(<FinancialSummaryStep />)
        break
      case 13:
        setStepContent(<MonthlyOverviewStep />)
        break

      case 14:
        setStepContent(<CategoryBeakdownStep />)
        break

      case 15:
        setStepContent(<BudgetsStep />)
        break
      case 16:
        setStepContent(<CreateBudgetStep />)
        break
      case 17:
        setStepContent(<ExistingBudgetsStep />)
        break
    }
  }, [knowledgeStep])

  return (
    <div className="onboarding-container">
      <div className="onboarding-popup">
        <div className="popup-header">
          <h4>
            Knowledge base ({knowledgeStep + 1}/{NUMBER_OF_STEPS + 1})
          </h4>
          <CloseKnowledgePopupButton />
        </div>
        <div className="onboarding-content">
          {stepContent && stepContent}

          {!isTablet && <KnowledgeIndex />}
          {isTablet && <KnowledgePopupFooter type={"knowledge"} />}
        </div>
      </div>
    </div>
  )
}

const KnowledgeIndex = () => {
  return (
    <div className="onboarding-index">
      <div>
        <h3> Index </h3>
        <div className="index">
          <IndexItem step={0} stepName={"Welcome"}></IndexItem>

          <IndexItem step={1} stepName={"Accounts"}>
            <IndexItem step={2} stepName={"Creating an account"}></IndexItem>
            <IndexItem step={3} stepName={"Existing accounts"}></IndexItem>
          </IndexItem>

          <IndexItem step={4} stepName={"Categories"}>
            <IndexItem step={5} stepName={"Creating a category"}></IndexItem>
            <IndexItem step={6} stepName={"Existing  categories"}></IndexItem>
          </IndexItem>

          <IndexItem step={7} stepName={"Transactions"}>
            <IndexItem step={8} stepName={"Creating a transaction"}></IndexItem>
            <IndexItem step={9} stepName={"Existing transactions"}></IndexItem>
            <IndexItem
              step={10}
              stepName={"Recurrent transactions"}
            ></IndexItem>
          </IndexItem>

          <IndexItem step={11} stepName={"Dashboard"}>
            <IndexItem step={12} stepName={"Financial summary"}></IndexItem>
            <IndexItem step={13} stepName={"Monthly overview"}></IndexItem>
            <IndexItem step={14} stepName={"Categories breakdown"}></IndexItem>
          </IndexItem>

          <IndexItem step={15} stepName={"Budgets"}>
            <IndexItem step={16} stepName={"Creating a budget"}></IndexItem>
            <IndexItem step={17} stepName={"Existing budgets"}></IndexItem>
          </IndexItem>
        </div>
        <KnowledgePopupFooter type={"knowledge"} />
      </div>
      <ReportIssue title={"Any doubts? "} text="Get in touch" />
    </div>
  )
}

const CloseKnowledgePopupButton = () => {
  const {hidePopup} = useKnowledge()
  return (
    <button onClick={hidePopup}>
      <CloseIcon width="24px" color={"#334050"} />
    </button>
  )
}

const KnowledgePopupFooter = () => {
  const {increaseStep, decreaseStep, knowledgeStep, NUMBER_OF_STEPS} =
    useKnowledge()

  return (
    <div
      className="onboarding-popup-footer"
      style={{display: "flex", gap: "1rem"}}
    >
      <SecondaryButton
        buttonText={"Previous"}
        onClick={() => decreaseStep()}
        textColor="var(--primary)"
      />
      <PrimaryButton
        buttonText={knowledgeStep !== NUMBER_OF_STEPS ? "Next" : "Close"}
        onClick={() => increaseStep()}
        borderWidth={"2px"}
      />
    </div>
  )
}

const IndexItem = ({children, step, stepName}) => {
  const {knowledgeStep, setKnowledgeStep} = useKnowledge()
  return (
    <div className={`index-item ${step === knowledgeStep ? "active" : ""}`}>
      <p onClick={() => setKnowledgeStep(step)}>{stepName}</p>
      {children}
    </div>
  )
}

export {KnowledgePopup}
