import axios from "axios"
import {createContext, useContext, useEffect, useState} from "react"
import {useAuth} from "./AuthContext"
import {apiClient} from "../infraestructure/api/client"

const FeatureContext = createContext()

export function useFeatureContext() {
  return useContext(FeatureContext)
}

export function FeatureProvider({children}) {
  const VOTING_PERIOD = "01_09_24_to_16_09_24"
  const [features, setFeatures] = useState(undefined)
  const [votingPeriodEnd, setVotingPeriodEnd] = useState(undefined)
  const [voteTotals, setVoteTotals] = useState(undefined)
  const {currentUser} = useAuth()
  const [userVotes, setUserVotes] = useState(undefined)

  const getFeatureSlugsAndMetadata = async () => {
    try {
      const response = await apiClient.get(`features/${VOTING_PERIOD}`)
      setFeatures(response.features)
    } catch (error) {
      console.log("Error fetching features:", error)
    }
  }

  const getFeature = async (slug) => {
    const query = {
      votingPeriod: VOTING_PERIOD,
      slug: slug,
    }

    try {
      const response = await apiClient.get("/features", {params: query})
      return response
    } catch (error) {
      console.log("Error fetching feature:", error)
      return null
    }
  }

  const getVoteTotals = async () => {
    try {
      const response = await apiClient.get(`/voteTotals/${VOTING_PERIOD}`)
      setVoteTotals(response)
    } catch (error) {
      console.log("Error fetching votes:", error)
      return null
    }
  }

  const getVotingPeriodEnd = async () => {
    // Extract the last date part of the string
    const lastDateStr = VOTING_PERIOD.split("_to_")[1]

    // Parse the extracted string into a date
    const [day, month, year] = lastDateStr.split("_")

    // Create a new Date object
    const lastDate = new Date(`20${year}`, month - 1, day)

    // Set the voting period end date in the state
    setVotingPeriodEnd(lastDate)
  }

  const postVote = async (votingPeriod, votedFeature) => {
    const request_body = {
      votingPeriod: votingPeriod,
      votedFeature: votedFeature,
      userID: currentUser.uid,
    }

    try {
      const response = await apiClient.post("/votes", request_body)
      return response
    } catch (err) {
      console.log(err)
      return null
    }
  }

  const getUserVotes = async () => {
    const query = {
      userID: currentUser.uid,
    }

    try {
      const response = await apiClient.get(`votes/${VOTING_PERIOD}`, {
        params: query,
      })
      setUserVotes(response)
      return response
    } catch (error) {
      console.log("Error fetching votes:", error)
    }
  }
  useEffect(() => {
    if (votingPeriodEnd) return

    getVotingPeriodEnd()
  }, [])

  const value = {
    features,
    getFeatureSlugsAndMetadata,
    getFeature,
    votingPeriodEnd,
    voteTotals,
    getVoteTotals,
    postVote,
    getUserVotes,
    userVotes,
  }
  return (
    <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
  )
}
