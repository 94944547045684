import DashboardMockup from "./../../../static/resources/images/home/dashboard_mockup.png"

export default function DashboardStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Dashboard</h2>
      </div>
      <div className="step-content">
        <p>
          Finally, let's go back to the Dashboard. The idea is for the dashboard
          to act as the main screen on the app, where you can see a complete
          overfiew of you finances.
        </p>
        <img src={DashboardMockup} alt="Dashboard mockup" />
      </div>
    </div>
  )
}
