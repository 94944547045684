import {useEffect} from "react"
import {
  DashboardPanel,
  DashboardRow,
} from "../../components/dashboard/DashboardBasics"
import RecurrentTransactionsTable from "../../components/dashboard/recurrentTranactions/RecurrentTransactionsTable"
import PageHeader from "../../components/pages/PageHeader"
import RecurrenceInsights from "../../components/recurrent_transactions/RecurrenceInsights"
import RecurrencesBreakdown from "../../components/recurrent_transactions/RecurrencesBreakdown"
import RecurrentBarChart from "../../components/recurrent_transactions/RecurrentBarChart"
import CreateRecurrentTransactionForm from "../../components/transactions/forms/RecurrentTransactionForm"
import useRecurrenceContext from "../../contexts/RecurrentTransactionContext"
import {useResponsive} from "../../contexts/ResponsiveContext"
import "./RecurrentTransactionsPage.css"

export default function RecurrentTransactions() {
  const {recurrentTransactions} = useRecurrenceContext()
  const {isPhone, isTablet} = useResponsive()

  useEffect(() => {
    document.title = "Recurrences - Finanzia"
  }, [])

  return (
    <main className="recurrent-transactions-page">
      {isTablet && (
        <PageHeader
          title={isPhone ? "Recurrences" : "Recurrent transactions"}
          hasMonthSelector={true}
        />
      )}
      <DashboardRow>
        <section className="dashboard-left">
          {!isTablet && (
            <PageHeader
              title={isPhone ? "Recurrences" : "Recurrent transactions"}
              hasMonthSelector={true}
            />
          )}
          <RecurrentTransactionsTable />
          {isTablet && (
            <>
              <DashboardPanel>
                <RecurrentBarChart />
              </DashboardPanel>
              <DashboardPanel>
                <RecurrencesBreakdown />
              </DashboardPanel>
            </>
          )}
          {isPhone && (
            <DashboardPanel>
              <RecurrenceInsights />
            </DashboardPanel>
          )}
        </section>
        {!isPhone && (
          <section className="dashboard-right">
            <DashboardPanel orientation={"vertical"}>
              <h2> Add recurrence</h2>
              <CreateRecurrentTransactionForm />
            </DashboardPanel>
            {isTablet && (
              <DashboardPanel>
                <RecurrenceInsights />
              </DashboardPanel>
            )}
          </section>
        )}
      </DashboardRow>
      {!isTablet && (
        <DashboardRow pTop="0.8rem" alignItems="stretch">
          <DashboardPanel>
            <RecurrentBarChart />
          </DashboardPanel>
          <DashboardPanel>
            <RecurrencesBreakdown />
          </DashboardPanel>
          <DashboardPanel>
            <RecurrenceInsights />
          </DashboardPanel>
        </DashboardRow>
      )}
    </main>
  )
}
