export default function PageTitle({title, textAlign = "center"}) {
  return (
    <div style={{margin: "auto"}} className="page-title">
      <h1 style={{textAlign: textAlign}}>{title}</h1>
    </div>
  )
}

export function PageSubtitle({subtitle, textAlign = "center"}) {
  return (
    <div style={{margin: "auto"}} className="page-subtitle">
      <h2 style={{textAlign: textAlign}}>{subtitle}</h2>
    </div>
  )
}
