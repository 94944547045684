import {createContext, useContext, useEffect, useState} from "react"
import {useTransactionContext} from "./TransactionContext"
import {useAuth} from "./AuthContext"
import {useDebouncedCallback} from "use-debounce"
import {useAccounts} from "./AccountContext"

const TransactionFiltersContext = createContext()

export function useTransactionFilters() {
  return useContext(TransactionFiltersContext)
}

export function TransactionFiltersProvider({children}) {
  const {currentUser} = useAuth()
  const {accounts} = useAccounts()
  const {transactions, categories, getAllTransactions, getAllCategories} =
    useTransactionContext()

  const {getAllAccounts} = useAccounts()
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showFilteredStats, setShowFilteredStats] = useState(false)

  useEffect(() => {
    if (categories.length === 0) {
      getAllCategories(currentUser.uid)
    }

    if (accounts.length === 0) {
      getAllAccounts(currentUser.uid)
    }
  }, [
    transactions,
    getAllCategories,
    getAllAccounts,
    currentUser.uid,
    categories,
    accounts,
  ])

  useEffect(() => {
    applyFilters()
  }, [
    transactions,
    searchQuery,
    selectedCategories,
    selectedAccounts,
    startDate,
    endDate,
  ])

  const applyFilters = () => {
    let updatedTransactions = transactions

    if (searchQuery) {
      updatedTransactions = updatedTransactions.filter((transaction) =>
        transaction.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    if (selectedCategories.length > 0) {
      updatedTransactions = updatedTransactions.filter((transaction) =>
        selectedCategories.some(
          (category) => category.id === transaction.categoryID
        )
      )
    }

    if (selectedAccounts.length > 0) {
      updatedTransactions = updatedTransactions.filter((transaction) =>
        selectedAccounts.some(
          (account) =>
            account.id === transaction.accountID ||
            account.id === transaction.fromAccountID
        )
      )
    }

    if (startDate) {
      updatedTransactions = updatedTransactions.filter(
        (transaction) => transaction.date >= startDate
      )
    }

    if (endDate) {
      updatedTransactions = updatedTransactions.filter(
        (transaction) => transaction.date <= endDate
      )
    }

    if (updatedTransactions.length !== transactions.length) {
      setShowFilteredStats(true)
    } else {
      setShowFilteredStats(false)
    }
    setFilteredTransactions(updatedTransactions)
  }

  const debouncedFilterByName = useDebouncedCallback((query) => {
    setSearchQuery(query)
  }, 300) // Adjust the debounce delay as needed

  const filterByCategory = (categories) => {
    setSelectedCategories(categories)
  }

  const filterByAccount = (accounts) => {
    setSelectedAccounts(accounts)
  }

  const filterByName = (query) => {
    debouncedFilterByName(query)
  }

  const filterByDateRange = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const clearFilters = () => {
    setSearchQuery("")
    setSelectedCategories([])
    setSelectedAccounts([])
    setStartDate(null)
    setEndDate(null)
    setShowFilteredStats(false)
  }

  const value = {
    filterByName,
    filterByCategory,
    filteredTransactions,
    selectedCategories,
    setSelectedCategories,
    filterByAccount,
    selectedAccounts,
    setSelectedAccounts,
    filterByDateRange,
    startDate,
    endDate,
    clearFilters,
    showFilteredStats,
  }

  return (
    <TransactionFiltersContext.Provider value={value}>
      {children}
    </TransactionFiltersContext.Provider>
  )
}
