import {
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  UserCredential,
} from "firebase/auth"
import {auth} from "../../firebase"

export interface IAuthRepo {
  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential>
  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential>
}

export class FirebaseAuthProvider implements IAuthRepo {
  async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return await firebaseSignInWithEmailAndPassword(auth, email, password)
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return await firebaseCreateUserWithEmailAndPassword(auth, email, password)
  }
}
