import {useNavigate} from "react-router-dom"
import "./Accounts.css"
import {useAccounts} from "../../contexts/AccountContext"

function AcccountList({title}) {
  const {accounts} = useAccounts()

  return (
    <section className="account-list-container">
      <div>
        <h3>{title}</h3>
      </div>
      <div className="account-list">
        {accounts &&
          accounts.map((account) => {
            return <AccountRow account={account} />
          })}
      </div>
    </section>
  )
}

function AccountRow({account}) {
  const navigate = useNavigate()
  const balance = account.currentBalance + "€"

  let balanceClass
  if (account.currentBalance > 0) {
    balanceClass = "account-balance positive"
  } else if (account.currentBalance < 0) {
    balanceClass = "account-balance negative"
  } else {
    balanceClass = "account-balance zero"
  }

  const redirectToAccountPage = () => {
    navigate(`/app/account/${account.id}`)
  }

  return (
    <div
      className="account account-row"
      key={account.id}
      onClick={redirectToAccountPage}
    >
      <div className="account-left">
        <h4 className="account-name"> {account.name} </h4>
        <p>{account.description}</p>
      </div>
      <div className="account-right">
        <p>Balance:</p>
        <h3 className={balanceClass}>{balance}</h3>
      </div>
    </div>
  )
}

export {AcccountList}
