export default function SectionTitle({
  children,
  color = "var(--gray)",
  textAlign = "center",
}) {
  return (
    <h2
      className="section-title"
      style={{
        background: color,
        fontSize: "2.8rem",
        textAlign: textAlign,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        lineHeight: "3.4rem",
      }}
    >
      {children}
    </h2>
  )
}
