import {useEffect, useState} from "react"
import {BrowserRouter as Router} from "react-router-dom"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useNavigate, useParams} from "react-router-dom"
import {DashboardPanel} from "../../components/dashboard/DashboardBasics"
import {TransactionList} from "../../components/transactions/TransactionGroups"
import PageHeader from "../../components/pages/PageHeader"
import {StatsProvider} from "../../contexts/StatsContext"
import {useAuth} from "../../contexts/AuthContext"
import FinancialSummary from "../../components/dashboard/FinancialSummary"
import "./Accounts.css"

import trashIcon from "./../../static/resources/icons/trash.svg"
import editIcon from "./../../static/resources/icons/edit.svg"
import closeIcon from "./../../static/resources/icons/close.svg"

import toast from "react-hot-toast"
import {usePopup} from "../../contexts/PopupContext"
import {ClosePopupButton} from "../../components/popups/SimplePopup"
import {EditAccountForm} from "../../components/accounts/AccountForms"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import SecondaryButton from "../../components/buttons/SecondaryButton"
import {useResponsive} from "../../contexts/ResponsiveContext"
import {useAccounts} from "../../contexts/AccountContext"

export default function Account() {
  const {accountID} = useParams()
  const {getTransactions} = useTransactionContext()
  const {getAccounts, editAccount, getAccountGroups} = useAccounts()
  const [account, setAccount] = useState()
  const {dashboardLayout} = useResponsive()

  useEffect(() => {
    const fetchAccount = async () => {
      const query = {
        id: accountID,
      }
      const accountData = await getAccounts(query)
      setAccount(accountData)
    }

    const fetchTransactions = async () => {
      const query = {
        accountID: accountID,
        fromAccountID: accountID,
      }
      const transactions = await getTransactions(query)
    }

    if (accountID) {
      fetchAccount()
      fetchTransactions()
      getAccountGroups()
    }
  }, [accountID])

  const {hidePopup} = usePopup()

  const handleAccountEdit = async (
    accountName,
    accountDescription,
    accountGroup
  ) => {
    const editedAccount = {
      name: accountName,
      description: accountDescription,
      groupID: accountGroup,
      userID: account.userID,
      currentBalance: account.currentBalance,
      id: account.id,
    }
    const isAccountEdited = await editAccount(editedAccount)

    if (isAccountEdited) {
      hidePopup()
      const query = {
        id: accountID,
      }

      const accountData = await getAccounts(query).catch((e) =>
        console.error("Failed to fetch account data:", e)
      )

      setAccount(accountData)
      toast.success("Account updated")
    }
  }

  const dashboardRightClass = `dashboard-right ${
    dashboardLayout.right ? "isActive" : ""
  }`

  return (
    <main className="default-page account-page">
      <StatsProvider>
        {account && (
          <>
            <section className="dashboard-left">
              <PageHeader
                title={account.name}
                actions={
                  <AccountActions
                    handleAccountEdit={handleAccountEdit}
                    account={account}
                  />
                }
              />
              <FinancialSummary accountID={accountID} />
            </section>
            <section className={dashboardRightClass}>
              <DashboardPanel orientation={"vertical"}>
                <TransactionList
                  key={account}
                  title={"Account transactions"}
                  type={"byAccount"}
                />
              </DashboardPanel>
            </section>
          </>
        )}
      </StatsProvider>
    </main>
  )
}

function AccountActions({account, handleAccountEdit}) {
  const {showPopup, hidePopup} = usePopup()
  const {accounts} = useAccounts()
  const {deleteAccount} = useAccounts()
  const navigate = useNavigate()

  const handleDeleteAccount = async (e) => {
    if (accounts.length === 1)
      return toast.error("You only have an account, create one before deleting")

    const isAccountDeleted = await deleteAccount(account.id)

    if (isAccountDeleted) {
      hidePopup()
      navigate("/app/dashboard")
      toast.success(`Account ${account.name} was deleted`)
    } else {
      toast.error("Account could not be deleted")
    }
  }
  const showDeleteConfirmationPopup = (e) => {
    showPopup({
      content: (
        <ConfirmAccountDeletePopup
          account={account}
          handleDelete={handleDeleteAccount}
        />
      ),
    })
  }
  const showEditAccountPopup = (e) => {
    showPopup({
      content: (
        <EditAccountPopup
          account={account}
          handleAccountEdit={handleAccountEdit}
        />
      ),
    })
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
      className="account-actions"
    >
      <img
        src={editIcon}
        style={{fill: "#02dca2", cursor: "pointer"}}
        onClick={showEditAccountPopup}
      />
      <img
        src={trashIcon}
        style={{fill: "#334050", cursor: "pointer"}}
        onClick={showDeleteConfirmationPopup}
      />
    </div>
  )
}

const EditAccountPopup = ({account, handleAccountEdit}) => {
  return (
    <div className="edit-transaction">
      <div className="popup-header">
        <h2>Edit account</h2>
        <ClosePopupButton icon={closeIcon} />
      </div>
      <EditAccountForm account={account} onAccountEdited={handleAccountEdit} />
    </div>
  )
}

const ConfirmAccountDeletePopup = ({account, handleDelete}) => {
  const {hidePopup} = usePopup()

  return (
    <div>
      <div className="popup-header">
        <h2> Are you sure?</h2>
        <ClosePopupButton icon={closeIcon} />
      </div>
      <p>
        Deleting this account will also result on all transactions in this
        account to be PERMANENTLY removed
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <PrimaryButton onClick={handleDelete} buttonText={"Yes, delete!"} />
        <SecondaryButton
          buttonText={"No, go back"}
          borderColor="#334050"
          onClick={() => {
            hidePopup()
          }}
        />
      </div>
    </div>
  )
}

function AccountHero() {}
