import {useNavigate} from "react-router"
import {useTransactionContext} from "../../contexts/TransactionContext"
import AddIcon from "../icons/AddIcon"
import {useEffect, useState} from "react"
import {CreateAccountForm} from "./AccountForms"
import {usePopup} from "../../contexts/PopupContext"
import {useAccounts} from "../../contexts/AccountContext"

function DashboardAccounts() {
  const {accounts} = useAccounts()
  const {transactions} = useTransactionContext()
  const {showPopup} = usePopup()
  const [positiveBalances, setPositiveBalances] = useState([])
  const [negativeBalances, setNegativeBalances] = useState([])

  useEffect(() => {
    if (accounts) {
      const positiveBalances = accounts
        .filter((account) => account.currentBalance > 0)
        .map((account) => account.currentBalance)
      const negativeBalances = accounts
        .filter((account) => account.currentBalance < 0)
        .map((account) => account.currentBalance)
      setPositiveBalances(positiveBalances)
      setNegativeBalances(negativeBalances)
    }
  }, [accounts, transactions])

  // Extract max and min from each list
  const balanceExtremes = {
    maxPositive: Math.max(...positiveBalances),
    minPositive: Math.min(...positiveBalances),
    maxNegative: Math.max(...negativeBalances),
    minNegative: Math.min(...negativeBalances),
  }

  const handleAddAccountClick = () => {
    showPopup({
      content: (
        <CreateAccountForm
          hideForm={undefined}
          hasAccountSummary={false}
          hasClosePopup={true}
        />
      ),
    })
  }

  return (
    <section className="dashboard-accounts">
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <h2> Accounts</h2>
        <span onClick={handleAddAccountClick} style={{cursor: "pointer"}}>
          <AddIcon color={"var(--light-gray)"} width={"26px"} />
        </span>
      </div>
      <div className="dashboard-account-list no-scroll-bar">
        {accounts &&
          accounts.map((account) => {
            return (
              <Account
                key={account.id}
                account={account}
                balanceExtremes={balanceExtremes}
              />
            )
          })}
      </div>
    </section>
  )
}

function Account({account, balanceExtremes}) {
  const [backgroundColor, setBackgroundColor] = useState()
  const [color, setColor] = useState()
  const getColor = () => {
    var ratio

    if (account.currentBalance > 0) {
      ratio =
        (account.currentBalance - balanceExtremes.minPositive) /
        (balanceExtremes.maxPositive - balanceExtremes.minPositive)
    } else {
      ratio =
        (account.currentBalance - balanceExtremes.maxNegative) /
        (balanceExtremes.minNegative - balanceExtremes.maxNegative)
    }

    const baseColor =
      account.currentBalance > 0
        ? "rgba(2, 220, 162, X)"
        : "rgba(51, 64, 80, X)"

    const backgroundColor = baseColor.replace("X", ratio * 0.8 + 0.1)

    const color = ratio > 0.65 ? "#f5f5f5" : "#334050"

    return [color, backgroundColor]
  }

  useEffect(() => {
    const [color, backgroundColor] = getColor()
    setColor(color)
    setBackgroundColor(backgroundColor)
  }, [account])

  const navigate = useNavigate()
  const redirectToAccountPage = () => {
    navigate(`/app/account/${account.id}`)
  }
  return (
    <div
      style={{backgroundColor: backgroundColor, color: color}}
      className="dashboard-account"
      onClick={redirectToAccountPage}
    >
      <h4>{account.name}</h4>
      <p className="balance">{account.currentBalance}€</p>
    </div>
  )
}

export {DashboardAccounts}
