import {AxiosResponse} from "axios"
import {IAccountRepository} from "../../infraestructure/api/accountRepository"
import {Account} from "../../types"

export interface IAccountService {
  createAccount(account: Account): Promise<AxiosResponse>
}

export class AccountService implements IAccountService {
  repo: IAccountRepository
  constructor(accountRepo: IAccountRepository) {
    this.repo = accountRepo
  }

  async createAccount(account: Account) {
    return await this.repo.addAccount(account)
  }
}
