export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      // Resolve the promise with the base64 string (excluding metadata if needed)
      const base64String = reader.result as string
      resolve(base64String)
    }

    reader.onerror = (error) => reject(error)
  })
}
