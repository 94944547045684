import "./App.css"
import {BrowserRouter as Router, Route, Routes, Link} from "react-router-dom"
import Dashboard from "./pages/app/Dashboard"
import {IsAdmin, NotLoggedIn, ProtectedWrapper} from "./routes/ProtectedRoutes"
import Login from "./pages/auth/Login"
import SignUp from "./pages/auth/Register"
import LateralMenu from "./components/navigation/LateralMenu"
import "./fonts.css"
import Account from "./pages/app/AccountPage"
import {TransactionProvider} from "./contexts/TransactionContext"
import {PopupProvider} from "./contexts/PopupContext"
import {Toaster} from "react-hot-toast"
import Category from "./pages/app/CategoryPage"
import BottomMenu from "./components/navigation/BottomMenu"
import {ResponsiveProvider, useResponsive} from "./contexts/ResponsiveContext"
import Home from "./pages/Home"
import Transactions from "./pages/app/TransactionsPage"
import BudgetsPage from "./pages/app/BudgetsPage"

import {RecurrentTransactionProvider} from "./contexts/RecurrentTransactionContext"
import RecurrentTransactions from "./pages/app/RecurrentTransactionsPage"

import {useAuth} from "./contexts/AuthContext"
import TopMenu from "./components/navigation/TopMenu"
import {
  NavigationProvider,
  useNavigationContext,
} from "./contexts/NavigationContext"
import "./components/text/Text.css"
import {BudgetProvider} from "./contexts/BudgetsContext"
import BlogPage from "./pages/blog/BlogPage"
import BlogPost from "./pages/blog/Post"
import {BlogProvider} from "./contexts/BlogContext"
import TagPage from "./components/blog/TagPage"
import SettingsPage from "./pages/app/SettingsPage"
import KpiPage from "./pages/admin/Kpi"
import {KpiProvider} from "./contexts/KpisContext"
import VoteNewFeaturesPage from "./pages/app/VoteNewFeaturesPage"
import ForgotPassword from "./pages/auth/ForgotPassword"
import {BottomSheetProvider} from "./contexts/BottomSheetContext"
import BulkUploadPage from "./pages/app/transactions/BulkUploadPage"

function App() {
  const {isLoading} = useAuth()
  const {isVerticalTablet} = useResponsive()
  const {isApp} = useNavigationContext()

  return (
    <div className="App">
      <Toaster
        toastOptions={{
          success: {
            style: {
              color: "#334050",
            },
            iconTheme: {
              primary: "rgb(2, 220, 162)",
            },
          },
          error: {
            style: {
              background: "#ebc8c4",
              color: "red",
            },
          },
        }}
      />
      <ResponsiveProvider>
        <RecurrentTransactionProvider>
          <BudgetProvider>
            <PopupProvider>
              <BottomSheetProvider>
                {isApp && !isVerticalTablet && <LateralMenu />}
                {!isApp && !isLoading && <TopMenu />}

                {isVerticalTablet && isApp && <BottomMenu />}
                <BlogProvider>
                  <KpiProvider>
                    <Routes>
                      <Route path="/" element={<Home />} />

                      <Route path="/blog" element={<BlogPage />} />
                      <Route path="/blog/:slug" element={<BlogPost />} />
                      <Route path="/blog/tags/:tag" element={<TagPage />} />

                      <Route path="/app" element={<ProtectedWrapper />}>
                        <Route path="dashboard" element={<Dashboard />} />

                        <Route path="transactions" element={<Transactions />} />
                        <Route
                          path="transactions/bulk-upload"
                          element={<BulkUploadPage />}
                        />
                        <Route path="budgets" element={<BudgetsPage />} />
                        <Route
                          path="recurrent-transactions"
                          element={<RecurrentTransactions />}
                        />
                        <Route path="settings" element={<SettingsPage />} />
                        <Route path="vote" element={<VoteNewFeaturesPage />} />
                        <Route
                          path="account/:accountID"
                          element={<Account />}
                        />
                        <Route
                          path="category/:categoryID"
                          element={<Category />}
                        />
                      </Route>

                      <Route path="/cuenta" element={<NotLoggedIn />}>
                        <Route path="iniciar-sesion" element={<Login />} />
                        <Route path="register" element={<SignUp />} />
                        <Route
                          path="reset-password"
                          element={<ForgotPassword />}
                        />
                      </Route>

                      <Route path="/admin" element={<IsAdmin />}>
                        <Route path="kpi" element={<KpiPage />} />
                      </Route>
                    </Routes>
                  </KpiProvider>
                </BlogProvider>
              </BottomSheetProvider>
            </PopupProvider>
          </BudgetProvider>
        </RecurrentTransactionProvider>
      </ResponsiveProvider>
    </div>
  )
}

export default App
