import {useResponsive} from "../../../contexts/ResponsiveContext"
import DashboardMockup from "./../../../static/resources/images/home/dashboard_mockup.png"
import PhoneMockup from "./../../../static/resources/images/home/phone_mockup.png"

export default function Onboarding_WelcomeStep() {
  const {isTablet} = useResponsive()
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Welcome to Finanzia</h2>
      </div>
      <div className="step-content">
        <p>
          First of all, huge thanks for trying out FINANZIA! This is a tutorial
          to help you get started with the app. But we want you to explore the
          app at your own pace, so we've kept this tutorial extremely short and
          simple. But don't worry, there's a knowledge base that you can access
          anytime.
        </p>
        <img src={isTablet ? PhoneMockup : DashboardMockup} />
      </div>
    </div>
  )
}
