import styled from "styled-components"
import SectionHeader from "../text/SectionHeader"
import TextPill from "../text/TextPill"
import SectionTitle from "../text/SectionTitle"
import Container from "../layout/Container"
import {useEffect, useRef, useState} from "react"

export default function ImpactSection() {
  return (
    <ImpactSectionContainer>
      <Container maxWidth={"600px"} padX="0">
        <SectionHeader>
          <TextPill border="1px solid var(--gray)" fontWeight="600">
            Impact
          </TextPill>
          <SectionTitle color={"linear-gradient(to left, #6d7682, #334050)"}>
            How FINANZIA impacts our users finances
          </SectionTitle>
        </SectionHeader>
      </Container>
      <ImpactContent />
    </ImpactSectionContainer>
  )
}

function ImpactContent() {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [startYPosition, setStartYPosition] = useState(0)
  const sectionRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // Set the initial Y-position of the component
    const handleInitialYPosition = () => {
      if (sectionRef.current) {
        const position =
          sectionRef.current.getBoundingClientRect().top + window.scrollY
        setStartYPosition(position)
      }
    }

    handleInitialYPosition() // Set position on mount

    window.addEventListener("resize", handleInitialYPosition) // Re-calculate on resize
    return () => window.removeEventListener("resize", handleInitialYPosition)
  }, [])

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (sectionRef.current) {
        const boundingRect = sectionRef.current.getBoundingClientRect()

        // Check if the scroll is within the component's area
        if (
          boundingRect.top <= 0 &&
          boundingRect.bottom >= window.innerHeight
        ) {
          setScrollPosition(window.scrollY)
          document.body.style.overflow = "hidden" // Block page scroll
        } else {
          document.body.style.overflow = "auto" // Enable page scroll
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
      document.body.style.overflow = "auto" // Re-enable scroll when component unmounts
    }
  }, [])

  const getOpacity = (index: number) => {
    const threshold = 300
    const adjustedScrollPosition = scrollPosition - startYPosition + 1500
    console.log("starting position", startYPosition)
    console.log("adjusted scroll position", adjustedScrollPosition)
    const diff = adjustedScrollPosition - index * threshold

    if (diff < 0) return 0
    if (diff > threshold) return 0
    return 1 - Math.abs(diff / threshold)
  }

  return (
    <ImpactSectionContent ref={sectionRef}>
      <ImpactItem style={{opacity: getOpacity(0)}}>
        <h3>Save money</h3>
        <p>
          By using FINANZIA, you can easily track your expenses and see your
          progress over time. This way, you can make better financial decisions
          and save money.
        </p>
      </ImpactItem>
      <ImpactItem style={{opacity: getOpacity(1)}}>
        <h3>Get insights</h3>
        <p>
          FINANZIA provides you with insights into your finances, such as
          monthly income, monthly expenses, and your net worth. This way, you
          can make better financial decisions and save money.
        </p>
      </ImpactItem>
      <ImpactItem style={{opacity: getOpacity(2)}}>
        <h3>Stay on top of your finances</h3>
        <p>
          By using FINANZIA, you can easily track your expenses and see your
          progress over time. This way, you can make better financial decisions
          and save money.
        </p>
      </ImpactItem>
    </ImpactSectionContent>
  )
}

const ImpactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ImpactSectionContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 300px;
`

const ImpactItem = styled.div`
  position: absolute;
  will-change: opacity;
`
