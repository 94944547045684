import {useEffect} from "react"
import {useAuth} from "../../contexts/AuthContext"
import {useFeatureContext} from "../../contexts/FeatureContext"
import FeatureRow from "./FeatureRow"
import "./Features.css"

export default function FeatureList() {
  const {features, getFeatureSlugsAndMetadata, getUserVotes} =
    useFeatureContext()
  const {currentUser} = useAuth()
  const {voteTotals, getVoteTotals} = useFeatureContext()

  useEffect(() => {
    if (voteTotals) return
    getVoteTotals()
  }, [voteTotals])

  useEffect(() => {
    if (!features) {
      getFeatureSlugsAndMetadata()
    }

    getUserVotes()
  }, [])

  return (
    <div className="feature-list-container">
      <div className="feature-list-header">
        <h2>Proposed features</h2>
        <div className="vote-closing">
          <p>Vote closes on</p>
          <h4>23th September, 18:00h</h4>
        </div>
      </div>
      <div className="feature-list">
        {features &&
          features.map((feature) => {
            return <FeatureRow key={`${feature.slug}`} feature={feature} />
          })}
      </div>
    </div>
  )
}
