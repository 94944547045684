import {useEffect, useState} from "react"
import {useKPIs} from "../../../contexts/KpisContext"
import KpiAreaChart from "../KpiAreaChart"

export default function DailyNewUsers() {
  const {dailyNewUsers} = useKPIs()
  const [todayNewUsers, setTodayNewUsers] = useState()
  const [yesterdayNewUsers, setYesterdayNewUsers] = useState()
  const [data, setData] = useState()
  const [dataLabels, setDataLabels] = useState()

  useEffect(() => {
    if (dailyNewUsers && dailyNewUsers.length > 0) {
      setTodayNewUsers(dailyNewUsers[0].new_users)
      setYesterdayNewUsers(dailyNewUsers[1].new_users)
      const dataArray = dailyNewUsers
        .map((transaction) => transaction.new_users)
        .reverse()

      setData(dataArray)

      const dataLabelsArray = dailyNewUsers
        .map((transaction) => transaction.date)
        .reverse()

      setDataLabels(dataLabelsArray)
    }
  }, [dailyNewUsers])

  return (
    <div className="kpi">
      <div className="kpi-header">
        <p className="kpi-title">Daily new users</p>

        <div className="today-kpi-value">
          <h3>{todayNewUsers}</h3>
          <p>{todayNewUsers - yesterdayNewUsers}</p>
        </div>
      </div>

      <KpiAreaChart
        data={data}
        dataLabels={dataLabels}
        datasetLabel={"New users"}
      />
    </div>
  )
}
