import {useEffect, useState} from "react"
import "./Post.css"
import {useParams} from "react-router"
import {useBlog} from "../../contexts/BlogContext"
import {PostMetadata} from "../../components/posts/PostMetadata"

import ReactMarkdown from "react-markdown"
export default function BlogPost() {
  const {slug} = useParams()
  const [post, setPost] = useState(null)
  const {getPostContent} = useBlog()

  useEffect(() => {
    const fetchPost = async () => {
      const postContent = await getPostContent(slug)
      setPost(postContent)
    }
    fetchPost()
  }, [slug])

  useEffect(() => {
    if (post) document.title = `${post.metadata.title} - Finanzia`
  }, [post])

  return (
    <main className="single-post">
      {post && <PostMetadata metadata={post.metadata} slug={slug} />}
      {post && (
        <section className="post-content">
          <ReactMarkdown
            components={{
              img: ({node, ...props}) => (
                <div className="image-container">
                  <img {...props} />
                </div>
              ),
            }}
          >
            {post.content}
          </ReactMarkdown>
        </section>
      )}
    </main>
  )
}
