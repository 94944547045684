import {useState} from "react"
import {TextAreaInput} from "../inputs/TextArea"
import "./Feedback.css"
import PrimaryButton from "../buttons/PrimaryButton"
import ImageUpload from "../inputs/ImageInput"
import {FormRow} from "../forms/FormUtils"
import {useAuth} from "../../contexts/AuthContext"
import toast from "react-hot-toast"
import axios from "axios"
import {supabase} from "../../supabase"

export default function DashboardFeedback() {
  const [feedback, setFeedback] = useState("")
  const [images, setImages] = useState([])
  const [imageNames, setImageNames] = useState([]) // State to hold original image names
  const {currentUser} = useAuth()

  const handleInputChange = (e) => {
    setFeedback(e.target.value)
  }

  const onSend = async (e) => {
    e.preventDefault()
    if (!currentUser) return

    if (feedback === "") {
      toast.error("Please write your feedback")
      return
    }

    try {
      const BASE_URL =
        "https://discord.com/api/webhooks/1285579535311441972/83QCJAQTtWMdF1G1NHwZ0BpqyO9B6Zrkp2EQADnraMfmKcNQAfgP31cPpRifs-cD8wim"

      // Create embeds for each image (if images exist)
      const imageEmbeds =
        images.length > 0
          ? images.map((imageUrl) => ({
              url: "https://your-website.com", // replace with your desired link or leave it as-is
              image: {
                url: imageUrl, // each image's public URL
              },
            }))
          : []

      // Add an embed for the feedback text
      const feedbackEmbed = {
        title: `${currentUser.name} ${currentUser.surname} - ${currentUser.username} - ${currentUser.email}`,
        description: feedback,
        color: 5814783,
      }

      // Combine feedback embed with image embeds (if any)
      const embeds = [feedbackEmbed, ...imageEmbeds]

      const response = await axios.post(BASE_URL, {
        content: "Hi there devs! New feedback received.",
        embeds: embeds, // Send all embeds including feedback and images if available
      })

      if (response.status === 204) {
        toast.success("Feedback sent successfully.")
        setFeedback("")
        setImages([])
        setImageNames([]) // Reset the image names after successful submission
      } else {
        toast.error("Could not send feedback. Please try again later.")
      }
    } catch (err) {
      console.error("Error sending feedback:", err)
      toast.error("Could not send feedback. Please try again later.")
    }
  }

  return (
    <section className="dashboard-feedback">
      <div>
        <h2>Share your feedback</h2>
        <p>
          We would love to hear your thoughts on FINANZIA. And keep in mind, we
          will make the changes you propose.
        </p>
      </div>

      <form onSubmit={onSend}>
        <FormRow>
          <TextAreaInput
            inputId={"issue_report"}
            inputName={"feedback"}
            inputPlaceholder={"Write your feedback here..."}
            inputValue={feedback}
            additionalClasses={"input-container"}
            textAlign="left"
            onChange={handleInputChange}
          />

          <ImageUpload
            images={images}
            setImages={setImages}
            setImageNames={setImageNames}
          />
        </FormRow>

        {/* Show list of uploaded image names */}
        {imageNames.length > 0 && (
          <div className="image-name-list">
            <h4>Uploaded Images:</h4>
            <ul style={{marginTop: "4px"}}>
              {imageNames.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}

        <PrimaryButton
          backgroundColor="var(--white)"
          color="var(--gray)"
          buttonText={"Send"}
          type="submit"
        />
      </form>
    </section>
  )
}
