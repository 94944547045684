import ExistingCategories from "../../../static/resources/images/onboarding/6_existing_categories.png"

export default function ExistingCategoriesStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Existing categories</h2>
      </div>
      <div className="step-content">
        <p>
          Once again, below the create category form you will see a summary of
          your existing categories divided by their type.
        </p>

        <div className="note">
          <p>
            <b>Note:</b> As you register into FINANZIA, two categories are
            created automatically, one for income and one for expenses. You can
            edit them.
          </p>
        </div>

        <img
          style={{
            maxHeight: "250px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={ExistingCategories}
          alt="Accounts tab"
        />
        <p>
          By clicking on any of the categories you will be redirected to a
          specific page for the category (which is still in development). For
          now, you will be able to edit the category or delete it.
        </p>

        <div className="note">
          <p>
            <b>Note:</b> You won't be able to delete a category if it is the
            only category left for a given type. Edit it or create another one
            before deleting.
          </p>
        </div>
      </div>
    </div>
  )
}
