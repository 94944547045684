import {useEffect} from "react"
import {CreateAccountForm} from "../../components/accounts/AccountForms"
import {DashboardPanel} from "../../components/dashboard/DashboardBasics"
import FinancialSummary from "../../components/dashboard/FinancialSummary"
import {TransactionActions} from "../../components/dashboard/TransactionActions"
import PageHeader from "../../components/pages/PageHeader"
import {CreateTransactionForm} from "../../components/transactions/TransactionForms"
import {
  TransactionList,
  TransactionTable,
} from "../../components/transactions/TransactionGroups"
import {useAuth} from "../../contexts/AuthContext"
import {StatsProvider, useStats} from "../../contexts/StatsContext"
import {useTransactionContext} from "../../contexts/TransactionContext"
import CategoryBreakdown from "../../components/charts/CategoryBreakdown"
import {useResponsive} from "../../contexts/ResponsiveContext"
import IncomeExpenses from "../../components/charts/IncomeExpenses"
import {DashboardAccounts} from "../../components/accounts/DashboardAccounts"
import RecurrenceSummary from "../../components/dashboard/recurrentTranactions/RecurrenceSummary"
import {useOnboarding} from "../../contexts/OnboardingContext"
import QuestionIcon from "../../components/icons/QuestionIcon"
import {DashboardBudgets} from "../../components/budgets/DashboardBudgets"
import DashboardFeedback from "../../components/dashboard/Feedback"
import {useAccounts} from "../../contexts/AccountContext"
import {usePopup} from "../../contexts/PopupContext"
import AccountGroupMappingPopup from "../../components/accounts/AssingAccountGroup"
import {useKnowledge} from "../../contexts/KnowledgeContext"

export default function Dashboard() {
  const {currentUser} = useAuth()
  const {getAllAccounts, accounts, getAccountGroups} = useAccounts()
  const {transactions, getAllTransactions, getAllCategories} =
    useTransactionContext()
  const {dashboardLayout, isPhone} = useResponsive()
  const {showKnowledgePopup} = useKnowledge()

  const {showPopup} = usePopup()

  useEffect(() => {
    document.title = "Dashboard - Finanzia"
  }, [])

  useEffect(() => {
    if (!currentUser || !currentUser.uid) return
    getAllTransactions(currentUser.uid)
    getAllCategories(currentUser.uid)
    getAllAccounts(currentUser.uid)
    getAccountGroups()
  }, [currentUser])

  const dashboardRightClass = `dashboard-right ${
    dashboardLayout.right ? "isActive" : ""
  }`
  return (
    <main className="dashboard default-page">
      <StatsProvider>
        <section className="dashboard-left">
          <PageHeader
            title={"Dashboard"}
            actions={
              <span
                onClick={() => {
                  showKnowledgePopup()
                }}
                style={{cursor: "pointer"}}
              >
                {" "}
                <QuestionIcon
                  color={"#334050"}
                  width={isPhone ? "20px" : "24px"}
                />
              </span>
            }
          />
          <FinancialSummary />
          <div className="breakdown-row reversed">
            <DashboardPanel>
              <IncomeExpenses />
            </DashboardPanel>
            <DashboardPanel>
              <CategoryBreakdown />
            </DashboardPanel>
          </div>
          {isPhone && (
            <DashboardPanel>
              <TransactionList
                key={transactions.length}
                title={"Your transactions"}
                maxHeight={"45dvh"}
              />
            </DashboardPanel>
          )}
          <div className="breakdown-row">
            <DashboardPanel>
              <DashboardAccounts />
            </DashboardPanel>
            <DashboardPanel>
              <DashboardBudgets />
            </DashboardPanel>
          </div>
          <div className="breakdown-row">
            <DashboardPanel>
              <RecurrenceSummary />
            </DashboardPanel>
          </div>

          <DashboardPanel backgroundColor="var(--gray)">
            <DashboardFeedback />
          </DashboardPanel>
        </section>

        {dashboardLayout && dashboardLayout.right && (
          <section className={dashboardRightClass}>
            <DashboardPanel orientation={"vertical"} padding={"18px 30px 20px"}>
              <TransactionActions />
            </DashboardPanel>
            <DashboardPanel maxHeight={"35%"} orientation={"vertical"}>
              <TransactionList
                key={transactions.length}
                title={"Your transactions"}
              />
            </DashboardPanel>
          </section>
        )}
      </StatsProvider>
    </main>
  )
}
