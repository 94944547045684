import {useEffect} from "react"
import PostGrid from "../../components/blog/PostGrid"
import PageTitle from "../../components/pages/PageTitle"
import {useBlog} from "../../contexts/BlogContext"
import "./Blog.css"
import "../../components/blog/BlogComponents.css"

export default function BlogPage() {
  const {getPostsSlugAndMetadata} = useBlog()

  useEffect(() => {
    getPostsSlugAndMetadata()
  }, [])

  useEffect(() => {
    document.title = "Blog - Finanzia"
  }, [])

  return (
    <main className="blog">
      <section className="page-header">
        <PageTitle title="Finanzia's blog" />
      </section>

      <PostGrid />
    </main>
  )
}
