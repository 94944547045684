import {useState, useEffect} from "react"
import axios from "axios"
import {useNavigate} from "react-router-dom"
import {createUserWithEmailAndPassword} from "firebase/auth"
import {toast} from "react-hot-toast" // Ensure toast is imported

import EmailInput from "../../components/inputs/EmailInput"
import {TextInput} from "../../components/inputs/TextInput"
import PasswordInput from "../../components/inputs/PasswordInput"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import {auth} from "../../firebase"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useAuth} from "../../contexts/AuthContext"
import styled from "styled-components"
import {useAccounts} from "../../contexts/AccountContext"
import {retrieveReferralCode} from "../../domain/referrals"
import {useUser} from "../../contexts/UserContext"
import PhoneInput from "../../components/inputs/PhoneInput"
import {FormRow} from "../../components/forms/FormUtils"

const EmailSignUpForm = () => {
  const [userEmail, setUserEmail] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [userName, setUserName] = useState("")
  const [userSurname, setUserSurname] = useState("")
  const [userPhoneNumber, setUserPhoneNumber] = useState("")
  const [registerError, setRegisterError] = useState(null)

  const navigate = useNavigate()
  const {createAccount, accountService} = useAccounts()
  const {createCategory, categoryService} = useTransactionContext()

  const {authService} = useAuth()
  const {storeUser, isLoading} = useUser()

  useEffect(() => {
    document.title = "Register - Finanzia"
  }, [])

  const handleSignUp = async (e) => {
    e.preventDefault()

    const signUpPromise = (async () => {
      try {
        const userCredentials = await authService.registerUser(
          userEmail,
          userPassword
        )

        const newUser = {
          email: userEmail,
          username: `${userName.toLowerCase()}.${userSurname.toLowerCase()}`,
          name: userName,
          surname: userSurname,
          uid: userCredentials.user.uid,
          phone: userPhoneNumber,
          referralCode: retrieveReferralCode(),
        }

        await storeUser(newUser)

        const newAccount = {
          name: "DEFAULT",
          description: "This is your first account",
          groupID: 1,
          currentBalance: 0,
          userID: userCredentials.user.uid,
        }
        await accountService.createAccount(newAccount)

        await categoryService.createCategory({
          name: "DEFAULT INCOME",
          description: "This is your first income category",
          currentBalance: 0,
          userID: userCredentials.user.uid,
          type: "INCOME",
        })
        await categoryService.createCategory({
          name: "DEFAULT EXPENSE",
          description: "This is your first expense category",
          currentBalance: 0,
          userID: userCredentials.user.uid,
          type: "EXPENSE",
        })

        navigate("/app/dashboard")
      } catch (error) {
        console.error("Registration error: ", error)
        setRegisterError(error.message)
        throw error
      }
    })()

    toast.promise(
      signUpPromise,
      {
        loading: "Registering your account...",
        success: "Registration complete! Redirecting to dashboard...",
        error: (err) => `Registration failed: ${err.message}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
          icon: "🎉",
        },
      }
    )
  }

  return (
    <main className="signup-page">
      <section className="signup-container">
        <div className="auth-header centered">
          <AuthTitle>Create a new account</AuthTitle>
          <AuthText>
            Fill the details below to create a new account and start using
            Finanzia
          </AuthText>
        </div>
        <form className="auth-form" style={{padding: "0px 4px"}}>
          {registerError && <p>Error: {registerError}</p>}
          <FormRow additionalClasses="responsiveColumn">
            <TextInput
              inputName="name"
              inputPlaceholder="Nombre"
              textAlign="center"
              additionalClasses={"input-container"}
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextInput
              inputName="surname"
              inputPlaceholder="Apellido"
              textAlign="center"
              additionalClasses={"input-container"}
              onChange={(e) => setUserSurname(e.target.value)}
            />
          </FormRow>
          <EmailInput
            inputName="username"
            inputPlaceholder="Correo electrónico"
            textAlign="center"
            additionalClasses={"input-container"}
            onChange={(e) => setUserEmail(e.target.value)}
            marginTop="4px"
          />
          <PhoneInput
            inputName="phoneNumber"
            inputPlaceholder="Teléfono"
            textAlign="center"
            additionalClasses={"input-container"}
            onChange={(e) => setUserPhoneNumber(e.target.value)}
            marginTop="4px"
          />

          <PasswordInput
            inputName="userPassword"
            inputPlaceholder="Contraseña"
            textAlign="center"
            additionalClasses={"input-container"}
            onChange={(e) => setUserPassword(e.target.value)}
            hasToggle={true}
          />
          <PrimaryButton
            buttonStyles={{
              width: "100%",
              margin: "auto",
              marginTop: "20px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0px",
            }}
            buttonText="Crear una cuenta"
            onClick={handleSignUp}
            isDisabled={isLoading}
          />
        </form>
      </section>
    </main>
  )
}

const AuthTitle = styled.h2`
  font-size: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 28px;
  }
`

const AuthText = styled.p`
  width: 90%;
  margin: auto;
  font-weight: 300;
`

export default EmailSignUpForm
