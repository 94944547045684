import {useKPIs} from "../../contexts/KpisContext"
import DailyBudgets from "./usage/DailyBudgets"
import DailyRecurrences from "./usage/DailyRecurrences"
import DailyTransactions from "./usage/DailyTransactions"

export default function UsageKpis() {
  return (
    <section>
      <h2>Usage metrics</h2>
      <div className="kpi-row">
        <DailyTransactions />
        <DailyRecurrences />
        <DailyBudgets />
      </div>
    </section>
  )
}
