import RecurrentTransactions from "../../../static/resources/images/onboarding/10_recurrent_transactions.png"

export default function RecurrentTransactionsStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Recurrent transactions</h2>
      </div>
      <div className="step-content">
        <p>
          Most of your financial movements might come from recurrent
          transactions. Think about:
        </p>
        <ul>
          <li>Your streaming subscriptions</li>
          <li>Your music platform subscription</li>
          <li>Your gym membership</li>
          <li>Your salary</li>
        </ul>

        <p>
          {" "}
          We know that it's tedious (and easy to forget) to add these recurrent
          transactions. Thus, we've built a feature that allows you to set up
          recurrent transations.
        </p>

        <p>
          {" "}
          To do so, click on the iconn beside the <i> Add transaction</i> form
          and you will see the following form:
        </p>

        <img
          style={{
            maxHeight: "300px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={RecurrentTransactions}
          alt="Accounts tab"
        />
        <p>
          As you can see, the form is very similar to the transaction one. There
          is, however, small differences: You have to choose the recurrence
          period and the date when the transaction will be executed.
        </p>
        <div className="note">
          <p>
            <b>Note:</b> As of now, only <i>monthly</i> recurrences are
            supported, but we are working on adding:
            <ul>
              <li>Weekly recurrences</li>
              <li>Yearly recurrences</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  )
}
