import {useEffect, useState} from "react"
import {SubmitButton} from "../../../components/forms/FormUtils"
import UploadIcon from "../../../components/icons/UploadIcon"
import {TransactionAccountInput} from "../../../components/inputs/TransationInputs"
import {useAccounts} from "../../../contexts/AccountContext"
import {useTransactionContext} from "../../../contexts/TransactionContext"
import {useAuth} from "../../../contexts/AuthContext"
import styled from "styled-components"
import {useTransactionImageUpload} from "../../../domain/uploads"
import {
  TransactionDetails,
  TransactionRow,
} from "../../../components/transactions/TransactionGroups"
import {usePopup} from "../../../contexts/PopupContext"

export default function BulkUploadPage() {
  const {accounts, getAllAccounts} = useAccounts()
  const {categories, getAllCategories} = useTransactionContext()
  const {currentUser} = useAuth()
  const {uploadTransactionImage, uploadedTransactions, isLoading} =
    useTransactionImageUpload()
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null)
  const [image, setImage] = useState<File | null>(null)
  const {showPopup} = usePopup()

  if (!accounts || accounts.length === 0) {
    getAllAccounts(currentUser.uid)
  }

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setSelectedAccount(accounts[0].id)
    }
  }, [accounts])

  if (!categories || categories.length === 0) {
    getAllCategories(currentUser.uid)
  }

  const handleImageChange = (e: any) => {
    const file = e.target.files[0]

    if (file) {
      setImage(file)
    }
  }

  const handleTransactionClick = (
    transaction: any,
    account: any,
    category: any
  ) => {
    showPopup({
      content: (
        <TransactionDetails
          transaction={transaction}
          account={account}
          category={category}
          type={undefined}
        />
      ),
    })
  }

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        maxWidth: "600px",
        margin: "auto",
        alignItems: "center",
        padding: "2rem 2rem",
        boxSizing: "border-box",
      }}
    >
      <div>
        <Headline> Beta</Headline>
        <h1 className="page-title" style={{textAlign: "center"}}>
          Upload with AI
        </h1>
      </div>
      <p style={{textAlign: "center", maxWidth: "400px"}}>
        Upload a screenshot of your transactions and our AI model will process
        them
      </p>

      <form
        style={{
          width: "400px",
          maxWidth: "100%",
        }}
      >
        <div className="image-upload-input gray">
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
            htmlFor="image-upload"
          >
            <UploadIcon
              color={"var(--gray)"}
              width={"24px"}
              height={undefined}
            />
            Choose image to upload
            <Headline>{image?.name}</Headline>
          </label>
          <input
            id="image-upload"
            type="file"
            multiple
            accept=".jpg, .jpeg, .png"
            onChange={handleImageChange}
            style={{display: "none"}} // hide the default file input button
          />
        </div>
        <TransactionAccountInput
          inputId={"transaction_account"}
          inputLabel={undefined}
          inputName={"Bulk update account"}
          showLabel={true}
          accounts={accounts}
          onChange={(e: any) => setSelectedAccount(e.target.value)}
          selectedAccountId={selectedAccount}
        />
        <SubmitButton
          buttonText={isLoading ? "Processing..." : "Upload"}
          transType={"INCOME"}
          onSend={() => uploadTransactionImage(image, selectedAccount)}
          isDisabled={isLoading}
        />
      </form>

      {uploadedTransactions && (
        <h3 style={{marginTop: "1rem"}}>Added transactions</h3>
      )}
      {uploadedTransactions && uploadedTransactions.length > 0 && (
        <div style={{maxHeight: "300px", overflowY: "auto", maxWidth: "100%"}}>
          {uploadedTransactions.map((transaction: any, index) => {
            const category = categories.find(
              (cat: any) => cat.id === transaction.categoryID
            )

            const account = accounts.find(
              (acc: any) => acc.id === transaction.accountID
            )
            return (
              <TransactionRow
                transaction={transaction}
                key={index}
                rowKey={index}
                category={category}
                account={account}
                onClick={handleTransactionClick}
              />
            )
          })}
        </div>
      )}

      {uploadedTransactions && uploadedTransactions.length === 0 && (
        <Headline>No transactions found</Headline>
      )}
    </main>
  )
}

const Headline = styled.h4`
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--light-gray);
  text-align: center;
`
