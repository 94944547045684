import toast from "react-hot-toast"

const validateBudget = (budget) => {
  if (!validateBudgetLimit(budget.budgetLimit)) {
    toast.error("Invalid budget limit")
    return false
  }

  if (budget.accountIDs === "") {
    toast.error("No accounts selected")
    return false
  }
  if (budget.categoryIDs === "") {
    toast.error("No categories selected")
    return false
  }

  if (!validateDate(budget.startDate)) {
    toast.error("Invalid start date")
    return false
  }

  if (budget.temporalType === "RECURRENT") {
    if (!budget.timeSpan || budget.timeSpan === "") {
      toast.error("Please select a periodicity")
      return false
    }
  }

  if (budget.temporalType === "ONE_TIME") {
    if (!validateDate(budget.endDate)) {
      toast.error("Invalid end date")
      return false
    }

    if (!validateBudgetDates(budget.startDate, budget.endDate)) {
      toast.error("Start date must be before end date")
      return false
    }
  }

  return true
}

const validateBudgetLimit = (budgetLimit) => {
  if (budgetLimit === "") {
    return false
  }
  const parsedBudgetLimit = parseFloat(budgetLimit)
  if (isNaN(parsedBudgetLimit)) {
    return false
  }
  return true
}

const validateDate = (date) => {
  if (date === "") {
    return false
  }
  const parsedDate = new Date(date)
  if (isNaN(parsedDate)) {
    return false
  }
  return true
}

const validateBudgetDates = (startDate, endDate) => {
  if (!startDate || !endDate) return false

  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)

  if (startDateObj > endDateObj) return false

  return true
}

export {validateBudgetDates, validateBudget}
