export default function InfoIcon({color, height, width = "26px"}) {
  return (
    <svg
      width={width}
      height={height ? height : width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke={color} stroke-width="1.5" />
      <path
        d="M12 17V11"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(1 0 0 -1 11 9)"
        fill={color}
      />
    </svg>
  )
}
