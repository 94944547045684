import React from "react"
import {useState} from "react"
import DescriptionIcon from "../icons/DescriptionIcon"
import CloseIcon from "../icons/CloseIcon"
import {FormRow, SubmitButton} from "../forms/FormUtils"
import {DateInput} from "../inputs/DateInput"
import {TextInput} from "../inputs/TextInput"
import toast from "react-hot-toast"
import {useAuth} from "../../contexts/AuthContext"
import {useBudgetContext} from "../../contexts/BudgetsContext"
import "./../forms/Forms.css"
import {
  BudgetLimitInput,
  BudgetSingleInput,
  BudgetMultiSelectInput,
} from "../inputs/BudgetInputs"
import {currentDate, currentDatePlusMonths} from "../../utils/transactionUtils"
import PrimaryButton from "../buttons/PrimaryButton"
import {useAccounts} from "../../contexts/AccountContext"
import {validateBudget, validateBudgetDates} from "../../utils/budgetUtils"

// TODO -> Improve to accept recurrent budgets
export function CreateBudgetForm({hideTitle = false}) {
  const {currentUser} = useAuth()
  const {accounts} = useAccounts()
  const {createBudget, getAllBudgets} = useBudgetContext()

  const [budgetName, setBudgetName] = useState()
  const [budgetDescription, setBudgetDescription] = useState()
  const [budgetLimit, setBudgetLimit] = useState()
  const [budgetDate, setBudgetDate] = useState(currentDate())
  const [budgetEndDate, setBudgetEndDate] = useState(currentDatePlusMonths(1))
  const [budgetPeriod, setBudgetPeriod] = useState("MONTHLY")
  const [budgetAccounts, setBudgetAccounts] = useState([])
  const [budgetCategories, setBudgetCategories] = useState([])
  const [showDescription, setShowDescription] = useState(false)

  const [budgetType, setBudgetType] = useState("RECURRENT")

  const updateBudgetName = (e) => {
    setBudgetName(e.target.value)
  }

  const updateBudgetDescription = (e) => {
    setBudgetDescription(e.target.value)
  }

  const updateBudgetLimit = (e) => {
    setBudgetLimit(e.target.value)
  }

  const updateBudgetDate = (e) => {
    setBudgetDate(e.target.value)
  }

  const updateBudgetEndDate = (e) => {
    setBudgetEndDate(e.target.value)
  }

  const updateBudgetPeriod = (e) => {
    setBudgetPeriod(e.target.value)
  }

  const updateBudgetType = (e) => {
    setBudgetType(e.target.value)
  }

  const updateBudgetAccounts = (value) => {
    setBudgetAccounts(value)
    console.log(budgetAccounts)
  }

  const updateBudgetCategories = (value) => {
    setBudgetCategories(value)
  }

  const sendBudgetToCreate = async (e) => {
    e.preventDefault()

    const categoryIds = budgetCategories
      .map((category) => category.id)
      .join(",")
    const accountIds = budgetAccounts.map((account) => account.id).join(",")

    try {
      const budgetBody = {
        name: budgetName,
        description: budgetDescription,
        budgetLimit: budgetLimit,
        accountIDs: accountIds,
        categoryIDs: categoryIds,
        temporalType: budgetType,
        timeSpan: budgetPeriod,
        startDate: budgetDate,
        endDate: budgetType === "ONE_TIME" ? budgetEndDate : null,
        userID: currentUser.uid,
      }

      if (!validateBudget(budgetBody)) return

      const budgetCreated = await createBudget(budgetBody)

      if (budgetCreated) {
        toast.success("Budget created")
        getAllBudgets(currentUser.uid)
      } else {
        toast.error("Something went wrong. Try again please :)")
      }
    } catch (err) {
      setBudgetType("ONE_TIME")
      console.log(err)
      toast.error("Something went wrong. Try again please :)")
    }
  }

  const budgetPeriodOptions = [
    {value: "MONTHLY", name: "Monthly"},
    {value: "YEARLY", name: "Yearly"},
  ]

  const budgetTypeOptions = [
    {
      value: "ONE_TIME",
      name: "One time",
    },
    {
      value: "RECURRENT",
      name: "Recurrent",
    },
  ]

  return (
    <section className="container " style={{paddingBottom: "20px"}}>
      {!hideTitle && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div style={{display: "flex", gap: "0.8rem", alignItems: "flex-end"}}>
            <h2>Create budget</h2>
          </div>
        </div>
      )}

      <form className="create-budget-form" onSubmit={sendBudgetToCreate}>
        <FormRow>
          <TextInput
            labelText={"Name"}
            inputId={"budget_name"}
            inputName={"Name"}
            inputPlaceholder={"Name"}
            additionalClasses={"input-container"}
            textAlign={"center"}
            onChange={updateBudgetName}
          />
          <span
            style={{cursor: "pointer"}}
            onClick={() => {
              setShowDescription(!showDescription)
            }}
          >
            {!showDescription ? (
              <DescriptionIcon color={"#334050"} />
            ) : (
              <CloseIcon isFilled={false} color={"#334050"} />
            )}
          </span>
        </FormRow>

        {showDescription && (
          <FormRow>
            <TextInput
              labelText={"Description"}
              inputId={"budget_description"}
              inputName={"Description"}
              inputPlaceholder={"Description"}
              isRequired={false}
              additionalClasses={"input-container"}
              textAlign="center"
              onChange={updateBudgetDescription}
            />
          </FormRow>
        )}

        <FormRow>
          <BudgetLimitInput
            onChange={updateBudgetLimit}
            inputValue={budgetLimit}
          />
          <BudgetSingleInput
            inputId={"budget_type"}
            inputLabel={"Type"}
            onChange={updateBudgetType}
            selectedPeriod={budgetType}
            options={budgetTypeOptions}
            placeholder={"Type"}
          />
        </FormRow>

        <FormRow>
          <BudgetMultiSelectInput
            onChange={updateBudgetAccounts}
            selectedCategories={budgetAccounts}
            id={"budgets-category-autocomplete"}
            label={"Accounts"}
            use={"Accounts"}
          />
        </FormRow>
        <FormRow>
          <BudgetMultiSelectInput
            onChange={updateBudgetCategories}
            selectedCategories={budgetCategories}
            id={"budgets-category-autocomplete"}
            label={"Categories"}
            use={"Categories"}
          />
        </FormRow>
        <FormRow>
          {budgetType === "RECURRENT" && (
            <BudgetSingleInput
              inputId={"budget_period"}
              inputLabel={"Periodicity"}
              onChange={updateBudgetPeriod}
              selectedPeriod={budgetPeriod}
              options={budgetPeriodOptions}
              placeholder={"Period"}
              showLabel={true}
            />
          )}
          <DateInput
            inputValue={budgetDate}
            onChange={updateBudgetDate}
            inputLabel={"Start date"}
          />
          {budgetType === "ONE_TIME" && (
            <DateInput
              inputValue={budgetEndDate}
              onChange={updateBudgetEndDate}
              inputLabel={"End date"}
            />
          )}
        </FormRow>
        <PrimaryButton buttonText={"Create budget"} type="submit" />
      </form>
    </section>
  )
}
