import {NumberInput} from "./NumberInput"
import SelectSearch from "react-select-search"
import {Box, Chip, TextField, Autocomplete} from "@mui/material"
import "./SelectSearch.css"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useEffect, useState} from "react"
import { useAccounts } from '../../contexts/AccountContext'

const BudgetLimitInput = ({
  onChange,
  inputId = "budget_limit",
  inputPlaceholder = "Budget Limit",
  inputName = "Budget Limit",
  inputValue,
}) => {
  return (
    <div className="input-container budget-limit-input">
      <NumberInput
        inputValue={inputValue}
        inputId={inputId}
        onChange={onChange}
        inputPlaceholder={inputPlaceholder}
        inputName={inputName}
      />
    </div>
  )
}

const BudgetSingleInput = ({
  onChange,
  inputLabel,
  inputId,
  inputName,
  selectedPeriod,
  showLabel = false,
  options = [],
  placeholder,
}) => {
  const handleChange = (value) => {
    // Simulate a native event structure for compatibility
    onChange({
      target: {
        value: value,
        name: inputName,
        id: inputId,
      },
    })
  }

  return (
    <div className="input-container period-input">
      {showLabel && inputLabel && <label htmlFor={inputId}>{inputLabel}</label>}

      <SelectSearch
        id={inputId}
        name={inputName}
        options={options}
        value={selectedPeriod}
        onChange={handleChange}
        search
        placeholder={placeholder}
      />
    </div>
  )
}

const BudgetMultiSelectInput = ({
  onChange,
  selectedCategories,
  id,
  label = "Categories",
  use = "Categories"
}) => {
    const {accounts} = useAccounts()
  const {categories} = useTransactionContext()
  const [expenseCategories, setExpenseCategories] = useState([])
  const [optionAccounts, setOptionAccounts] = useState([])

  const handleChange = (event, value) => {
    onChange(value)
  }

  useEffect(() => {
    setExpenseCategories(
      categories.filter((category) => category.type === "EXPENSE")
    )
  }, [categories])

  useEffect(() => {
    setOptionAccounts(
      accounts
    )
  }, [accounts])

  return (
    <Autocomplete
      multiple
      id={id}
      options={use === "Categories" ? expenseCategories : optionAccounts}
      getOptionLabel={(option) => option.name}
      value={selectedCategories}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          sx={{
            "& .MuiInputLabel-root.Mui-focused": {
              color: "var(--primary)", // Change to your desired color
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "var(--primary)", // Change to your desired color
              },
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.name}
            {...getTagProps({index})}
            key={option.id}
            sx={{
              borderRadius: "8px",
              backgroundColor: "var(--gray)",
              color: "var(--white)",
            }}
          />
        ))
      }
      sx={{my: 1, width: "100%"}}
    />
  )
}

export {BudgetLimitInput, BudgetSingleInput, BudgetMultiSelectInput}
