import toast from "react-hot-toast"
import {useAuth} from "../../contexts/AuthContext"
import {usePopup} from "../../contexts/PopupContext"
import PrimaryButton from "../buttons/PrimaryButton"
import SecondaryButton from "../buttons/SecondaryButton"
import {ClosePopupButton} from "../popups/SimplePopup"
import {useNavigate} from "react-router"

export default function DeleteAccount() {
  const {currentUser, deleteUser} = useAuth()
  const {showPopup, hidePopup} = usePopup()
  const navigate = useNavigate()

  const deleteUserAccount = async () => {
    const isUserDeleted = await deleteUser(currentUser.uid)
    if (isUserDeleted) {
      toast.success("User deleted")
      hidePopup()
      navigate("/")
    } else {
      toast.error("Could not delete user")
    }
  }

  const showDeleteConfirmationPopup = (e) => {
    showPopup({
      content: (
        <ConfirmAccountDeletePopup onDeleteConfirmation={deleteUserAccount} />
      ),
    })
  }
  return (
    <>
      <p>
        Your account (<b>{currentUser.email}</b>) and all your data will be
        permanently deleted from the app.
      </p>

      <SecondaryButton
        buttonText={"Delete my account"}
        textColor="var(--light-gray)"
        borderColor="var(--light-gray)"
        borderRadius="12px"
        fontWeight="300"
        borderWidth="1px"
        onClick={showDeleteConfirmationPopup}
      />
    </>
  )
}

function ConfirmAccountDeletePopup({onDeleteConfirmation}) {
  const {hidePopup} = usePopup()

  return (
    <div>
      <div className="popup-header">
        <h2> Are you sure?</h2>
        <ClosePopupButton />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <PrimaryButton
          onClick={onDeleteConfirmation}
          buttonText={"Yes, delete!"}
          borderWidth={"2px"}
        />
        <SecondaryButton
          buttonText={"No, go back"}
          borderColor="#334050"
          onClick={() => {
            hidePopup()
          }}
        />
      </div>
    </div>
  )
}
