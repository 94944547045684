import styled from "styled-components"
import EmailInput from "../../components/inputs/EmailInput"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import {useAuth} from "../../contexts/AuthContext"
import toast from "react-hot-toast"
import {useState} from "react"

export default function ForgotPassword() {
  const {resetPassword} = useAuth()
  const [userEmail, setUserEmail] = useState("")

  const updateEmail = (e) => setUserEmail(e.target.value)

  const handleResetPassword = async (e) => {
    e.preventDefault()
    const email = userEmail

    if (email === "" || email.includes("@") === false) {
      toast.error("Please enter a valid email address")
      return
    }

    const isPasswordResetSent = await resetPassword(email)
    if (isPasswordResetSent) {
      toast.success("Password reset email sent successfully")
    } else {
      toast.error("Could not send password reset email")
    }
  }
  return (
    <main className="login-page">
      <section className="login-container">
        <div className="auth-header centered">
          <AuthTitle>Reset your password</AuthTitle>
          <AuthText>
            Enter your email address and we will send you a link to reset your
            password
          </AuthText>
        </div>

        <form className="auth-form" onSubmit={handleResetPassword}>
          <EmailInput
            inputName={"testInput"}
            inputPlaceholder={"Correo electrónico"}
            textAlign={"center"}
            additionalClasses={"input-container"}
            onChange={updateEmail}
          />

          <PrimaryButton
            buttonText={"Send reset password link"}
            buttonStyles={{
              width: "100%",
              margin: "auto",
              marginTop: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0px",
            }}
            type="submit"
          />
        </form>
      </section>
    </main>
  )
}

const AuthTitle = styled.h2`
  font-size: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 28px;
  }
`

const AuthText = styled.p`
  width: 90%;
  margin: auto;
  font-weight: 300;
`
