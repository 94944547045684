import toast from "react-hot-toast"
import {usePopup} from "../../contexts/PopupContext"
import { useBudgetContext } from "../../contexts/BudgetsContext"
import PrimaryButton from "../buttons/PrimaryButton"
import SecondaryButton from "../buttons/SecondaryButton"
import { ClosePopupButton } from "../popups/SimplePopup"

export default function DeleteBudgetConfirmation({budget}) {
    const {hidePopup} = usePopup()
    const {deleteBudget} = useBudgetContext()

    const handleDelete = async (e) => {
        const isBudgetDeleted = await deleteBudget(budget.id)

        if (isBudgetDeleted) {
            hidePopup()
            toast.success("Budget has been deleted")
        } else {
            toast.error("Could not delete budget")
        }
    }

    return (
        <div className="confirm-budget-delete">
            <div className="popup-header">
                <h2> Are you sure?</h2>
                <ClosePopupButton />
            </div>
            <p>
                {" "}
                Budget "<span style={{fontWeight: 700}}>{budget.name}</span>" will be permanently removed
            </p>

            <div
                style={{
                    paddingTop: "1.4rem",
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px"
                }}
            >
                <SecondaryButton
                    onClick={hidePopup}
                    buttonText={"No, go back"}
                    textColor="var(--primary)"
                />
                <PrimaryButton onClick={handleDelete} buttonText={"Yes, delete!"} />
            </div>
        </div>
    )
}