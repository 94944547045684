const NumberInput = ({
  onChange,
  inputId,
  inputPlaceholder,
  inputName,
  inputValue,
}) => {
  return (
    <div className="number-input">
      <input
        value={inputValue}
        type="number"
        name={inputName}
        id={inputId}
        placeholder={inputPlaceholder}
        onChange={onChange}
      ></input>
    </div>
  )
}

export {NumberInput}
