import CategoriesTab from "../../../static/resources/images/onboarding/4_categories_tab.png"

export default function CreatingCategoryStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Creating a category</h2>
      </div>
      <div className="step-content">
        <p>
          After creating an account, the next step is to setup your categories.
          You can do so using the create category form, where you can define:
        </p>
        <ul>
          <li>The name of the category</li>
          <li>The description of the category</li>
          <li>The type of the category</li>
        </ul>
        <div className="note">
          <p>
            <b>Note:</b> The type of a category can be either <i>Income</i> or
            <i>Expense</i> and they can not be changed afterwards.
          </p>
        </div>

        <img
          style={{
            maxHeight: "250px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={CategoriesTab}
          alt="Accounts tab"
        />
      </div>
    </div>
  )
}
