import {useEffect} from "react"
import {DashboardPanel} from "../../components/dashboard/DashboardBasics"
import PageHeader from "../../components/pages/PageHeader"
import {useKPIs} from "../../contexts/KpisContext"
import UsageKpis from "../../components/kpis/UsageKpis"
import "./Kpi.css"
import GrowthKpis from "../../components/kpis/GrowthKpis"
import ReloadIcon from "../../components/icons/ReloadIcon"
import LastActiveUsers from "../../components/kpis/activity/LastActiveUsers"

export default function KpiPage() {
  const {getKPIs} = useKPIs()
  useEffect(() => {
    const fetchKPIs = async () => {
      await getKPIs()
    }
    fetchKPIs()
  }, [])
  return (
    <main className=" kpi-page">
      <PageHeader
        title={"KPIs dashboard"}
        actions={<ReloadMetricsButtons />}
        hasMonthSelector={false}
      />

      <DashboardPanel>
        <UsageKpis />
      </DashboardPanel>
      <KPIRow>
        <DashboardPanel>
          <GrowthKpis />
        </DashboardPanel>
        <DashboardPanel>
          <LastActiveUsers />
        </DashboardPanel>
      </KPIRow>
    </main>
  )
}

const ReloadMetricsButtons = () => {
  const {getKPIs} = useKPIs()
  return (
    <span style={{cursor: "pointer"}} onClick={getKPIs}>
      <ReloadIcon color={"#334050"} width={"24px"} />
    </span>
  )
}

const KPIRow = ({children}) => {
  return <div className="kpi-dashboard-row">{children}</div>
}
