import React, {useContext, createContext, useState, useEffect} from "react"
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  deleteUser,
} from "@firebase/auth"
import axios from "axios"

import {AuthService} from "../domain/auth/authService"
import {FirebaseAuthProvider} from "../infraestructure/firebase/authRepository"
import {apiClient} from "../infraestructure/api/client"

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const fetchUserData = async (uid, attempt = 1) => {
          try {
            const response = await apiClient.get("/users", {params: {uid}})
            if (response) {
              setCurrentUser(response)
              setIsLoading(false)
            } else if (attempt < 3) {
              console.log(
                `Attempt ${attempt}: Failed to fetch user details, retrying...`
              )
              setTimeout(() => fetchUserData(uid, attempt + 1), 2000) // Retry after 2 seconds
            } else {
              console.error(
                "Failed to fetch user details after multiple attempts"
              )
              setIsLoading(false)
            }
          } catch (error) {
            console.error("Error fetching user details:", error)
            if (attempt < 3) {
              setTimeout(() => fetchUserData(uid, attempt + 1), 2000) // Retry after 2 seconds
            } else {
              setIsLoading(false)
            }
          }
        }

        fetchUserData(user.uid)
      } else {
        setCurrentUser(null)
        setIsLoading(false)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  async function updateUser(user) {
    try {
      const response = await apiClient.put("/users", user)
      setCurrentUser(response)
      return response
    } catch (error) {
      console.log("Error updating user", error)
      return false
    }
  }

  async function resetPassword(email) {
    const auth = getAuth()
    try {
      await sendPasswordResetEmail(auth, email)
      return true
    } catch (error) {
      console.error("Error sending password reset email:", error)
      return false
    }
  }

  async function deleteUser(userID) {
    const auth = getAuth()
    const user = auth.currentUser
    const query = {
      userID: userID,
    }

    try {
      const response = await apiClient.get("/utils/deleteAccount", {
        params: query,
      })
      if (response) {
        await user.delete()
        return true
      }
    } catch (error) {
      console.log("Error deleting user account", error)
      return false
    }
  }

  const authRepo = new FirebaseAuthProvider()
  const authService = new AuthService(authRepo)

  const value = {
    currentUser,
    setCurrentUser,
    isLoading,
    updateUser,
    resetPassword,
    deleteUser,
    authService,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
