import {createContext, useContext, useState} from "react"
import BottomSheet from "../components/layout/BottomSheet"

const BottomSheetContext = createContext()

export function useBottomSheet() {
  return useContext(BottomSheetContext)
}

export function BottomSheetProvider({children}) {
  const [isOpen, setIsOpen] = useState(false)
  const [sheetContent, setSheetContent] = useState(null)
  const showBottomSheet = (content) => {
    console.log("Opening bottom sheet")
    setSheetContent(content)
    setIsOpen(true)
  }
  const value = {showBottomSheet}
  return (
    <BottomSheetContext.Provider value={value}>
      {children}
      {isOpen && (
        <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
          {sheetContent}
        </BottomSheet>
      )}
    </BottomSheetContext.Provider>
  )
}
