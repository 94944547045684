import toast from "react-hot-toast"
import {useAuth} from "../../contexts/AuthContext"
import SecondaryButton from "../buttons/SecondaryButton"
import axios from "axios"
import {useAccounts} from "../../contexts/AccountContext"
import {apiClient} from "../../infraestructure/api/client"

export default function RecalculateAccountBalances() {
  const {currentUser} = useAuth()
  const {getAccounts} = useAccounts()

  async function caclulateAccountBalances() {
    const userID = currentUser.uid

    if (!userID) {
      toast.error("We ran into an error, please try again")
      return
    }

    const query = {
      userID: userID,
    }

    try {
      const isUpdated = await apiClient.get("/utils/recomputeAccountBalances", {
        params: query,
      })

      if (isUpdated.data) {
        toast.success("Account balances have been updated")
        getAccounts(userID)
        return
      }

      throw new Error("Error updating account balances")
    } catch (err) {
      toast.error("We ran into an error, please try again")
    }
  }

  return (
    <>
      <p>
        Are your account balances not matching up. Don’t worry, we can calculate
        them again.
      </p>

      <SecondaryButton
        buttonText={"Calculate account balances"}
        textColor="var(--primary)"
        borderRadius="12px"
        fontWeight="300"
        borderWidth="1px"
        onClick={caclulateAccountBalances}
      />
    </>
  )
}
