import FinancialSummary from "./../../../static/resources/images/onboarding/12_financial_summary.png"

export default function FinancialSummaryStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Financial summary</h2>
      </div>
      <div className="step-content">
        <p>
          The firt thing you will see on your dashboard is a financial summary.
          This section includes:
        </p>
        <ul>
          <li>
            Your total <b>net worth</b> (ie: the sum of all your transactions)
          </li>
          <li>
            Your <b>monthly income</b>
          </li>
          <li>
            Your <b>monthly expenses</b>
          </li>
          <li>
            Your monthly <b>balance</b> (ie: income - expenses)
          </li>
        </ul>

        <p>Here's how it looks like:</p>
        <img src={FinancialSummary} alt="Dashboard mockup" />
        <div className="note">
          <p>
            <b>Note:</b> By clicking on the month selector you can navigate to
            previous months and the dashboard data will get updated accordingly.
          </p>
        </div>
      </div>
    </div>
  )
}
