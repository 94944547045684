import TransactionsTab from "../../../static/resources/images/onboarding/7_transactions_tab.png"

export default function TransactionsStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Transactions</h2>
      </div>
      <div className="step-content">
        <p>
          Now that we have our accounts and categories, it's time to navigate to
          the transactions tab. Transactions are the backbone of FINANZIA, where
          everything starts. The idea is that you record a transaction for every
          income, payment or transfer you do. This way, you will be able to
          effectively track your finances and get precise analytics.
        </p>

        <div className="note">
          <p>
            <b>Note:</b> We are aware that adding each transaction manually can
            be a tadious task, but:
          </p>
          <ul>
            <li>We have in our roadmap the automation of this process</li>
            <li>It will help you get to know FINANZIA</li>
            <li>It makes you be more conscious of your spending</li>
          </ul>

          <p>
            Still, we encourage it to use the feature proposal form in the
            <i>Vote</i> page to request this feature so we can estimate how many
            people wants it.
          </p>
        </div>

        <p>There are three types of transactions:</p>
        <ul>
          <li>Income transactions</li>
          <li>Expense transactions</li>
          <li>
            Transfer transactions (ie. for internal transfers between accounts)
          </li>
        </ul>
        <p>
          Let's navigate to the transactions tab by clicking on the{" "}
          <i> Add transaction</i> button.
        </p>
        <img
          style={{
            maxHeight: "250px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={TransactionsTab}
          alt="Accounts tab"
        />
      </div>
    </div>
  )
}
