import {useNavigate, useParams} from "react-router"
import PageHeader from "../../components/pages/PageHeader"
import {StatsProvider} from "../../contexts/StatsContext"
import {useAuth} from "../../contexts/AuthContext"
import {useEffect} from "react"
import {useTransactionContext} from "../../contexts/TransactionContext"
import trashIcon from "./../../static/resources/icons/trash.svg"
import editIcon from "./../../static/resources/icons/edit.svg"
import closeIcon from "./../../static/resources/icons/close.svg"
import {usePopup} from "../../contexts/PopupContext"
import {ClosePopupButton} from "../../components/popups/SimplePopup"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import SecondaryButton from "../../components/buttons/SecondaryButton"
import toast from "react-hot-toast"
import {DashboardPanel} from "../../components/dashboard/DashboardBasics"
import {TransactionList} from "../../components/transactions/TransactionGroups"
import {EditCategoryForm} from "../../components/categories/CategoryForms"

export default function Category() {
  const {categoryID} = useParams()
  const {currentUser} = useAuth()
  const {getCategories, currentCategory} = useTransactionContext()

  useEffect(() => {
    const fetchCategory = async () => {
      const query = {
        id: categoryID,
        userID: currentUser.uid,
      }

      const retrievedCategory = await getCategories(query)
    }

    if (categoryID) {
      fetchCategory()
    }
  }, [categoryID, currentUser])

  return (
    <main className="dashboard category-page">
      <StatsProvider>
        {currentCategory && (
          <>
            <section className="dashboard-left">
              <PageHeader
                title={currentCategory && currentCategory.name}
                actions={<CategoryActions />}
              />
            </section>
            <section className="dashboard-right">
              <DashboardPanel orientation={"vertical"}>
                <TransactionList
                  title={"Category transactions"}
                  type={"byCategory"}
                />
              </DashboardPanel>
            </section>
          </>
        )}
      </StatsProvider>
    </main>
  )
}

function CategoryActions() {
  const {showPopup, hidePopup} = usePopup()
  const {deleteCategory, currentCategory} = useTransactionContext()
  const {categories} = useTransactionContext()
  const navigate = useNavigate()

  const showDeleteConfirmationPopup = (e) => {
    showPopup({
      content: <ConfirmCategoryDeletePopup onDelete={handleDeleteCategory} />,
    })
  }

  const handleDeleteCategory = async (e) => {
    const sameTypeCategories = categories.filter(
      (category) => category.type === currentCategory.type
    )

    if (sameTypeCategories.length === 1) {
      toast.error("You can't delete the last category of this type")
      return
    }

    const isCategoryDeleted = await deleteCategory(currentCategory.id)
    if (isCategoryDeleted) {
      hidePopup()
      navigate("/app/dashboard")
      toast.success(`Category ${currentCategory.name} has been deleted`)
    } else {
      toast.error("Could not delete category")
    }
  }

  const showEditCategoryPopup = (e) => {
    showPopup({content: <EditCategoryPopup category={currentCategory} />})
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <img
        src={editIcon}
        style={{fill: "#02dca2", height: "26px", cursor: "pointer"}}
        onClick={showEditCategoryPopup}
      />
      <img
        src={trashIcon}
        style={{fill: "#334050", height: "26px", cursor: "pointer"}}
        onClick={showDeleteConfirmationPopup}
      />
    </div>
  )
}

function ConfirmCategoryDeletePopup({onDelete}) {
  const {hidePopup} = usePopup()
  return (
    <div>
      <div className="popup-header">
        <h2> Are you sure?</h2>
        <ClosePopupButton icon={closeIcon} />
      </div>
      <p>
        Deleting this category will also result on all transactions in this
        category to be PERMANENTLY removed
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <PrimaryButton buttonText={"Yes, delete!"} onClick={onDelete} />
        <SecondaryButton
          buttonText={"No, go back"}
          borderColor="#334050"
          onClick={() => {
            hidePopup()
          }}
        />
      </div>
    </div>
  )
}

const EditCategoryPopup = ({category}) => {
  const {editCategory, getCategories, setCurrentCategory} =
    useTransactionContext()
  const {currentUser} = useAuth()
  const {hidePopup} = usePopup()

  const handleCategoryEdit = async (newName, newDescription, newType) => {
    const categoryBody = {
      name: newName,
      description: newDescription,
      userID: category.userID,
      currentBalance: category.currentBalance,
      type: newType,
      id: category.id,
    }

    const isCategoryEdited = await editCategory(categoryBody)

    if (isCategoryEdited) {
      hidePopup()

      const query = {
        id: category.id,
        userID: currentUser.uid,
      }

      const categoryData = await getCategories(query)
      toast.success("The category has been updated")
    } else {
      toast.error("Could not edit category")
    }
  }

  return (
    <div className="edit-transaction">
      <div className="popup-header">
        <h2>Edit category</h2>
        <ClosePopupButton icon={closeIcon} />
      </div>
      <EditCategoryForm
        onCategoryEddited={handleCategoryEdit}
        category={category}
      />
    </div>
  )
}
