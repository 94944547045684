import {Chart} from "chart.js"
import {useEffect, useRef} from "react"

export default function KpiAreaChart({data, dataLabels, datasetLabel}) {
  const chartRef = useRef(null)
  const chartInstance = useRef(null) // Keep a reference to the chart instance

  useEffect(() => {
    if (chartRef.current) {
      // Destroy previous chart instance if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }

      const ctx = chartRef.current.getContext("2d")

      // Create new chart instance
      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: dataLabels,
          datasets: [
            {
              label: datasetLabel,
              data: data,
              fill: true, // This fills the area below the line
              backgroundColor: "rgba(2, 220, 162, 0.5)", // Semi-transparent fill color
              borderColor: "rgba(2, 220, 162, 1)", // Line color
              borderWidth: 2,
              pointRadius: 0,
              tension: 0.2, // Adjust for smoothness
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
                drawBorder: false,
              },
              border: {
                display: true,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              display: true,
              grid: {
                display: false,
              },
              border: {
                display: true,
              },
              ticks: {
                display: true,
                stepSize: 1,
              },
            },
          },
        },
      })
    }
  }, [data, dataLabels, datasetLabel]) // Recreate the chart when the data changes

  return <canvas style={{maxHeight: "86%", width: "100%"}} ref={chartRef} />
}
