import "./Inputs.css"

interface PhoneInputProps {
  inputID: string
  inputName: string
  inputPlaceholder: string
  inputLabel: string
  onChange: (e: any) => void
  inputValue: string
  additionalClasses: string
  isRequired: boolean
  marginTop: string
}

export default function PhoneInput({
  inputID,
  inputName,
  inputPlaceholder,
  inputLabel,
  onChange,
  inputValue,
  additionalClasses,
  isRequired,
  marginTop,
}: PhoneInputProps) {
  return (
    <div className={`phone-input ${additionalClasses}`}>
      <label htmlFor={inputID}>{inputLabel}</label>
      <input
        style={{textAlign: "center", marginTop: marginTop}}
        placeholder={inputPlaceholder}
        id={inputID}
        name={inputName}
        type="tel"
        value={inputValue}
        onChange={onChange}
        required={isRequired ? true : false}
      />
    </div>
  )
}
