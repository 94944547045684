import styled from "styled-components"
import Container from "../layout/Container"
import SectionHeader from "../text/SectionHeader"
import SectionTitle from "../text/SectionTitle"
import TextPill from "../text/TextPill"
import {ReactComponent as MonthlyOverview} from "./../../static/resources/images/home/monthly_overview.svg"
import {ReactComponent as CategoryBreakdown} from "./../../static/resources/images/home/category_breakdown.svg"
import {ReactComponent as Insights} from "./../../static/resources/images/home/insights.svg"
import {ReactComponent as Budgets} from "./../../static/resources/images/home/budgets.svg"
import {ReactComponent as Recurrences} from "./../../static/resources/images/home/recurrences.svg"

export default function HomeFeatures() {
  return (
    <section style={{marginTop: "60px"}}>
      <Container maxWidth={"600px"} padY="2rem" padX="0">
        <SectionHeader>
          <TextPill border="1px solid var(--gray)" fontWeight="600">
            Features
          </TextPill>
          <SectionTitle color={"linear-gradient(to left, #6d7682, #334050)"}>
            Control your expenses and grow your net worth
          </SectionTitle>
        </SectionHeader>
      </Container>

      <Container maxWidth={"1400px"} padY="1rem">
        <FeatureRow>
          <FeatureCard>
            <FeatureImage>
              <MonthlyOverview style={{width: "100%"}} />
            </FeatureImage>
            <CardContent>
              <FeatureTitle>Track your income & expenses</FeatureTitle>
              <FeatureDescription>
                Track the evolution of your monthly income and expenses
              </FeatureDescription>
            </CardContent>
          </FeatureCard>
          <FeatureCard>
            <FeatureImage>
              <CategoryBreakdown style={{width: "100%"}} />
            </FeatureImage>

            <CardContent>
              <FeatureTitle>See where your money's going</FeatureTitle>
              <FeatureDescription>
                Get a breakdown of your income and expenses by category
              </FeatureDescription>
            </CardContent>
          </FeatureCard>
          <FeatureCard>
            <FeatureImage>
              <Insights style={{width: "100%"}} />
            </FeatureImage>
            <CardContent>
              <FeatureTitle>Get insights on your finances</FeatureTitle>
              <FeatureDescription>
                Discover detailed insights that help you save money
              </FeatureDescription>
            </CardContent>
          </FeatureCard>
        </FeatureRow>
        <FeatureRow
          style={{
            marginTop: "18px",
          }}
        >
          <FeatureCardWide>
            <CardContentWide>
              <FeatureTitle> Create budgets</FeatureTitle>
              <FeatureDescription>
                To track & reduce your spending and improve your financial
                habits
              </FeatureDescription>
            </CardContentWide>
            <FeatureImage>
              <Budgets style={{flex: "1", width: "100%"}} />
            </FeatureImage>
          </FeatureCardWide>
          <FeatureCardWide>
            <CardContentWide>
              <FeatureTitle> Configure recurrences</FeatureTitle>
              <FeatureDescription>
                Easy tracking of your recurrent transactions and detailed
                insights
              </FeatureDescription>
            </CardContentWide>
            <FeatureImage>
              <Recurrences style={{flex: "1", width: "100%"}} />
            </FeatureImage>
          </FeatureCardWide>
        </FeatureRow>
      </Container>
    </section>
  )
}

const FeatureRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 18px;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`
const FeatureImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 100%;

  padding: 1rem;
  box-sizing: border-box;
`

const FeatureCard = styled.div`
  border-radius: 12px;
  border: 1px solid var(--light-gray);
  box-sizing: border-box;
  padding-top: 1.2rem;

  flex: 1;

  display: flex;
  gap: 1.4rem;
  flex-direction: column;
  justify-content: space-between;

  min-width: 300px;
`

const FeatureCardWide = styled.div`
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--light-gray);
  box-sizing: border-box;
  padding: 1.4rem 2rem;

  flex: 1;

  display: flex;
  gap: 1.4rem;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: 600px) {
    padding: 0;
    gap: 0;
  }
`

const CardContent = styled.div`
  background-color: #ffffff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 1rem 2rem;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
`

const CardContentWide = styled.div`
  flex: 1;
  @media only screen and (max-width: 600px) {
    padding: 1.4rem 2rem;
    box-sizing: border-box;
  }
`

const FeatureTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
`

const FeatureDescription = styled.p`
  font-size: 0.95rem;
  font-weight: 300;
`
