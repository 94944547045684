import React, {useEffect, useState} from "react"
import ArrowIcon from "../icons/ArrowIcon"
import Delimiter from "../icons/Delimiter"
import EditIcon from "../icons/EditIcon"
import TrashIcon from "../icons/TrashIcon"
import BudgetProgressChart from "../charts/BudgetProgressChart"
import BudgetHistoryChart from "../charts/BudgetHistoryChart"
import {usePopup} from "../../contexts/PopupContext"
import DeleteBudgetConfirmation from "./DeleteBudgetConfirmation"
import EditBudgetConfirmation from "./EditBudgetConfirmation"

export default function BudgetCard({key, budget}) {
  const [budgetPercentage, setBudgetPercentage] = useState(0)
  const [budgetColor, setBudgetColor] = useState("#1E1E1E")

  useEffect(() => {
    if (!budget) return

    const percentage = parseInt(
      (budget.currentSpent / budget.budgetLimit) * 100
    )
    setBudgetPercentage(percentage)

    switch (true) {
      case percentage < 50:
        setBudgetColor("#00dca2")
        break
      case percentage < 75:
        setBudgetColor("#F1BC6D")
        break
      default:
        setBudgetColor("#F00")
        break
    }
  }, [budget])

  return (
    <div className="budget-single-card-container">
      <BudgetProgressChart
        progressColor={budgetColor}
        spentBudget={Math.min(budgetPercentage, 100)}
        realPercentage={budgetPercentage}
      />
      <div className="first-delimiter">
        <Delimiter
          color="#1E1E1E"
          height="80px"
          width="1px"
          lineStroke="1"
          lineOpacity="0.2"
        />
      </div>
      {budget && (
        <BudgetCardInfo
          budgetName={budget.name}
          currentSpent={budget.currentSpent}
          budgetLimit={budget.budgetLimit}
          startDate={budget.startDate}
          endDate={budget.endDate}
          budgetPercentage={budgetPercentage}
          budgetColor={budgetColor}
          budget={budget}
        />
      )}
      <div className="second-delimiter">
        <Delimiter
          color="#1E1E1E"
          height="80px"
          width="1px"
          lineStroke="1"
          lineOpacity="0.2"
        />
      </div>
      {budget && (
        <BudgetHistoryChart
          budgetColor={budgetColor}
          history={budget.history}
        />
      )}{" "}
    </div>
  )
}

const BudgetCardInfo = ({
  budgetName,
  currentSpent,
  budgetLimit,
  startDate,
  endDate,
  budgetPercentage,
  budgetColor,
  budget,
}) => {
  const {showPopup} = usePopup()
  const handleBudgetEdit = (budget) => {
    showPopup({content: <EditBudgetConfirmation budget={budget} />})
  }

  const handleBudgetDelete = (budget) => {
    showPopup({content: <DeleteBudgetConfirmation budget={budget} />})
  }
  return (
    <div className="budget-card-info">
      <div className="info-title">
        <div className="title-name">{budgetName}</div>
        <div className="budget-icons">
          <span
            style={{cursor: "pointer", height: "18px"}}
            onClick={(e) => handleBudgetEdit(budget)}
          >
            <EditIcon width="18px" color="var(--light-gray)" />
          </span>
          <span
            style={{cursor: "pointer", height: "18px"}}
            onClick={(e) => handleBudgetDelete(budget)}
          >
            <TrashIcon width="18px" color="var(--light-gray)" />
          </span>
        </div>
      </div>
      <div className="info-current-spent">
        {`${currentSpent} € / ${budgetLimit} €`}
      </div>
      <BudgetRangeDates startDate={startDate} endDate={endDate} />
    </div>
  )
}

const BudgetRangeDates = ({startDate, endDate}) => {
  return (
    <div className="budget-range-dates">
      <div className="budget-start-date">
        <div className="budget-start-date-text">{startDate}</div>
      </div>
      <div className="budget-icon-between-dates">
        <ArrowIcon direction={"right"} color={"#334050"} width={"25px"} />
      </div>
      <div className="budget-end-date">
        <div className="budget-end-date-text">{endDate}</div>
      </div>
    </div>
  )
}
