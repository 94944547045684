export default function Container({
  children,
  maxWidth,
  margin = "auto",
  padY = "2rem",
  padX = "1rem",
}) {
  return (
    <div
      style={{
        maxWidth: maxWidth,
        margin: margin,
        padding: `${padY} ${padX}`,
        boxSizing: "border-box",
      }}
    >
      {children}
    </div>
  )
}
