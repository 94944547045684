import ExistingTransactions from "../../../static/resources/images/onboarding/9_existing_transactions.png"

export default function ExistingTransactionsStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Existing transactions</h2>
      </div>
      <div className="step-content">
        <p>
          Below the create transaction form you will be able to see a list of
          your most recent transactions:
        </p>

        <img
          style={{
            maxHeight: "300px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={ExistingTransactions}
          alt="Accounts tab"
        />
        <p>
          {" "}
          If you click on any of the transactions, a popup will appear from
          where you will be able to edit or delete the transaction.
        </p>
      </div>
    </div>
  )
}
