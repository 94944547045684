import {useState} from "react"
import {apiClient} from "../../infraestructure/api/client"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useAuth} from "../../contexts/AuthContext"
import {fileToBase64} from "./index"
import {AxiosResponse} from "axios"
import toast from "react-hot-toast"

interface UploadTransactionsRequest {
  accountID: number
  uid: string
  categories: object[]
  image: string
}

export function useTransactionImageUpload() {
  const {categories, getAllCategories} = useTransactionContext()
  const {currentUser} = useAuth()
  const [uploadedTransactions, setUploadedTransaction] = useState<
    object[] | null
  >(null)
  const [isLoading, setIsLoading] = useState(false)

  if (!categories || categories.length === 0) {
    getAllCategories(currentUser.uid)
  }

  const uploadTransactionImage = async (
    file: File | null,
    accountID: number | null
  ) => {
    if (!file) {
      toast.error("Please select an image to upload")
      return
    }

    const MAX_FILE_SIZE_MB = 2
    const fileSizeInMB = file.size / (1024 * 1024) // Convert bytes to MB
    if (fileSizeInMB > MAX_FILE_SIZE_MB) {
      toast.error(
        `File size exceeds ${MAX_FILE_SIZE_MB}MB limit. Please choose a smaller file.`
      )
      return
    }

    if (!accountID) {
      toast.error("Please select an account to upload to")
      return
    }

    setIsLoading(true)

    const base64String = await fileToBase64(file)

    const body: UploadTransactionsRequest = {
      accountID: accountID,
      uid: currentUser.uid,
      categories: categories,
      image: base64String,
    }

    await toast.promise(
      apiClient.post("/transactions/in_bulk/screenshot", body),
      {
        loading: "Processing your transactions...",
        success: (response: any) => {
          setUploadedTransaction(response.transactions)
          return "Your transactions have been processed"
        },
        error: "Error processing your transactions",
      }
    )
    setIsLoading(false)
  }

  return {uploadTransactionImage, uploadedTransactions, isLoading}
}
