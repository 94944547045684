import CategoriesTab from "../../../static/resources/images/onboarding/4_categories_tab.png"

export default function CategoriesStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Categories</h2>
      </div>
      <div className="step-content">
        <p>
          Categories allow you to group transactions from multiple accounts into
          a common group based on its nature. In FINANZIA there are two types of
          categories:
        </p>
        <ul>
          <li>Income categories</li>
          <li>Expense categories</li>
        </ul>
        <div className="example">
          <p>
            <b>Example:</b> As income categories you could have:
            <ul>
              <li>Salary</li>
              <li>Investments</li>
              <li>Presents</li>
            </ul>
            And as expense categories you could have:
            <ul>
              <li>Clothing</li>
              <li>Eating out</li>
              <li>Transport</li>
            </ul>
          </p>
        </div>
        <p>
          Again, and similar to how we did for accounts, let's navigate to the
          categories tab by clicking on the <i>Add category</i> button.
        </p>
        <img
          style={{
            maxHeight: "250px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={CategoriesTab}
          alt="Accounts tab"
        />
      </div>
    </div>
  )
}
