import axios from "axios"
import {apiClient} from "../infraestructure/api/client"

const {createContext, useContext, useState} = require("react")

const BlogContext = createContext()

export function useBlog() {
  return useContext(BlogContext)
}

export function BlogProvider({children}) {
  const [posts, setPosts] = useState(undefined)
  const getPostsSlugAndMetadata = async () => {
    const query = {
      hasMetadata: true,
    }

    try {
      const response = await apiClient.get("/posts", {params: query})
      setPosts(response.posts)
    } catch (error) {
      console.log("Error fetching posts:", error)
    }
  }

  const getPostContent = async (slug) => {
    try {
      const response = await apiClient.get(`/posts/${slug}`)
      return response
    } catch (error) {
      console.log("Error fetching post content:", error)
      return null
    }
  }
  const value = {posts, getPostsSlugAndMetadata, getPostContent}
  return <BlogContext.Provider value={value}>{children}</BlogContext.Provider>
}
