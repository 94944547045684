import LockIcon from "../../components/icons/LockIcon"
import UserIcon from "../../components/icons/UserIcon"
import CalculatorIcon from "../../components/icons/CalculatorIcon"
import DangerIcon from "../../components/icons/DangerIcon"
import ReportIcon from "../../components/icons/ReportIcon"
import PageHeader from "../../components/pages/PageHeader"
import ResetPassword from "../../components/settings/ResetPassword"
import SettingSectionHeader from "../../components/settings/SettingHeader"
import ReportIssue from "../../components/settings/ReportIssue"
import SettingsSection from "../../components/settings/SettingsSection"
import UpdateProfileInfo from "../../components/settings/UpdateProfileInfo"
import "./Settings.css"
import RecalculateAccountBalances from "../../components/settings/RecalculateAccountBalances"
import DeleteAccount from "../../components/settings/DeleteAccount"

export default function SettingsPage() {
  return (
    <main className="settings-page">
      <div className="settings-header">
        <PageHeader title={"Your settings"} hasMonthSelector={false} />
        <p>Manage your Finanzia settings</p>
      </div>

      <SettingsSection
        header={
          <SettingSectionHeader
            title="Profile data"
            icon={<UserIcon color={"var(--gray)"} width="22px" />}
          />
        }
      >
        <UpdateProfileInfo />
      </SettingsSection>
      <SettingsSection
        header={
          <SettingSectionHeader
            title="Reset password"
            icon={<LockIcon color={"var(--gray)"} width="22px" />}
          />
        }
      >
        <ResetPassword
          styles={{
            padding: "1rem",
          }}
        />
      </SettingsSection>
      <SettingsSection
        header={
          <SettingSectionHeader
            title="Recompute account balances"
            icon={<CalculatorIcon color={"var(--gray)"} width="22px" />}
          />
        }
      >
        <RecalculateAccountBalances />
      </SettingsSection>
      <SettingsSection
        header={
          <SettingSectionHeader
            title="Report issue"
            icon={<ReportIcon color={"var(--gray)"} width="22px" />}
          />
        }
      >
        <ReportIssue />
      </SettingsSection>
      <SettingsSection
        header={
          <SettingSectionHeader
            title="Delete account"
            icon={<DangerIcon color={"var(--gray)"} width="22px" />}
          />
        }
      >
        <DeleteAccount />
      </SettingsSection>
    </main>
  )
}
