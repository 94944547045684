import {useState} from "react"
import "./Inputs.css"
import HideIcon from "../icons/HideIcon"
import ShowIcon from "../icons/ViewIcon"

export default function PasswordInput({
  inputId,
  inputName,
  inputPlaceholder,
  inputLabel,
  onChange,
  inputValue,
  additionalClasses,
  textAlign = "left",
  marginTop = "0px",
  hasToggle,
}) {
  const [isVisible, setIsVisible] = useState(false)
  const inputClasses = `password-input ${additionalClasses}`

  const handlePasswordVisibility = (e) => {
    setIsVisible(!isVisible)
  }

  return (
    <div className={inputClasses}>
      <div className="password-input">
        <input
          style={{textAlign: textAlign, marginTop: marginTop}}
          placeholder={inputPlaceholder}
          id={inputId}
          name={inputName}
          type={isVisible ? "text" : "password"}
          value={inputValue}
          onChange={onChange}
        ></input>
        {hasToggle && (
          <span
            style={{cursor: "pointer", height: "24px", paddingRight: "4px"}}
            onClick={handlePasswordVisibility}
          >
            {isVisible ? (
              <HideIcon color={"#334050"} />
            ) : (
              <ShowIcon color={"#334050"} />
            )}
          </span>
        )}
      </div>
    </div>
  )
}
