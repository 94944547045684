import axios, {AxiosError, AxiosResponse} from "axios"
import {Account} from "../../types"
import {apiClient} from "./client"

export interface IAccountRepository {
  addAccount(account: Account): Promise<any>
}

export class AccountRepository implements IAccountRepository {
  async addAccount(account: Account): Promise<any> {
    try {
      const response = await apiClient.post("/accounts", account)

      return response
    } catch (error) {
      console.log(`Error creating account in accountRepository: ${error}`)
      throw error
    }
  }
}
