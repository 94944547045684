import useRecurrenceContext from "../../../contexts/RecurrentTransactionContext"
import {formatDate} from "../../../utils/transactionUtils"

export function RecurrenceTypeSelector({onClick, selectedType}) {
  return (
    <div className="recurrence-type-selector">
      <span
        className={`option ${selectedType === "EXPENSE" ? "active" : ""}`}
        onClick={() => onClick("EXPENSE")}
      >
        Expenses
      </span>
      <span
        className={`option ${selectedType === "INCOME" ? "active" : ""}`}
        onClick={() => onClick("INCOME")}
      >
        Income
      </span>
    </div>
  )
}

export function RecurrentTransaction({transaction}) {
  return (
    <div className="recurrent-transaction">
      <div className="recurrence-left">
        <h3 className="name">{transaction.name}</h3>
        <p>{formatDate(transaction.executionDate)}</p>
      </div>
      <div className="recurrence-right ">
        <p
          className={`amount ${
            transaction.type === "EXPENSE" ? "expense" : "income"
          }`}
        >
          {transaction.type === "EXPENSE" ? "-" : ""}
          {transaction.recurrentAmount}€
        </p>
      </div>
    </div>
  )
}

export const CategorySummaryItem = ({categorySummary}) => {
  return (
    <div className="recurrent-transaction">
      <div className="recurrence-left">
        <h3 className="name">{categorySummary.category_name}</h3>
        <p>Transactions: {categorySummary.total_transactions}</p>
      </div>
      <div className="recurrente-right">
        <p
          className={`amount ${
            categorySummary.type === "EXPENSE" ? "expense" : "income"
          }`}
        >
          {categorySummary.type === "EXPENSE" ? "-" : ""}
          {categorySummary.total_amount}€
        </p>
      </div>
    </div>
  )
}

export function RecurrenceTotal({type}) {
  const {recurrentTransactions} = useRecurrenceContext()
  const totalAmout = recurrentTransactions
    ? recurrentTransactions.reduce((acc, transaction) => {
        if (transaction.type !== type) return acc
        return acc + parseFloat(transaction.recurrentAmount)
      }, 0)
    : 0
  return (
    <div className="recurrence-total">
      <h3>Total </h3>
      <p
        className={`total-amount ${type === "EXPENSE" ? "expense" : "income"}`}
      >
        {type === "EXPENSE" ? "-" : ""}
        {totalAmout.toFixed(2)}€
      </p>
    </div>
  )
}

export const formatRecurrenceFrequency = (frequency) => {
  switch (frequency) {
    case "WEEKLY":
      return "Weekly"

    case "MONTHLY":
      return "Monthly"

    case "YEARLY":
      return "Yearly"
  }
}

export function getRecurrenceSettings(transaction) {
  let className
  let typeName
  switch (transaction.type) {
    case "INCOME":
      className = "income"
      typeName = "Income"
      break
    case "EXPENSE":
      className = "expense"
      typeName = "Expense"
      break
    default:
      className = "transfer"
      typeName = "Transfer"
      break
  }

  const info = {
    class: className,
    displayName: typeName,
  }

  return info
}
