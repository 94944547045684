import BadIcon from "../icons/BadIcon"
import GoodIcon from "../icons/GoodIcon"
import IdeaIcon from "../icons/IdeaIcon"
import InfoIcon from "../icons/InfoIcon"
import {InsightAccent} from "./Insight"

export function replacePlaceholdersWithData(insight) {
  const text = insight.text
  const data = insight.data
  // Split the text by each occurrence of #DATA#
  const parts = text.split("#DATA#")
  return parts.map((part, i) => (
    <span key={i}>
      {part}
      {/* Insert the corresponding data value, wrapped in InsightAccent */}
      {i < data.length && (
        <InsightAccent color={getInsightColor(insight.type)}>
          {data[i]}
        </InsightAccent>
      )}
    </span>
  ))
}

export function getInsightColor(type) {
  switch (type) {
    case "positive":
      return "#02DCA2"
    case "negative":
      return "#334050"
    default:
      return "var(--light-gray)"
  }
}

export function getInsightIcon(type) {
  switch (type) {
    case "positive":
      return <GoodIcon color="#02DCA2" width="21px" />
    case "negative":
      return <BadIcon color="#334050" width="21px" />
    case "tip":
      return <IdeaIcon color="#02DCA2" width="24px" />
    default:
      return <InfoIcon color="var(--light-gray)" width="22px" />
  }
}
