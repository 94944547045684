import ExistingAccounts from "../../../static/resources/images/onboarding/3_existing_accounts.png"

export default function ExistingAccountsStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Existings accounts</h2>
      </div>
      <div className="step-content">
        <p>
          Below the create account form you will see a summary of your existing
          accounts. For each account, we show the account name, the account
          description and the current balance for that account.
        </p>
        <img
          style={{
            maxHeight: "350px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={ExistingAccounts}
          alt="Accounts tab"
        />

        <div className="note">
          <p>
            <b>Note:</b> By clicking on any of the accounts you will be redirect
            to that account's page, where you can see a detailed analysis and
            view of all the transactions as well as edit the account details or
            delete it.
          </p>
        </div>
      </div>
    </div>
  )
}
