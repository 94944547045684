import React, {useContext, createContext, useState, useEffect} from "react"
import axios, {all} from "axios"
import {useAuth} from "./AuthContext"
import {CategoryRepository} from "../infraestructure/api/categoryRepository"
import {CategoryService} from "../domain/categories/categoryService"
import {apiClient} from "../infraestructure/api/client"

const TransactionsContext = createContext()

export function useTransactionContext() {
  return useContext(TransactionsContext)
}

export function TransactionProvider({children}) {
  const {currentUser} = useAuth()
  const [transactions, setTransactions] = useState([])
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState()

  const createTransaction = async (transaction) => {
    try {
      const response = await apiClient.post("/transactions", transaction)
      return true
    } catch (error) {
      console.log("Error creating transation", error)
      return false
    }
  }

  const deleteTransaction = async (id) => {
    const params = {
      id: id,
    }
    try {
      const response = await apiClient.delete("/transactions", {params: params})
      return response
    } catch (error) {
      console.log("Error deleting transaction", error)
      return false
    }
  }

  const editTransaction = async (transaction) => {
    try {
      const response = await apiClient.put("/transactions", transaction)

      if (response) {
        return response
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const createCategory = async (category) => {
    try {
      const response = await apiClient.post("/categories", category)
      return response
    } catch (error) {
      console.log("Error creating category", error)
      return false
    }
  }

  const getAllTransactions = async (userID, orderBy = "date") => {
    const params = {
      userID: userID,
    }

    try {
      const response = await apiClient.get("/transactions", {params: params})
      setTransactions(response || [])
    } catch (error) {
      console.log("Error retrieving transactions", error)
      return null
    }
  }

  const getTransactions = async (query, orderBy = "date") => {
    try {
      const response = await apiClient.get("/transactions", {params: query})

      setTransactions(response || [])
    } catch (err) {
      console.log("Error retrieving transactions", err)
      return null
    }
  }

  const getAllCategories = async (userID) => {
    const params = {
      userID: userID,
    }

    try {
      const response = await apiClient.get("/categories", {params: params})
      const allCategories = response
      setCategories(allCategories)
    } catch (err) {
      console.log(err.message)
    }
  }

  const getCategories = async (query) => {
    try {
      const response = await apiClient.get("/categories", {params: query})
      const category = response
      setCurrentCategory(category)
      return category
    } catch (err) {
      console.log("Error retrieving category", err)
      return null
    }
  }

  const deleteCategory = async (id) => {
    const query = {
      id: id,
      userID: currentUser.uid,
    }

    try {
      const response = await apiClient.delete("/categories", {params: query})
      return response
    } catch (err) {
      console.log(err.message)
      return false
    }
  }

  const editCategory = async (category) => {
    try {
      const response = await apiClient.put("/categories", category)

      if (response) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const categoryRepo = new CategoryRepository()
  const categoryService = new CategoryService(categoryRepo)

  const value = {
    createTransaction,
    deleteTransaction,
    editTransaction,
    getAllTransactions,
    getAllCategories,
    createCategory,
    getCategories,
    deleteCategory,
    editCategory,
    getTransactions,
    transactions,
    categories,
    currentCategory,
    categoryService,
  }

  return (
    <TransactionsContext.Provider value={value}>
      {children}
    </TransactionsContext.Provider>
  )
}
