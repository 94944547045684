import CategoryBreakdown from "./../../../static/resources/images/onboarding/14_category_breakdown.png"

export default function CategoryBeakdownStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Categories breakdown</h2>
      </div>
      <div className="step-content">
        <p>
          Besides the monthly overview you can also see the breakdown of your
          income and expenses by category.
        </p>

        <img
          style={{
            maxHeight: "400px",
            width: "auto",
          }}
          src={CategoryBreakdown}
          alt="Category breakdown"
        />
      </div>
    </div>
  )
}
