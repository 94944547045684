import {useEffect} from "react"
import {useNavigate} from "react-router"
import {useAuth} from "../contexts/AuthContext"
import "./Home.css"
import DashboardMockup from "./../static/resources/images/home/dashboard_mockup.png"
import PhoneMockup from "./../static/resources/images/home/dashboard_phone_mockup.png"
import PrimaryButton from "../components/buttons/PrimaryButton"
import SecondaryButton from "../components/buttons/SecondaryButton"

import styled from "styled-components"
import OnScrollText from "../components/text/OnScrollText"
import Container from "../components/layout/Container"
import SectionHeader from "../components/text/SectionHeader"
import TextPill from "../components/text/TextPill"
import SectionTitle from "../components/text/SectionTitle"
import IconButton from "../components/buttons/IconButton"
import DiscordIcon from "../components/icons/DiscordIcon"
import {useResponsive} from "../contexts/ResponsiveContext"
import {getAndStoreReferralCode} from "../domain/referrals"
import ImpactSection from "../components/home/ImpactSection"
import HomeFeatures from "../components/home/Features"

export default function Home() {
  const {currentUser} = useAuth()
  const {isPhone} = useResponsive()

  const navigate = useNavigate()

  //Redirect to dashboard if logged in
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      navigate("app/dashboard")
    }
  }, [currentUser, navigate])

  useEffect(() => {
    getAndStoreReferralCode()
  }, [])

  useEffect(() => {
    document.title = "Home - Finanzia"
  }, [])

  return (
    <main className="home">
      <HomeHero />
      <section>
        <Container maxWidth={"800px"}>
          <OnScrollText
            text={
              "32% or 150€. Is the average extra monthly expenses of a teenager when not using a finance tracking app."
            }
            initialColor={"var(--gray)"}
            accentColor={"var(--primary)"}
            effectStartIndex={3}
            threshold={40}
            intialThreshold={75}
            textClass={"home-big-text"}
            verticalPadding={isPhone ? "50px" : "100px"}
          />
        </Container>
      </section>
      <IntroducingFinanzia />
      <HomeFeatures />
    </main>
  )
}

function HomeHero() {
  const navigate = useNavigate()
  const {isPhone} = useResponsive()
  return (
    <section className="home-section hero">
      <div className="hero-left">
        {!isPhone && <h1>FINANZIA</h1>}
        <h2>Money is hard.</h2>
        <HeroParagraph>
          And will forever be if you don't regain control over your personal
          finances and have a long term plan.
        </HeroParagraph>

        <ButtonRow>
          <PrimaryButton
            buttonText={"Create account"}
            onClick={() => navigate("/cuenta/register")}
          />

          <SecondaryButton
            buttonText={"Login"}
            textColor="var(--primary-green)"
            onClick={() => navigate("/cuenta/iniciar-sesion")}
          />

          <IconButton
            icon={
              <DiscordIcon
                color={"var(--light-gray)"}
                width={isPhone ? "22px" : "24px"}
              />
            }
            onClick={() => {
              window.open("https://discord.gg/gmehT7CmV5", "_blank")
            }}
            text={isPhone ? "Join our Discord" : null}
          />
        </ButtonRow>
      </div>
      <div className="hero-right">
        <img
          style={{width: "100%", border: "none"}}
          src={PhoneMockup}
          border={"1px solid var(--primary)"}
          alt="Dashboard mockup hero"
        />
      </div>
    </section>
  )
}

function IntroducingFinanzia() {
  return (
    <section>
      <Container maxWidth={"600px"} padY="0" padX="0">
        <SectionHeader>
          <TextPill border="1px solid var(--gray)" fontWeight="600">
            Introducing Finanzia
          </TextPill>
          <SectionTitle color={"linear-gradient(to left, #6d7682, #334050)"}>
            Now available on PC, Android and iOS
          </SectionTitle>

          <p style={{maxWidth: "450px"}}>
            {" "}
            Add Finanzia to you home screen to use it as an app on your phone!
          </p>
        </SectionHeader>
      </Container>
      <Container maxWidth={"1200px"} padY="0">
        <img
          style={{maxWidth: "100%", width: "100%"}}
          src={DashboardMockup}
          alt="Dashboard mockup"
        />
      </Container>
    </section>
  )
}

const ButtonRow = styled.div`
  display: flex;
  gap: 20px;
  width: 70%;
  padding-top: 1rem;

  align-items: center;
  @media screen and (max-width: 600px) {
    width: 80%;
    margin: auto;
    margin-top: 0.8rem;
    gap: 10px;
    align-items: flex-start;

    flex-direction: column;

    button.primary-button,
    button.icon-button {
      margin: 0 !important;
      width: 100% !important;
    }
  }
`

const HeroParagraph = styled.p`
  padding-top: 1rem;
`
