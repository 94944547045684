import {useResponsive} from "../../contexts/ResponsiveContext"
import MonthSelector from "../dashboard/dates/DateSelectors"
import "./Pages.css"

export default function PageHeader({
  title,
  textAlign = "left",
  actions,
  hasMonthSelector = true,
  rightSideActions,
}) {
  const {isTablet} = useResponsive()
  return (
    <section className="page-header">
      <div
        style={{
          display: "flex",
          gap: `${isTablet ? "10px" : "20px"}`,
          alignItems: "baseline",
        }}
      >
        <h1 style={{textAlign: textAlign}} className="page-title">
          {title}
        </h1>
        {actions}
      </div>

      {hasMonthSelector && (
        <div>
          <MonthSelector />
        </div>
      )}
      {!hasMonthSelector && <div>{rightSideActions}</div>}
    </section>
  )
}
