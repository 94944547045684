import {useState, useEffect, useContext} from "react"
import {Navigate, Outlet} from "react-router-dom"
import {AuthContext, useAuth} from "../contexts/AuthContext" // Assuming AuthContext is the context you have created
import Loading from "../pages/Loading"

function ProtectedWrapper() {
  const {currentUser, isLoading} = useAuth()

  if (isLoading) {
    return <Loading /> // Or some loading spinner component
  }

  if (!currentUser) {
    return <Navigate to={"/cuenta/iniciar-sesion"} replace />
  }

  return <Outlet />
}

function NotLoggedIn() {
  const {currentUser, isLoading} = useAuth()

  if (isLoading) {
    return <Loading /> // Or some loading spinner component
  }

  if (currentUser) {
    return <Navigate to={"/app/dashboard"} replace />
  }

  return <Outlet />
}

function IsAdmin() {
  const {currentUser, isLoading} = useAuth()

  if (isLoading) return <Loading />

  if (!currentUser) {
    return <Navigate to={"/"} replace />
  }

  if (currentUser.userType !== "admin") {
    return <Navigate to={"/app/dashboard"} replace />
  }

  return <Outlet />
}

export {ProtectedWrapper, NotLoggedIn, IsAdmin}
