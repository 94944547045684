import axios, {AxiosError, AxiosResponse} from "axios"
import {Category} from "../../types"
import {apiClient} from "./client"

export interface ICategoryRepository {
  addCategory(category: Category): Promise<any>
}

export class CategoryRepository implements ICategoryRepository {
  async addCategory(category: Category) {
    try {
      const response = await apiClient.post("/categories", category)

      return response
    } catch (error) {
      let errMessage = error
      if (error instanceof AxiosError) {
        errMessage = error.message
      }
      console.log(`Error creating in categoryRepository: ${errMessage}`)
      throw error
    }
  }
}
