export default function DescriptionIcon({color, height, width = "26px"}) {
  return (
    <svg
      width={width}
      height={height ? height : width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M3 10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C21 4.34315 21 6.22876 21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V10Z"
          stroke={color}
          stroke-width="1.5"
        />{" "}
        <path
          d="M8 12H16"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />{" "}
        <path
          d="M8 8H16"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />{" "}
        <path
          d="M8 16H13"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />{" "}
      </g>
    </svg>
  )
}
