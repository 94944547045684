import {useEffect} from "react"
import {useParams} from "react-router"
import {useBlog} from "../../contexts/BlogContext"
import PostGrid from "./PostGrid"
import PageTitle, {PageSubtitle} from "../pages/PageTitle"

export default function TagPage() {
  const {tag} = useParams()
  const {posts, getPostsSlugAndMetadata} = useBlog()
  useEffect(() => {
    if (!posts) getPostsSlugAndMetadata()
  }, [posts])

  const capitalizedTag = tag.charAt(0).toUpperCase() + tag.slice(1)
  useEffect(() => {
    document.title = `${capitalizedTag} - Finanzia`
  }, [capitalizedTag])

  return (
    <main className="blog tag-page">
      <section className="page-header col-layout">
        <PageSubtitle subtitle={`Posts tagged with:`} />
        <PageTitle title={capitalizedTag} />
      </section>

      <PostGrid tagToFilter={tag} />
    </main>
  )
}
