import "./Insights.css"

export default function Insight({
  text,
  icon,
  borderWidth = "1px",
  borderRadius = "0.8rem",
  borderColor = "#C2C6CB",
}) {
  return (
    <div className="insight-container">
      {icon && <span className="insight-icon">{icon}</span>}
      <div
        className="insight"
        style={{
          border: `${borderWidth} solid ${borderColor}`,
          borderRadius: borderRadius,
          padding: "1.4rem",
          paddingBottom: "1.2rem",
          marginLeft: icon ? "1rem" : "0rem",
        }}
      >
        <p className="insight-text">{text}</p>
      </div>
    </div>
  )
}

export function InsightAccent({children, color = "var(--gray)"}) {
  return <span style={{color: color, fontWeight: 700}}>{children}</span>
}
