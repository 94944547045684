export default function SelectInput({
  inputID,
  inputName,
  onChange,
  defaultValue,
  options,
}) {
  return (
    <div className="input-container">
      <select
        id={inputID}
        name={inputName}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <option value={option.value}>{option.name}</option>
        ))}
      </select>
    </div>
  )
}
