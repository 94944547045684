import {
  ls_deleteReferralCode,
  ls_getReferralCode,
  ls_storeReferralCode,
} from "../../infraestructure/local-storage"

const REFERRAL_CODE = "fza_origin"

export function extractReferralCode(): string | null {
  const urlSearchParam = new URLSearchParams(window.location.search)

  if (!urlSearchParam.has(REFERRAL_CODE)) {
    return null
  }

  const referralCode = urlSearchParam.get(REFERRAL_CODE)
  return referralCode
}

export function storeReferralCode(referralCode: string): boolean {
  return ls_storeReferralCode(referralCode)
}

export function getAndStoreReferralCode(): void {
  const referralCode = extractReferralCode()
  if (referralCode) {
    storeReferralCode(referralCode)
  }
}

export function retrieveReferralCode(): string | null {
  const referral = ls_getReferralCode(REFERRAL_CODE)

  if (!referral) {
    return null
  }

  return referral
}

export function deleteReferralCode(): void {
  ls_deleteReferralCode(REFERRAL_CODE)
}
