import {createContext, useContext, useEffect, useState} from "react"
import {useLocation} from "react-router"

const NavigationContext = createContext()

export function useNavigationContext() {
  return useContext(NavigationContext)
}

export function NavigationProvider({children}) {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)
  const [isApp, setIsApp] = useState(false)
  const [pathname, setPathname] = useState(null)

  const location = useLocation()

  useEffect(() => {
    if (
      location.pathname.startsWith("/app") ||
      location.pathname.startsWith("/admin")
    ) {
      setIsApp(true)
    } else {
      setIsApp(false)
    }

    setPathname(location.pathname)
  }, [location])

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen)
  }

  const value = {toggleHamburgerMenu, isHamburgerMenuOpen, isApp, pathname}
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}
