export default function LockIcon({color, height, width = "26px"}) {
  return (
    <svg
      width={width}
      height={height ? height : width}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.1125 6.36477V4.87952C2.1125 2.18464 4.07685 0 6.5 0C8.92313 0 10.8875 2.18464 10.8875 4.87952V6.36477C11.6121 6.42499 12.084 6.57687 12.4288 6.96051C13 7.59571 13 8.61802 13 10.6627C13 12.7073 13 13.7296 12.4288 14.3648C11.8577 15 10.9385 15 9.1 15H3.9C2.06152 15 1.14228 15 0.571142 14.3648C0 13.7296 0 12.7073 0 10.6627C0 8.61802 0 7.59571 0.571142 6.96051C0.916051 6.57687 1.38791 6.42499 2.1125 6.36477ZM3.0875 4.87952C3.0875 2.7835 4.61533 1.08434 6.5 1.08434C8.38468 1.08434 9.9125 2.7835 9.9125 4.87952V6.3279C9.66355 6.3253 9.39347 6.3253 9.1 6.3253H3.9C3.60652 6.3253 3.33646 6.3253 3.0875 6.3279V4.87952ZM3.9 11.3855C4.25898 11.3855 4.55 11.0619 4.55 10.6627C4.55 10.2634 4.25898 9.93976 3.9 9.93976C3.54102 9.93976 3.25 10.2634 3.25 10.6627C3.25 11.0619 3.54102 11.3855 3.9 11.3855ZM6.5 11.3855C6.85899 11.3855 7.15 11.0619 7.15 10.6627C7.15 10.2634 6.85899 9.93976 6.5 9.93976C6.141 9.93976 5.85 10.2634 5.85 10.6627C5.85 11.0619 6.141 11.3855 6.5 11.3855ZM9.75 10.6627C9.75 11.0619 9.45899 11.3855 9.1 11.3855C8.741 11.3855 8.45 11.0619 8.45 10.6627C8.45 10.2634 8.741 9.93976 9.1 9.93976C9.45899 9.93976 9.75 10.2634 9.75 10.6627Z"
        fill={color}
      />
    </svg>
  )
}
