export default function IconButton({
  icon,
  onClick,
  isActive = false,
  border,
  text = null,
}) {
  return (
    <button
      onClick={onClick}
      className={isActive ? "icon-button active" : "icon-button"}
    >
      {icon}
      {text && <p>{text}</p>}
    </button>
  )
}
