export default function SectionHeader({children, textAlign = "center"}) {
  return (
    <div
      style={{
        textAlign: textAlign,
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        alignItems: "center",

        padding: "2rem 1rem",
        paddingBottom: "1.4rem",
      }}
    >
      {children}
    </div>
  )
}
