import React from 'react'
import '../budgets/Budgets.css'
import { Doughnut } from 'react-chartjs-2';

export default function BudgetProgressChart ({
    progressColor,
    spentBudget,
    realPercentage
}) {
    const availableBudget = 100 - spentBudget
    const data = {
        labels: ['Progress', 'Background'],
        datasets: [
            {
                data: [spentBudget, availableBudget],
                backgroundColor: [`${progressColor}`, '#D5D5D5'],
                hoverBorderColor: [`${progressColor}`, '#D5D5D5'],
                hoverBackgroundColor: [`${progressColor}`, '#D5D5D5'],
                borderRadius: 0,
                borderWidth: 0,
                borderColor: '#f9f9f9'
            },
        ],
    };

    const optionObject = {
        cutout: "85%",
        plugins: {
            legend: {
            display: false,
            },
            dataLabels: {
            display: false ,
            },

            tooltip: {
            enabled: false,
            },
        },
    }

    const centerDivStyle = {
        position: "relative",
        transform: "translate(0, -200%)",
        textAlign: "center",
        marginTop: "auto",
        marginBottom: "auto",
    }

    return (
        <div className="progress-budget-chart">
            <Doughnut data={data} options={optionObject}/>
            <div style={centerDivStyle}>
                <div className="progress-budget-number">
                    {`${realPercentage}%`}
                </div>
            </div>
        </div>
    )
}