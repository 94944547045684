import {useEffect, useState} from "react"
import {useBlog} from "../../contexts/BlogContext"
import PostCard from "./PostCard"

export default function PostGrid({tagToFilter = null, hasFeaturedPost = true}) {
  const {posts} = useBlog()

  if (!posts) return null
  return (
    <section className="post-grid" style={{paddingTop: "1.8rem"}}>
      {posts.map((post, index) => {
        if (tagToFilter && !post.metadata.tags.includes(tagToFilter))
          return null
        return (
          <PostCard
            slug={post.slug}
            metadata={post.metadata}
            isFeatured={
              hasFeaturedPost && (index === 0 || post.metadata.isFeatured)
            }
          />
        )
      })}
    </section>
  )
}
