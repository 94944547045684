import DashboardMockup from "./../../../static/resources/images/home/dashboard_mockup.png"

export default function WelcomeStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Learn about Finanzia</h2>
      </div>
      <div className="step-content">
        <p>
          Welcome to Finanzia's knowledge base. Here you will find information
          about all the main features and a detailed explanation on how to use
          them.
        </p>
        <p>
          However, if after going over it you still have any doubts, feel free
          to get in touch with us. We will happily answer you.
        </p>
        <img src={DashboardMockup} alt="Dashboard mockup" />
      </div>
    </div>
  )
}
