import {Doughnut} from "react-chartjs-2"
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js"
import {useStats} from "../../contexts/StatsContext"
import {useEffect, useRef, useState} from "react"
import {useTransactionContext} from "../../contexts/TransactionContext"

ChartJS.register(ArcElement, Tooltip, Legend)

export default function CategoryBreakdown() {
  const [categoryType, setCategoryType] = useState("EXPENSE")
  const expensesClass = `category-type ${
    categoryType === "EXPENSE" ? "active" : ""
  }`
  const incomeClass = `category-type ${
    categoryType === "INCOME" ? "active" : ""
  }`
  return (
    <div>
      <div className="breakdown-header">
        <h2 className="title">Breakdown</h2>
        <div className="category-options">
          <span
            onClick={() => {
              setCategoryType("EXPENSE")
            }}
            className={expensesClass}
          >
            Expenses
          </span>
          <span
            onClick={() => {
              setCategoryType("INCOME")
            }}
            className={incomeClass}
          >
            Income
          </span>
        </div>
      </div>
      {categoryType && <BreakdownChart type={categoryType} />}
    </div>
  )
}

function BreakdownChart({type}) {
  const {getCategoryBreakdown, selectedRange} = useStats()
  const {transactions} = useTransactionContext()
  const [categoryBreakdown, setCategoryBreakdown] = useState()
  const [categoryTotal, setCategoryTotal] = useState()
  const [categoryNames, setCategoryNames] = useState()
  const [total, setTotal] = useState([0])
  const [breakdownType, setBreakdownType] = useState("")
  const [colors, setColors] = useState()

  var pluginTotal = {value: null}
  const chartRef = useRef(null)

  useEffect(() => {
    pluginTotal.value = total

    // If the chart instance is stored in the ref, update the chart
    if (chartRef.current) {
      chartRef.current.update()
    }
  }, [total])

  useEffect(() => {
    if (!transactions || transactions.length === 0) return
    const fetcCategoryBreakdown = async () => {
      if (selectedRange[0]) {
        const response = await getCategoryBreakdown(type)

        setCategoryBreakdown(response.breakdown)
        setTotal([response.totalBalance])
      }
    }

    fetcCategoryBreakdown()
  }, [type, selectedRange, transactions])

  useEffect(() => {
    var totals = []
    var names = []
    var colors = []
    const baseColor =
      type === "EXPENSE" ? "rgba(51, 64, 80, X)" : "rgba(2, 220, 162, X)"

    if (categoryBreakdown && categoryBreakdown.length === 0) {
      totals = [1] // Only element in the array to form a complete circle
      names = ["No transactions"]
      colors = [baseColor.replace("X", "1")] // Ensure this color is visible
    } else if (categoryBreakdown) {
      var i = 0
      var opacityVariation = 0.7 / (categoryBreakdown.length - 0.9999) || 0
      for (const category of categoryBreakdown) {
        totals.push(category.categoryBalance)
        names.push(category.categoryName)
        colors.push(baseColor.replace("X", `${1 - opacityVariation * i}`))
        i++
      }
    }
    setCategoryNames(names)
    setCategoryTotal(totals)

    setColors(colors)
    setBreakdownType(type === "EXPENSE" ? "Expenses" : "Income")
  }, [categoryBreakdown, total])

  const optionObject = {
    cutout: "85%",
    plugins: {
      legend: {
        display: false,
      },
      dataLabels: {
        display: true,
      },

      tooltip: {
        enabled: true,
      },
    },
  }
  var dataObject
  if (categoryNames && categoryTotal && total && breakdownType && colors) {
    dataObject = {
      labels: categoryNames,
      datasets: [
        {
          label: "Total",
          data: categoryTotal,
          borderRadius: 90,
          spacing: 4,
          backgroundColor: colors,
          // Using the passed `data` array
        },
      ],
    }
  }

  const centerDivStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  }

  return (
    <div className="breakdown-chart-container">
      {breakdownType && total && categoryTotal && categoryNames && (
        <>
          <Doughnut data={dataObject} options={optionObject} />
          <div style={centerDivStyle}>
            <>
              <div style={{fontSize: "18px", color: "rgb(51, 64, 80)"}}>
                {breakdownType}
              </div>
              <div
                style={{fontSize: "30px", color: "#334050", fontWeight: "bold"}}
              >
                {`${total}€`}
              </div>
            </>
          </div>
        </>
      )}
    </div>
  )
}
