const REFERRAL_CODE = "fza_origin"

export function ls_storeReferralCode(referralCode: string): boolean {
  if (!referralCode) {
    return false
  }

  if (ls_getReferralCode()) {
    return false
  }

  localStorage.setItem(REFERRAL_CODE, referralCode)

  return true
}

export function ls_getReferralCode(
  referralCode: string = REFERRAL_CODE
): string | null {
  return localStorage.getItem(referralCode)
}

export function ls_deleteReferralCode(
  referralCode: string = REFERRAL_CODE
): void {
  localStorage.removeItem(referralCode)
}
