import {createContext, useContext, useState} from "react"
import {KnowledgePopup} from "../components/onboarding/KnowledgeBasePopup"

const KnowledgeContext = createContext()

export function useKnowledge() {
  return useContext(KnowledgeContext)
}

export function KnowledgeProvider({children}) {
  const [knowledgeStep, setKnowledgeStep] = useState(0)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const NUMBER_OF_STEPS = 17

  const showKnowledgePopup = () => {
    setIsPopupOpen(true)
  }

  const hidePopup = () => {
    setIsPopupOpen(false)
  }

  const increaseStep = () => {
    if (knowledgeStep === NUMBER_OF_STEPS) {
      hidePopup()
      setKnowledgeStep(0)
      return
    }
    setKnowledgeStep(knowledgeStep + 1)
  }
  const decreaseStep = () => {
    if (knowledgeStep === 0) return
    setKnowledgeStep(knowledgeStep - 1)
  }

  const value = {
    hidePopup,
    knowledgeStep,
    showKnowledgePopup,
    setKnowledgeStep,
    increaseStep,
    decreaseStep,
    NUMBER_OF_STEPS,
  }
  return (
    <KnowledgeContext.Provider value={value}>
      {children}
      {isPopupOpen && <KnowledgePopup />}
    </KnowledgeContext.Provider>
  )
}
