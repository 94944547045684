import {useEffect, useState} from "react"
import "./RecurrentTransactions.css"
import {
  CategorySummaryItem,
  RecurrenceTotal,
  RecurrenceTypeSelector,
  RecurrentTransaction,
} from "./RecurrenceTransactionsUtils"
import {DashboardSectionHeader} from "../DashboardBasics"
import useRecurrenceContext from "../../../contexts/RecurrentTransactionContext"
import DashboardIcon from "../../icons/DashboardIcon"
import {useNavigate} from "react-router-dom"

export default function RecurrenceSummary() {
  const [selectedType, setSelectedType] = useState("EXPENSE")
  const navigate = useNavigate()

  const handleTypeChange = (newType) => {
    setSelectedType(newType)
  }

  return (
    <section className="recurrence-summary">
      <DashboardSectionHeader>
        <div style={{display: "flex", gap: "10px", alignItems: "baseline"}}>
          <h2 className="title">Recurrences</h2>
          <span
            style={{cursor: "pointer"}}
            onClick={() => {
              navigate("/app/recurrent-transactions")
            }}
          >
            <DashboardIcon color={"#334050"} width="20px" />
          </span>
        </div>
        <RecurrenceTypeSelector
          onClick={handleTypeChange}
          selectedType={selectedType}
        />
      </DashboardSectionHeader>
      <RecurrenceList type={selectedType} />
      <RecurrenceTotal type={selectedType} />
    </section>
  )
}

const RecurrenceList = ({type}) => {
  const {recurrencCategorySummary, getCategorySummary} = useRecurrenceContext()
  useEffect(() => {
    if (!recurrencCategorySummary) {
      getCategorySummary()
    }
  }, [recurrencCategorySummary])
  return (
    <div className="recurrent-transaction-list">
      {recurrencCategorySummary &&
        recurrencCategorySummary
          .filter((summaryItem) => summaryItem.type === type)
          .map((summaryItem) => (
            <CategorySummaryItem categorySummary={summaryItem} />
          ))}
    </div>
  )
}
