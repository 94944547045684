export default function BadIcon({color, height, width = "26px"}) {
  return (
    <svg
      width={width}
      height={height ? height : width}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4943 22.5318C11.3306 22.5843 11.1516 22.5716 10.9997 22.4983C10.8333 22.4181 10.7233 22.2782 10.6837 22.1258L10.147 20.0569C9.95936 19.3333 9.686 18.6355 9.33412 17.9766C8.78773 16.9537 7.9609 16.1669 7.1939 15.506L5.57072 14.1073C5.31915 13.8905 5.18676 13.5656 5.21544 13.2339L6.13171 2.63708C6.178 2.10175 6.62525 1.69221 7.15992 1.69221H12.4045C15.9429 1.69221 18.9241 4.15427 19.4959 7.46056L20.2918 12.0633C20.4014 12.6967 19.9142 13.274 19.275 13.274H13.4297C12.3273 13.274 11.49 14.263 11.6679 15.3493L12.4156 19.9124C12.518 20.5374 12.4888 21.177 12.3297 21.79C12.249 22.1011 12.0035 22.3682 11.6579 22.4792L11.4943 22.5318ZM10.265 24.0229C10.8095 24.2852 11.4368 24.3277 12.0119 24.143L12.1755 24.0904C13.0521 23.8088 13.736 23.1085 13.9678 22.215C14.186 21.3738 14.2261 20.4963 14.0856 19.6388L13.338 15.0757C13.3284 15.0173 13.3734 14.9663 13.4297 14.9663H19.275C20.9665 14.9663 22.2474 13.4407 21.9594 11.7749L21.1635 7.17208C20.4438 3.01107 16.7212 -7.67769e-05 12.4045 -7.67769e-05H7.15992C5.74556 -7.67769e-05 4.56752 1.08276 4.44572 2.49131L3.52944 13.0881C3.45396 13.961 3.80226 14.8173 4.46601 15.3892L6.08919 16.788C6.8284 17.425 7.45064 18.0424 7.84142 18.7739C8.13089 19.3158 8.3551 19.8888 8.50893 20.4818L9.04564 22.5508C9.21396 23.1998 9.66479 23.7336 10.265 24.0229ZM0.809671 14.9655C1.26237 14.9851 1.65011 14.6445 1.68914 14.1931L2.7853 1.5161C2.85571 0.701774 2.21428 -7.67769e-05 1.39525 -7.67769e-05C0.623767 -7.67769e-05 0 0.625734 0 1.39584V14.1202C0 14.5733 0.356961 14.946 0.809671 14.9655Z"
        fill={color}
      />
    </svg>
  )
}
