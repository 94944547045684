import React, {useContext, createContext, useState, useEffect} from "react"
import axios, {all} from "axios"
import {useAuth} from "./AuthContext"
import {apiClient} from "../infraestructure/api/client"

const BudgetsContext = createContext()

export function useBudgetContext() {
  return useContext(BudgetsContext)
}

export function BudgetProvider({children}) {
  const {currentUser} = useAuth()
  const [budgets, setBudgets] = useState([])
  const [budgetsHistory, setBudgetsHistory] = useState([])

  const createBudget = async (budget) => {
    try {
      const response = await apiClient.post("/budgets", budget)
      return true
    } catch (error) {
      console.log("Error creating budget", error)
      return false
    }
  }

  const deleteBudget = async (id) => {
    const params = {
      id: id,
    }
    try {
      const response = await apiClient.delete("/budgets", {params: params})
      if (response) {
        getAllBudgets(currentUser.uid)
      }
      return response.data
    } catch (error) {
      console.log("Error deleting budget", error)
      return false
    }
  }

  const editBudget = async (budget) => {
    const params = {
      budget: budget,
    }
    try {
      const response = await apiClient.put("/budgets", {budget})

      if (response) {
        getAllBudgets(currentUser.uid)
        return response
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const getBudgetByID = async (id) => {
    const params = {
      id: id,
    }

    try {
      const response = await apiClient.get("/budgets", {params})
      return response
    } catch (err) {
      console.log("Error getting budget by ID", err)
    }
  }

  const getAllBudgets = async (userID, showOnlyActive = true) => {
    const params = {
      userID: userID,
      showOnlyActive: showOnlyActive,
    }
    try {
      const response = await apiClient.get("/budgets", {params: params})
      setBudgets(response)
    } catch (error) {
      console.log("Error getting budgets", error)
    }
  }

  const getAllBudgetHistories = async (budgets) => {
    const params = {
      budgets: budgets,
    }
    try {
      if (budgets.length === 0) {
        setBudgetsHistory([])
      } else {
        const response = await apiClient.get("/budgets/history", {params})
        setBudgetsHistory(response)
      }
    } catch (error) {
      console.log("Error getting budgets history", error)
    }
  }

  useEffect(() => {
    const fetchBudgets = async () => {
      if (currentUser?.uid) {
        await getAllBudgets(currentUser.uid)
      }
    }
    if (!currentUser) return

    fetchBudgets()
  }, [currentUser])

  useEffect(() => {
    const fetchBudgetHistories = async () => {
      if (currentUser?.uid) {
        await getAllBudgetHistories(budgets)
      }
    }

    fetchBudgetHistories()
  }, [budgets])

  const value = {
    createBudget,
    deleteBudget,
    editBudget,
    getBudgetByID,
    getAllBudgets,
    getAllBudgetHistories,
    budgets,
    budgetsHistory,
  }

  return (
    <BudgetsContext.Provider value={value}>{children}</BudgetsContext.Provider>
  )
}
