import {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {toast} from "react-hot-toast"
import "./Auth.css"
import styled from "styled-components"

import EmailInput from "../../components/inputs/EmailInput"
import PasswordInput from "../../components/inputs/PasswordInput"
import PrimaryButton from "../../components/buttons/PrimaryButton"

import ResetPassword from "../../components/settings/ResetPassword"
import {useAuth} from "../../contexts/AuthContext"

const EmailLoginForm = () => {
  const {authService} = useAuth()
  const [userEmail, setUserEmail] = useState("")
  const [userPassword, setUserPassword] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Login - Finanzia"
  }, [])

  const SignIn = (e) => {
    e.preventDefault()

    const loginPromise = authService.signIn(userEmail, userPassword)

    toast.promise(
      loginPromise,
      {
        loading: "Login into your account...",
        success: "Logged in successfully",
        error: (err) => `Login error: ${err.message}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      }
    )

    loginPromise
      .then((_) => {
        navigate("/app/dashboard")
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const updateEmail = (e) => setUserEmail(e.target.value)
  const updatePassword = (e) => setUserPassword(e.target.value)

  return (
    <main className="login-page">
      <section className="login-container">
        <div className="auth-header centered">
          <AuthTitle>Login to your account</AuthTitle>
          <AuthText>
            Please, use your email and password to login and access your
            dashboard
          </AuthText>
        </div>
        <form className="auth-form" onSubmit={SignIn}>
          <EmailInput
            inputName={"testInput"}
            inputPlaceholder={"Correo electrónico"}
            textAlign={"center"}
            additionalClasses={"input-container"}
            onChange={updateEmail}
          />
          <PasswordInput
            inputName={"userPassword"}
            inputPlaceholder={"Contraseña"}
            textAlign={"center"}
            additionalClasses={"input-container"}
            onChange={updatePassword}
            hasToggle={true}
          />
          <PrimaryButton
            buttonText={"Login"}
            buttonStyles={{
              width: "100%",
              margin: "auto",
              marginTop: "10px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0px",
            }}
            type="submit"
          />
        </form>

        <ResetPassword
          styles={{marginTop: "16px", fontSize: "0.9rem"}}
          text={"Send link"}
          onClick={() => navigate("/cuenta/reset-password")}
        />
      </section>
    </main>
  )
}

const AuthTitle = styled.h2`
  font-size: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 28px;
  }
`

const AuthText = styled.p`
  width: 90%;
  margin: auto;
  font-weight: 300;
`

const WrongCredentials = ({errorMessage}) => {
  return <p style={{textAlign: "center", color: "red"}}>{errorMessage}</p>
}

export default EmailLoginForm
