export default function GoodIcon({color, height, width = "26px"}) {
  return (
    <svg
      width={width}
      height={height ? height : width}
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4943 1.72435C11.3306 1.67177 11.1516 1.68452 10.9997 1.75777C10.8333 1.83798 10.7233 1.97795 10.6837 2.13027L10.147 4.19921C9.95936 4.92275 9.686 5.62064 9.33412 6.27952C8.78773 7.30243 7.9609 8.08916 7.1939 8.75011L5.57072 10.1488C5.31915 10.3656 5.18676 10.6905 5.21544 11.0222L6.13171 21.619C6.178 22.1544 6.62525 22.5639 7.15992 22.5639H12.4045C15.9429 22.5639 18.9241 20.1018 19.4959 16.7955L20.2918 12.1928C20.4014 11.5594 19.9142 10.9821 19.275 10.9821H13.4297C12.3273 10.9821 11.49 9.9931 11.6679 8.90677L12.4156 4.34372C12.518 3.71868 12.4888 3.0791 12.3297 2.46605C12.249 2.15495 12.0035 1.88795 11.6579 1.77689L11.4943 1.72435ZM10.265 0.233245C10.8095 -0.0291053 11.4368 -0.0715932 12.0119 0.113149L12.1755 0.165689C13.0521 0.447309 13.736 1.14759 13.9678 2.04113C14.186 2.88228 14.2261 3.75978 14.0856 4.61734L13.338 9.18039C13.3284 9.23879 13.3734 9.28978 13.4297 9.28978H19.275C20.9665 9.28978 22.2474 10.8154 21.9594 12.4812L21.1635 17.084C20.4438 21.245 16.7212 24.2562 12.4045 24.2562H7.15992C5.74556 24.2562 4.56752 23.1733 4.44572 21.7648L3.52944 11.168C3.45396 10.2951 3.80226 9.43883 4.46601 8.86685L6.08919 7.46813C6.8284 6.83113 7.45064 6.21372 7.84142 5.48217C8.13089 4.94027 8.3551 4.36732 8.50893 3.77429L9.04564 1.70535C9.21396 1.0563 9.66479 0.522458 10.265 0.233245ZM0.809671 9.29057C1.26237 9.27103 1.65011 9.6116 1.68914 10.063L2.7853 22.74C2.85571 23.5543 2.21428 24.2562 1.39525 24.2562C0.623767 24.2562 0 23.6304 0 22.8603V10.1359C0 9.6828 0.356961 9.3101 0.809671 9.29057Z"
        fill={color}
      />
    </svg>
  )
}
