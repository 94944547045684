import {useAuth} from "../../contexts/AuthContext"
import toast from "react-hot-toast"
import {useState} from "react"
import {TextAreaInput} from "../inputs/TextArea"
import SecondaryButton from "../buttons/SecondaryButton"
import axios from "axios"

export default function ReportIssue({text = null, title = null}) {
  const [issue, setIssue] = useState("")
  const {currentUser} = useAuth()

  const handleInputChange = (e) => {
    setIssue(e.target.value)
  }

  const onSend = async (e) => {
    if (!currentUser) return

    if (issue === "") {
      toast.error("Please describe your issue before submitting.")
      return
    }

    try {
      const BASE_URL =
        "https://discord.com/api/webhooks/1282753069788168213/LR9bbYSn3caIQK7OJDQA0OeekmOWXzaOvtka_CyuLc2-tM7wwtTYBNmLBQWsDreIcG4R"
      const response = await axios.post(BASE_URL, {
        content: "Hi there devs! Here is a new issue report from a user.",
        embeds: [
          {
            title: `${currentUser.name} ${currentUser.surname} - ${currentUser.username} - ${currentUser.email}`,
            description: issue,
            color: 5814783,
          },
        ],
      })
      if (response) {
        toast.success("Issue report sent successfully.")
      } else {
        toast.error("Could not send issue report. Please try again later.")
      }
      setIssue("")
    } catch (err) {
      toast.error("Could not send issue report. Please try again later.")
    }
  }

  return (
    <div>
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
      {!text && (
        <p>
          If you have experienced any issue by using{" "}
          <span style={{fontWeight: "bold"}}>FINANZIA</span>. Don't hesitate to
          write about it in this section. We will carefully take a look to all
          your requests, trying to solve them as soon as possible.
        </p>
      )}
      <form onSubmit={onSend}>
        <TextAreaInput
          inputId={"issue_report"}
          inputName={"issue"}
          inputPlaceholder={"Describe your issue here"}
          inputValue={issue}
          additionalClasses={"input-container"}
          textAlign="left"
          onChange={handleInputChange}
        />

        <div>
          <SecondaryButton
            buttonText={"Report issue"}
            textColor="var(--primary)"
            borderRadius="12px"
            fontWeight="300"
            borderWidth="1px"
            onClick={onSend}
          />
        </div>
      </form>
    </div>
  )
}
