import {useEffect, useState} from "react"
import {DashboardPanel} from "../../components/dashboard/DashboardBasics"
import PageHeader from "../../components/pages/PageHeader"
import TransactionFilters from "../../components/transactions/TransactionFilters"
import TransactionsTable from "../../components/transactions/TransactionTable"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {TransactionFiltersProvider} from "../../contexts/TransactionFiltersContext"
import {useAuth} from "../../contexts/AuthContext"
import FilteredTransactionsStats from "../../components/transactions/stats/FilteredTransactionsStats"
import "./TransactionsPage.css"
import {CreateTransactionForm} from "../../components/transactions/TransactionForms"
import AddTransferIcon from "../../components/icons/AddTranferIcon"
import FilterIcon from "../../components/icons/FilterIcon"
import {useResponsive} from "../../contexts/ResponsiveContext"
import DashboardIcon from "../../components/icons/DashboardIcon"
import FilteredTransactionsList from "../../components/transactions/FilteredTransactionsList"

export default function Transactions() {
  const {transactions, getAllTransactions} = useTransactionContext()
  const {currentUser} = useAuth()

  const [showTransactionForm, setShowTransactionForm] = useState(false)
  const [showStats, setShowStats] = useState(false)

  const {isPhone, isTablet} = useResponsive()
  const [showFilters, setShowFilters] = useState(!isPhone)

  useEffect(() => {
    document.title = "Transactions - Finanzia"
  }, [])

  useEffect(() => {
    if (!currentUser || !currentUser.uid) return
    getAllTransactions(currentUser.uid)
  }, [currentUser])

  return (
    <TransactionFiltersProvider>
      <main className="default-page transactions-page">
        <section className={`dashboard-left ${isPhone ? "isPhone" : ""}`}>
          <PageHeader
            title="Transactions"
            hasMonthSelector={false}
            actions={
              <TransactionPageActions
                showFilters={setShowFilters}
                showCreateForm={setShowTransactionForm}
                isFormActive={showTransactionForm}
                isFiltersActive={showFilters}
                isStatsActive={showStats}
                showStats={setShowStats}
              />
            }
          />
          {isPhone && (showFilters || showStats) && (
            <DashboardPanel orientation="vertical">
              {showFilters && <TransactionFilters hasTitle />}
              {showStats && <FilteredTransactionsStats />}
            </DashboardPanel>
          )}
          {!isPhone && !isTablet && <TransactionsTable />}
          {isPhone && (
            <DashboardPanel>
              <FilteredTransactionsList hasTitle={showFilters || showStats} />
            </DashboardPanel>
          )}

          {isTablet && !isPhone && (
            <FilteredTransactionsList hasTitle={false} />
          )}
        </section>
        {!isPhone && (
          <section className={`dashboard-right`}>
            <DashboardPanel orientation="vertical">
              {showTransactionForm && <CreateTransactionForm />}
              {showFilters && <TransactionFilters hasTitle />}
              <FilteredTransactionsStats />
            </DashboardPanel>
          </section>
        )}
      </main>
    </TransactionFiltersProvider>
  )
}

const TransactionPageActions = ({
  showFilters,
  showCreateForm,
  isFiltersActive,
  isFormActive,
  isStatsActive,
  showStats,
}) => {
  const {isPhone} = useResponsive()
  return (
    <div className="transaction-page-actions">
      <span
        className="action"
        onClick={() => {
          if (isFiltersActive) {
            showFilters(false)
          } else {
            showFilters(true)
            showCreateForm(false)
            showStats(false)
          }
        }}
      >
        <FilterIcon color={isFiltersActive ? "#02dca2" : "#334050"} />
      </span>
      {!isPhone && (
        <span
          className="action"
          onClick={() => {
            showFilters(false)
            showCreateForm(true)
          }}
        >
          <AddTransferIcon
            color={isFormActive ? "#02dca2" : "#334050"}
            width="26px"
          />
        </span>
      )}
      {isPhone && (
        <span
          className="action"
          onClick={() => {
            showFilters(false)
            showStats(true)
          }}
        >
          <DashboardIcon
            color={isStatsActive ? "#02dca2" : "#334050"}
            width="26px"
          />
        </span>
      )}
    </div>
  )
}
