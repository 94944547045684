import {useEffect, useState} from "react"
import useRecurrenceContext from "../../contexts/RecurrentTransactionContext"
import PieChart from "../charts/PieChart"
import {useStats} from "../../contexts/StatsContext"

export default function RecurrencesBreakdown() {
  const [categoryType, setCategoryType] = useState("EXPENSE")
  const {recurrenceBreakdown, getRecurrenceBreakdown} = useRecurrenceContext()
  const {selectedRange} = useStats()

  const incomeClass = `category-type ${
    categoryType === "INCOME" ? "active" : ""
  }`

  const expensesClass = `category-type ${
    categoryType === "EXPENSE" ? "active" : ""
  }`

  useEffect(() => {
    if (selectedRange[0]) getRecurrenceBreakdown()
  }, [selectedRange])

  return (
    <div>
      <div className="breakdown-header">
        <h2 className="title">Breakdown</h2>
        <div className="category-options">
          <span
            onClick={() => {
              setCategoryType("EXPENSE")
            }}
            className={expensesClass}
          >
            Expenses
          </span>
          <span
            onClick={() => {
              setCategoryType("INCOME")
            }}
            className={incomeClass}
          >
            Income
          </span>
        </div>
      </div>

      {recurrenceBreakdown && (
        <PieChart
          type={categoryType}
          data={
            categoryType === "EXPENSE"
              ? recurrenceBreakdown.expenses
              : recurrenceBreakdown.income
          }
        />
      )}
    </div>
  )
}
