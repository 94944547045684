import {useEffect, useState} from "react"
import {TextInput} from "../inputs/TextInput"
import {useTransactionFilters} from "../../contexts/TransactionFiltersContext"
import "./filters/Filters.css"
import CategoryFilter from "./filters/CategoryFilter"
import AccountFilter from "./filters/AccountFilter"
import DateRangeFilter from "./filters/DateRangeFilter"
import TrashIcon from "../icons/TrashIcon"

export default function TransactionFilters() {
  const [searchQuery, setSearchQuery] = useState("")
  const {filterByName, clearFilters} = useTransactionFilters()

  useEffect(() => {
    filterByName(searchQuery)
  }, [searchQuery, filterByName])

  return (
    <section className="transaction-filters-container">
      <div className="filters-header">
        <h2 className="title">Filters</h2>
        <div className="filter-actions">
          <span style={{cursor: "pointer"}} onClick={clearFilters}>
            <TrashIcon width="24px" color={"#334050"} />
          </span>
        </div>
      </div>
      <form className="transaction-filters">
        <TextInput
          inputId={"filterByName"}
          inputName={"filterByName"}
          inputPlaceholder={"Search by transaction name..."}
          additionalClasses={"input-container"}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <CategoryFilter />
        <AccountFilter />
        <DateRangeFilter />
      </form>
    </section>
  )
}
