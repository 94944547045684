import {useEffect, useState} from "react"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {
  dateIsToday,
  formatDate,
  formatTime,
  getTransactionSign,
  isNewMonth,
} from "../../utils/transactionUtils"
import "./Transactions.css"
import {useAuth} from "../../contexts/AuthContext"
import {usePopup} from "../../contexts/PopupContext"
import {ClosePopupButton} from "../popups/SimplePopup"
import closeIcon from "./../../static/resources/icons/close.svg"
import trashIcon from "./../../static/resources/icons/trash.svg"
import editIcon from "./../../static/resources/icons/edit.svg"
import viewIcon from "./../../static/resources/icons/view.svg"
import {EditTransactionForm} from "./TransactionForms"
import toast from "react-hot-toast"
import {getTransactionSettings} from "./transactionUtils"
import {useNavigationContext} from "../../contexts/NavigationContext"
import IdeaIcon from "../icons/IdeaIcon"
import EmptyPanel from "../layout/EmptyPanel"
import AddTransactionPopup from "../popups/specific/AddNewTransaction"
import {useAccounts} from "../../contexts/AccountContext"

function TransactionList({title, type, maxHeight}) {
  const {transactions, categories, currentCategory} = useTransactionContext()
  const {accounts, currentAccount} = useAccounts()
  const {showPopup} = usePopup()
  const {pathname} = useNavigationContext()

  const handleTransactionClick = (transaction, account, category) => {
    showPopup({
      content: (
        <TransactionDetails
          transaction={transaction}
          account={account}
          category={category}
          type={type}
        />
      ),
    })
  }

  return (
    <section
      className="transaction-list-container container"
      style={{maxHeight: maxHeight}}
    >
      <div className="section-header">
        <h2>{title}</h2>
        <p></p>
      </div>
      <div
        key={currentAccount + currentCategory + pathname}
        className="transaction-list"
      >
        {transactions &&
          transactions.map((transaction, index) => {
            if (
              pathname.includes("/account") &&
              currentAccount &&
              transaction.accountID !== currentAccount.id
            ) {
              return
            }

            const account = accounts.find(
              (acc) => acc.id === transaction.accountID
            )

            const fromAccount =
              transaction.type === "TRANSFER"
                ? accounts.find((acc) => acc.id === transaction.fromAccountID)
                : ""

            const category = categories.find(
              (cat) => cat.id === transaction.categoryID
            )

            const previousTransaction = transactions[index > 0 ? index - 1 : 0]

            const isDifMonth = isNewMonth(transaction, previousTransaction)
            return (
              <>
                {isDifMonth.newMonth && (
                  <div key={isDifMonth.date} className="month-row">
                    <p>{isDifMonth.date}</p>
                  </div>
                )}
                <TransactionRow
                  key={transaction.id + transactions.length + pathname}
                  transaction={transaction}
                  account={account}
                  category={category}
                  onClick={handleTransactionClick}
                  rowKey={transaction.id}
                  fromAccount={fromAccount}
                />
              </>
            )
          })}

        {transactions && transactions.length === 0 && (
          <EmptyPanel
            icon={<IdeaIcon color={"var(--lighter-gray)"} width={"80px"} />}
            title={"You don't have transactions"}
            text={"It's time to create your first transaction"}
            color="var(--lighter-gray)"
            maxWidth="400px"
            padding="2rem"
            cta={<AddTransactionPopup buttonColor={"var(--lighter-gray)"} />}
          />
        )}
      </div>
    </section>
  )
}

export function TransactionRow({
  transaction,
  account,
  category,
  onClick,
  rowKey,
  fromAccount = "",
}) {
  const amount = getTransactionSign(transaction.type) + transaction.value + "€"
  const amountClass = `transaction-amount ${transaction.type}`
  const iconClass = `transaction-icon ${transaction.type.toLowerCase()}`
  return (
    <div
      className="transaction transaction-row"
      onClick={() => {
        onClick(transaction, account, category)
      }}
      key={rowKey}
    >
      <div className="transaction-left">
        <div className={iconClass}></div>
        <div>
          <h4 className="transaction-name">{transaction.name}</h4>
          <p>
            {formatTime(transaction.time)} · {formatDate(transaction.date)}
          </p>
        </div>
      </div>
      <div className="transaction-right">
        <h3 className={amountClass}>{amount}</h3>
        <p>
          {category && `${category.name} ·`}{" "}
          {transaction.type === "TRANSFER" && `${fromAccount.name} -> `}{" "}
          {account && account.name}{" "}
        </p>
      </div>
    </div>
  )
}

function TransactionDetails({transaction, account, category, type}) {
  const [currentTransaction, setCurrentTransaction] = useState(transaction)
  const [currentAccount, setCurrentAccount] = useState(account)
  const [currentCategory, setCurrentCategory] = useState(category)
  const {deleteTransaction, getAllTransactions, categories, getTransactions} =
    useTransactionContext()

  const {accounts} = useAccounts()
  const {currentUser} = useAuth()
  const [content, setContent] = useState("viewTransaction")
  const [deleteStatusMessage, setDeleteStatusMessage] = useState(null)
  const {hidePopup} = usePopup()

  const manageContentChange = (e) => {
    setContent(e.target.id)
  }
  const handleDeleteTransaction = async (e) => {
    const isTransactionDeleted = await deleteTransaction(currentTransaction.id)

    if (isTransactionDeleted) {
      getAllTransactions(transaction.userID)
      hidePopup()
      toast.success("Transaction has been deleted")
    } else {
      setDeleteStatusMessage({
        success: false,
        message: "There was an error deleting the transaction",
      })
    }
  }
  const handleTransactionEdition = (editedTransaction) => {
    setCurrentTransaction(editedTransaction)

    const account = accounts.find(
      (acc) => acc.id === parseFloat(editedTransaction.accountID)
    )
    setCurrentAccount(account)

    const category = categories.find(
      (cat) => cat.id === parseFloat(editedTransaction.categoryID)
    )

    let query
    if (type === "byAccount" && currentUser && currentAccount) {
      query = {
        accountID: currentAccount.id,
        fromAccountID: currentAccount.id,
      }
    } else if (type === "byCategory" && currentUser && currentCategory) {
      query = {
        userID: currentUser.uid,
        categoryID: currentCategory.id,
      }
    } else {
      query = {
        userID: currentUser.uid,
      }
    }

    getTransactions(query)

    setCurrentCategory(category)
    setContent("viewTransaction")
    setDeleteStatusMessage({
      success: true,
      message: "Transaction updated successfully",
    })
  }

  const transactionSettings = getTransactionSettings(currentTransaction)

  const amount =
    getTransactionSign(currentTransaction.type) + currentTransaction.value + "€"
  const amountClass = `transaction-value ${currentTransaction.type}`
  return (
    <div className="transaction-details">
      <div className="popup-header">
        <div className="header-left">
          <p>{transactionSettings.displayName}</p>
          <div style={{display: "flex", gap: "20px", alignItems: "baseline"}}>
            <h2>{currentTransaction.name}</h2>
            <div
              className="actions"
              style={{display: "flex", alignItems: "baseline", gap: "8px"}}
            >
              {content == "viewTransaction" && (
                <button
                  id="editTransaction"
                  value={currentTransaction.id}
                  onClick={manageContentChange}
                  style={{width: "18px", padding: "0px", height: "18px"}}
                >
                  <img
                    src={editIcon}
                    id="editTransaction"
                    style={{fill: "#02dca2", cursor: "pointer", width: "100%"}}
                    onClick={manageContentChange}
                  />
                </button>
              )}

              {content == "editTransaction" && (
                <button
                  id="viewTransaction"
                  value={currentTransaction.id}
                  onClick={manageContentChange}
                  style={{width: "18px", padding: "0px", height: "18px"}}
                >
                  <img
                    src={viewIcon}
                    id="viewTransaction"
                    style={{fill: "#02dca2", cursor: "pointer", width: "100%"}}
                    onClick={manageContentChange}
                  />
                </button>
              )}
              <button
                value={currentTransaction.id}
                onClick={handleDeleteTransaction}
                style={{width: "18px", padding: "0px", height: "18px"}}
              >
                <img
                  src={trashIcon}
                  style={{fill: "#02dca2", cursor: "pointer", width: "100%"}}
                />
              </button>
            </div>
          </div>
        </div>
        <ClosePopupButton icon={closeIcon} text={"X"} />
      </div>
      {content && content === "viewTransaction" && (
        <div>
          <p>{currentTransaction.description}</p>
          <p>
            {formatTime(currentTransaction.time)} ·{" "}
            {formatDate(currentTransaction.date)}
          </p>
          <p>
            {currentCategory && `${currentCategory.name} · `}
            {currentAccount && currentAccount.name}
          </p>
          <h3 className={amountClass}>{amount}</h3>
        </div>
      )}
      {content && content == "editTransaction" && (
        <EditTransactionForm
          transaction={currentTransaction}
          onTransactionEdited={handleTransactionEdition}
        />
      )}
      {deleteStatusMessage && (
        <p
          style={{
            color: `${deleteStatusMessage.success ? "#02dca2" : "#334050"}`,
            textAlign: "center",
          }}
        >
          {deleteStatusMessage.message}
        </p>
      )}
    </div>
  )
}

export {TransactionList, TransactionDetails}
