import FeatureList from "../../components/features/FeatureList"
import PageHeader from "../../components/pages/PageHeader"
import {useAuth} from "../../contexts/AuthContext"
import {useState} from "react"
import {TextInput} from "../../components/inputs/TextInput"
import SecondaryButton from "../../components/buttons/SecondaryButton"
import axios from "axios"
import toast from "react-hot-toast"
import {FeatureProvider} from "../../contexts/FeatureContext"
import "./VoteFeaturesPage.css"
import {TextAreaInput} from "../../components/inputs/TextArea"

export default function VoteNewFeaturesPage() {
  return (
    <FeatureProvider>
      <main className="vote-page">
        <h1 className="page-title" style={{textAlign: "center"}}>
          New features
        </h1>
        <p style={{textAlign: "center"}}>
          Vote on the new features you want us to build for Finanzia
        </p>

        <FeatureList />
        <VotePageInfo />
        <VotePageProposal />
      </main>
    </FeatureProvider>
  )
}

function VotePageInfo() {
  return (
    <div className="vote-page-info">
      <h2> How does it work?</h2>
      <p>
        As we implement and release new features we will be updating this list,
        adding here new features that we have been thinking about adding to
        Finanzia.
      </p>
      <p>
        You can vote your favourite one, and at the end of the voting period we
        will take the results into consideration when deciding the new feature
        we will be working on.{" "}
      </p>
      <p>
        But don’t worry if your favourite feature is not chosen in one votation,
        you’ll have the change to vote for it again in the next voting period.
      </p>
    </div>
  )
}

function VotePageProposal() {
  const [proposal, setProposal] = useState("")
  const {currentUser} = useAuth()

  const handleInputChange = (e) => {
    setProposal(e.target.value)
  }

  const onSend = async (e) => {
    if (!currentUser) return

    if (proposal === "") {
      toast.error("Please write your proposal before submitting.")
      return
    }

    try {
      const BASE_URL =
        "https://discord.com/api/webhooks/1283493030187372564/9bWMaqR2V2zJEV8F055XA7-49zInEHLjZNHRZpJ9NA2KMZfFnXifKghvVoODERDsjOYn"
      const response = await axios.post(BASE_URL, {
        content: "Hi there devs! Here is a new feature proposal from a user.",
        embeds: [
          {
            title: `${currentUser.name} ${currentUser.surname} - ${currentUser.username} - ${currentUser.email}`,
            description: proposal,
            color: 5814783,
          },
        ],
      })
      if (response) {
        toast.success("Feature proposal sent successfully.")
      } else {
        toast.error("Could not send feature proposal. Please try again later.")
      }
      setProposal("")
    } catch (err) {
      toast.error("Could not send feature proposal. Please try again later.")
    }
  }

  return (
    <div className="vote-page-info" style={{marginTop: "1rem"}}>
      <div>
        <h2>Feature proposal</h2>
      </div>

      <div>
        <p>
          If you have in mind any other amazing feature you consider we should
          implement, don't hesitate to ask for it! In the following box you will
          be able to suggest any new features. We will carefully take a look to
          all your requests and consider to implement them for future{" "}
          <span style={{fontWeight: "bold"}}>FINANZIA</span> versions.
        </p>
        <form onSubmit={onSend}>
          <div className="form-row" style={{padding: "10px"}}>
            <TextAreaInput
              inputId={"feature_proposal"}
              inputName={"feature"}
              inputPlaceholder={"Describe your feature here"}
              inputValue={proposal}
              additionalClasses={"input-container"}
              textAlign="left"
              onChange={handleInputChange}
            />
          </div>
          <div>
            <SecondaryButton
              buttonText={"Propose feature"}
              textColor="var(--primary)"
              borderRadius="12px"
              fontWeight="300"
              borderWidth="1px"
              onClick={onSend}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
