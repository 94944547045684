import {createContext, useContext, useState} from "react"
import axios from "axios"
import {useAuth} from "./AuthContext"
import {AccountRepository} from "../infraestructure/api/accountRepository"
import {AccountService} from "../domain/accounts/accountService"
import {apiClient} from "../infraestructure/api/client"

const AccountContext = createContext()

export function useAccounts() {
  return useContext(AccountContext)
}

export function AccountProvider({children}) {
  const {currentUser} = useAuth()
  const [accounts, setAccounts] = useState([])
  const [currentAccount, setCurrentAccount] = useState()
  const [accountGroups, setAccountGroups] = useState([])

  const createAccount = async (account) => {
    try {
      const response = await apiClient.post("/accounts", account)
      return response
    } catch (error) {
      console.log("Error creating account", account)
      return false
    }
  }

  const deleteAccount = async (id) => {
    const params = {
      id: id,
      userID: currentUser.uid,
    }
    try {
      const response = await apiClient.delete("/accounts", {params: params})
      return response
    } catch (err) {
      console.log("Error deleting account", err)
      return false
    }
  }

  const editAccount = async (account) => {
    try {
      const response = await apiClient.put("/accounts", account)

      if (response) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const getAllAccounts = async (userID) => {
    const params = {
      userID: userID,
    }
    try {
      const response = await apiClient.get("/accounts", {params: params})
      const allAccounts = response

      setAccounts(allAccounts)
    } catch (err) {
      console.log("Error retrieving accounts", err)
      return null
    }
  }

  const getAccounts = async (query) => {
    try {
      const response = await apiClient.get("/accounts", {params: query})
      const account = response

      setCurrentAccount(account)
      return account
    } catch (err) {
      console.log("Error retrieving account:", err)
      return null
    }
  }

  const getAccountGroups = async () => {
    try {
      const response = await apiClient.get("/account-groups")
      setAccountGroups(response)
    } catch (error) {
      console.log("Error retrieving account groups:", error)
      return false
    }
  }

  const accountRepo = new AccountRepository()
  const accountService = new AccountService(accountRepo)

  const value = {
    accounts,
    currentAccount,
    accountGroups,
    createAccount,
    editAccount,
    deleteAccount,
    getAllAccounts,
    getAccounts,
    getAccountGroups,
    accountService,
  }
  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  )
}
