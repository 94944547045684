import "./Navigation.css"
import {LogOut, MenuItem} from "./NavigationComponents"

import LogOutIcon from "./../../static/resources/icons/logout.svg"
import DashboardIcon from "../icons/DashboardIcon"
import {useNavigate} from "react-router"

import favicon from "./../../static/resources/icons/favicon.svg"
import {useAuth} from "../../contexts/AuthContext"

export default function LateralMenu() {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  return (
    <div className="lateral-menu">
      <div className="lateral-menu-top">
        <div>
          <img
            src={favicon}
            alt="Finanzia logo"
            style={{width: "26px", marginBottom: "8px"}}
          />
          <span className="menu-divisor"></span>
          <MenuItem
            text={"Home"}
            iconName={"dashboard"}
            onClick={() => navigate("/app/dashboard")}
            toLink={"/app/dashboard"}
          />
          <MenuItem
            text={"Transactions"}
            iconName={"transactions"}
            toLink={"/app/transactions"}
            onClick={() => navigate("/app/transactions")}
          />

          <MenuItem 
            text={"Budgets"}
            iconName={"budgets"}
            toLink={"/app/budgets"}
            onClick={() => navigate("/app/budgets")}
          />
        </div>
        <div></div>
      </div>
      {currentUser && currentUser.userType === "admin" && (
        <div className="lateral-menu-admin">
          <h3
            style={{
              fontSize: "0.7rem",
              marginBottom: "8px",
              color: "var(--light-gray)",
            }}
          >
            {" "}
            Admin
          </h3>
          <MenuItem
            text={"KPIs"}
            iconName={"kpi"}
            toLink={"/admin/kpi"}
            onClick={() => navigate("/admin/kpi")}
          />
        </div>
      )}
      <div className="lateral-menu-bottom">
        <MenuItem
          text={"Vote"}
          iconName={"vote"}
          toLink={"/app/vote"}
          onClick={() => navigate("/app/vote")}
        />
        <MenuItem
          text={"Settings"}
          iconName={"settings"}
          onClick={() => navigate("/app/settings")}
          toLink={"/app/settings"}
        />
        <span className="menu-divisor"></span>
        <LogOut text={"Log out"} icon={LogOutIcon} />
      </div>
    </div>
  )
}
