import {useEffect, useState} from "react"
import {useKPIs} from "../../../contexts/KpisContext"
import KpiAreaChart from "../KpiAreaChart"

export default function DailyBudgets() {
  const {dailyBudgets} = useKPIs()
  const [todayBudgets, setTodayBudgets] = useState()
  const [yesterdayBudgets, setYesterdayBudgets] = useState()
  const [data, setData] = useState()
  const [dataLabels, setDataLabels] = useState()

  useEffect(() => {
    if (dailyBudgets && dailyBudgets.length > 0) {
      setTodayBudgets(dailyBudgets[0].new_budgets)
      setYesterdayBudgets(dailyBudgets[1].new_budgets)

      const dataArray = dailyBudgets
        .map((transaction) => transaction.new_budgets)
        .reverse()

      setData(dataArray)

      const dataLabelsArray = dailyBudgets
        .map((transaction) => transaction.date)
        .reverse()

      setDataLabels(dataLabelsArray)
    }
  }, [dailyBudgets])

  return (
    <div className="kpi">
      <div className="kpi-header">
        <p className="kpi-title">Daily budgets</p>

        <div className="today-kpi-value">
          <h3>{todayBudgets}</h3>
          <p>{todayBudgets - yesterdayBudgets}</p>
        </div>
      </div>

      <KpiAreaChart
        data={data}
        dataLabels={dataLabels}
        datasetLabel="New budgets"
      />
    </div>
  )
}
