import {useEffect, useState} from "react"
import {ClosePopupButton} from "./SimplePopup"
import ReactMarkdown from "react-markdown"
import {useFeatureContext} from "../../contexts/FeatureContext"
import PrimaryButton from "../buttons/PrimaryButton"
import toast from "react-hot-toast"
import {useAuth} from "../../contexts/AuthContext"

export default function FeaturePopup({slug}) {
  const {getFeature, postVote, userVotes, getUserVotes, getVoteTotals} =
    useFeatureContext()
  const [feature, setFeature] = useState(undefined)
  const [isVoteButtonDisabled, setIsVoteButtonDisabled] = useState(false)
  const {currentUser} = useAuth()

  useEffect(() => {
    const fetchFeature = async () => {
      const featureData = await getFeature(slug)
      setFeature(featureData)
    }
    fetchFeature()

    if (userVotes === undefined) {
      getUserVotes()
    }
  }, [slug, currentUser])

  const onVoteClick = async () => {
    setIsVoteButtonDisabled(true)
    const votePromise = postVote(feature.metadata.votingPeriod, slug)

    toast.promise(
      votePromise,
      {
        loading: "Recording your vote...",
        success: "Vote recorded successfully",
        error: "We couldn't store your vote. Please try again.",
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      }
    )

    votePromise
      .then((voteResponse) => {
        setIsVoteButtonDisabled(true)
        getVoteTotals()
        getUserVotes()
      })
      .catch((error) => {
        console.error("Error recording vote", error)
      })
  }

  return (
    <div className="popup-container feature-popup">
      {feature && (
        <div className="popup">
          <div className="feature-image">
            <img
              src={`https://backend.finanzia.app/images/features/${feature.metadata.votingPeriod}/${feature.metadata.featuredImage}`}
              alt="Feature image"
            />
          </div>
          <div className="feature-content">
            <div>
              <div className="popup-header">
                <h2 className="feature-title"> {feature.metadata.title} </h2>
                <ClosePopupButton />
              </div>

              <ReactMarkdown>{feature.content}</ReactMarkdown>
            </div>
            <div className="feature-footer">
              {/* Conditional rendering based on userVotes */}
              {userVotes === undefined && <div>Loading votes...</div>}

              {userVotes && userVotes.length === 0 && (
                <PrimaryButton
                  buttonText={"Vote for this feature"}
                  onClick={onVoteClick}
                  isDisabled={isVoteButtonDisabled}
                />
              )}

              {userVotes && userVotes.length > 0 && (
                <span className="already-voted"> You've already voted </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
