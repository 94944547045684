import {useNavigate} from "react-router"
import {PostTags} from "./PostTags"

export default function PostCard({slug, metadata, isFeatured}) {
  const postDate = new Date(metadata.date).toISOString().split("T")[0]

  const navigate = useNavigate()
  return (
    <div
      className={`post-card ${isFeatured ? "isFeatured" : ""}`}
      onClick={() => navigate(`/blog/${slug}`)}
    >
      {metadata.isFeatured && (
        <span
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            fontSize: "0.8rem",
            padding: "0.4rem 0.8rem",
            color: "var(--white)",
            backgroundColor: "var(--primary)",
            borderTopLeftRadius: "11.5px",
            borderBottomRightRadius: "2px",
          }}
        >
          Featured
        </span>
      )}
      <div className="post-card-image">
        <img
          src={`https://backend.finanzia.app/images/posts/${slug}/${metadata.featuredImage}`}
          alt={metadata.title}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
      <div className="post-card-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            flexWrap: "wrap",
          }}
        >
          <h2 className="post-title">{metadata.title}</h2>
          <p className="post-date">{postDate}</p>
        </div>
        <p className="post-excerpt">{metadata.excerpt}</p>
        {metadata.tags && <PostTags tags={metadata.tags} />}
      </div>
    </div>
  )
}
