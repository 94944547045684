import {createContext, useContext, useState} from "react"
import {UserRepository} from "../infraestructure/api/userRepository"
import {UserService} from "../domain/user/userService"

const UserContext = createContext()

export const useUser = () => {
  return useContext(UserContext)
}

export const UserProvider = ({children}) => {
  const [me, setMe] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const userService = new UserService(new UserRepository())

  const storeUser = async (userData) => {
    setIsLoading(true)
    try {
      const storedUser = await userService.storeUser(userData)
      setMe(storedUser)
    } catch (error) {
      console.error("Error storing user:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const value = {storeUser, isLoading}
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
