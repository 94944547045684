import "./Dashboard.css"

const DashboardPanel = ({
  children,
  orientation = "horizontal",
  maxHeight = null,
  backgroundColor = "#f9f9f9",
  color = "#334050",
}) => {
  const panelClasses = `dashboard-panel ${orientation}`
  const panelStyles = {
    backgroundColor: backgroundColor,
    color: color,
  }
  if (maxHeight) {
    panelStyles.maxHeight = maxHeight
  }
  
  return (
    <div style={panelStyles} className={panelClasses}>
      {children}
    </div>
  )
}

const DashboardSectionHeader = ({children}) => {
  return <div className="panel-header">{children}</div>
}

const DashboardRow = ({children, alignItems = "flex-start", pTop = "0rem"}) => {
  return (
    <div
      style={{alignItems: alignItems, paddingTop: pTop}}
      className="dashboard-row"
    >
      {children}
    </div>
  )
}

export {DashboardPanel, DashboardSectionHeader, DashboardRow}
