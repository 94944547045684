export default function SecondaryButton({
  buttonText,
  onClick,
  borderColor = "#02dca2",
  textColor = "#334050",
  borderWidth = "2px",
  buttonStyles = {
    width: "70%",
    margin: "auto",
    marginTop: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0.8rem",
  },
  type = "button",
  borderRadius = "4px",
  fontWeight = "600",
}) {
  const buttonProps = onClick ? {onClick} : {}

  const border = `${borderWidth} solid ${borderColor}`
  const color = textColor
  const combinedStyles = {
    ...buttonStyles,
    border,
    color,
    borderRadius: borderRadius,
  } // Combine the styles here
  return (
    <button
      style={combinedStyles}
      type={type}
      className="primary-button"
      {...buttonProps}
    >
      <span style={{fontWeight: fontWeight}}>{buttonText || "test"}</span>
    </button>
  )
}
