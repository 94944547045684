import toast from "react-hot-toast"
import {usePopup} from "../../contexts/PopupContext"
import useRecurrenceContext from "../../contexts/RecurrentTransactionContext"
import PrimaryButton from "../buttons/PrimaryButton"
import SecondaryButton from "../buttons/SecondaryButton"
import {ClosePopupButton} from "../popups/SimplePopup"

export default function DeleteRecurrentConfirmation({recurrentTransaction}) {
  const {hidePopup} = usePopup()
  const {deleteRecurrentTransaction} = useRecurrenceContext()

  const handleDelete = async (e) => {
    const isRecurrentDeleted = await deleteRecurrentTransaction(
      recurrentTransaction.id
    )
    if (isRecurrentDeleted) {
      hidePopup()
      toast.success("Recurrent transaction has been deleted")
    } else {
      toast.error("Could not delete recurrent transaction")
    }
  }
  return (
    <div className="confirm-recurrence-delete">
      <div className="popup-header">
        <h2> Are you sure?</h2>
        <ClosePopupButton />
      </div>
      <p>
        {" "}
        Recurrence "
        <span style={{fontWeight: 700}}>{recurrentTransaction.name}</span>" will
        be permanently removed
      </p>

      <div
        style={{
          paddingTop: "1.4rem",
          display: "flex",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <SecondaryButton
          onClick={hidePopup}
          buttonText={"No, go back"}
          textColor="var(--primary)"
        />
        <PrimaryButton onClick={handleDelete} buttonText={"Yes, delete!"} />
      </div>
    </div>
  )
}
