import {useNavigate} from "react-router"

export function PostTags({tags}) {
  return (
    <div className="post-tags-container">
      {tags.map((tag) => (
        <PostTag tag={tag} key={tag} />
      ))}
    </div>
  )
}

export function PostTag({tag}) {
  const navigate = useNavigate()
  return (
    <div className="post-tag">
      <span
        onClick={() => {
          navigate(`/blog/tags/${tag}`)
        }}
      >
        {tag}
      </span>
    </div>
  )
}
