import toast from "react-hot-toast"
import {usePopup} from "../../contexts/PopupContext"
import { useBudgetContext } from "../../contexts/BudgetsContext"
import { ClosePopupButton } from "../popups/SimplePopup"
import { EditBudgetForm } from "./EditBudgetForm"
import { useState } from "react"

export default function EditBudgetConfirmation({budget}) {
    const {hidePopup} = usePopup()
    const {editBudget} = useBudgetContext()

    const handleEdit = async (e) => {
        const isBudgetEdited = await editBudget(budget)

        if (isBudgetEdited) {
            hidePopup()
            toast.success("Budget has been edited")
        } else {
            toast.error("Could not edit budget")
        }
    }

    return (
        <div className="confirm-budget-edit">
            <div className="popup-header">
                <p style={{fontSize: 20}}>
                    <span style={{fontWeight: 700}}>Edit budget - {budget.name}</span>
                </p>
                <ClosePopupButton />
            </div>
            <div>
                <EditBudgetForm budget={budget} />
            </div>
        </div>
    )
}