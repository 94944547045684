import "./Inputs.css"

const TextInput = ({
  inputId,
  inputName,
  inputPlaceholder,
  inputLabel,
  onChange,
  inputValue,
  additionalClasses,
  textAlign = "left",
  isDisabled = false,
  isRequired = true,
  displayLabel = true,
  marginTop = "0px",
  fullBorder = false,
}) => {
  return (
    <div
      className={`text-input ${additionalClasses}`}
      style={{marginTop: marginTop}}
    >
      {displayLabel && <label htmlFor={inputId}>{inputLabel}</label>}
      <input
        style={{textAlign: textAlign}}
        placeholder={inputPlaceholder}
        id={inputId}
        name={inputName}
        type="text"
        value={inputValue}
        onChange={onChange}
        disabled={isDisabled}
        className={fullBorder ? "full-border" : ""}
        required={isRequired ? true : false}
      ></input>
    </div>
  )
}

export {TextInput}
