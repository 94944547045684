import {useNavigate} from "react-router"
import {useNavigationContext} from "../../contexts/NavigationContext"
import CloseIcon from "../icons/CloseIcon"
import HamburgerMenuIcon from "../icons/HamburgerMenuIcon"

export default function HamburgerMenu({children}) {
  const {toggleHamburgerMenu, isHamburgerMenuOpen} = useNavigationContext()
  const navigate = useNavigate()
  if (!isHamburgerMenuOpen) {
    return (
      <div>
        <span
          style={{lineHeight: 0, height: "fit-content", display: "block"}}
          onClick={toggleHamburgerMenu}
        >
          <HamburgerMenuIcon color={"#334050"} height={"36px"} width={"36px"} />
        </span>
      </div>
    )
  }

  return (
    <div className="hamburger-menu">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          marginBottom: "2rem",
        }}
      >
        <div className="top-menu-left">
          <div
            className="logo"
            style={{cursor: "pointer"}}
            onClick={() => {
              navigate("/")
              if (isHamburgerMenuOpen) toggleHamburgerMenu()
            }}
          >
            <h1>
              FINANZ
              <span style={{color: "var(--primary-green)", fontWeight: "700"}}>
                IA
              </span>
            </h1>
          </div>
        </div>
        <span onClick={toggleHamburgerMenu}>
          <CloseIcon color={"#334050"} height={"30px"} width={"30px"} />
        </span>
      </div>
      <div className="hamburger-menu-items">{children}</div>
    </div>
  )
}
