import useRecurrenceContext from "../../../contexts/RecurrentTransactionContext"
import {
  formatRecurrenceFrequency,
  getRecurrenceSettings,
} from "./RecurrenceTransactionsUtils"
import TrashIcon from "../../icons/TrashIcon"
import EditIcon from "../../icons/EditIcon"
import {usePopup} from "../../../contexts/PopupContext"
import DeleteRecurrentConfirmation from "../../recurrent_transactions/DeleteRecurrentConfirmation"
import EmptyPanel from "../../layout/EmptyPanel"
import IdeaIcon from "../../icons/IdeaIcon"

export default function RecurrentTransactionsTable() {
  const {recurrentTransactions} = useRecurrenceContext()
  const {showPopup} = usePopup()

  const handleRecurrenceDelete = (transaction) => {
    showPopup({
      content: (
        <DeleteRecurrentConfirmation recurrentTransaction={transaction} />
      ),
    })
  }

  return (
    <div className="transaction-table">
      <table
        style={{
          width: "100%",
          height: "100%",
          borderCollapse: "collapse",
          borderSpacing: "0",
        }}
      >
        <thead className="table-header">
          <tr>
            <th>Name</th>
            <th>Frequency</th>
            <th style={{textAlign: "right"}}>Total</th>
            <th style={{textAlign: "right"}}>Recurrent amount</th>
            <th style={{fontWeight: 400, textAlign: "center"}}>Actions</th>
          </tr>
        </thead>
        <tbody style={{rowGap: "20px"}}>
          {recurrentTransactions &&
            recurrentTransactions.map((transaction) => {
              const recurrenceSettings = getRecurrenceSettings(transaction)
              return (
                <tr key={transaction.id}>
                  <td>
                    <p className="transaction-name">{transaction.name}</p>
                    <p className="transaction-description">
                      {transaction.categoryName} · {transaction.accountName}
                    </p>
                  </td>
                  <td>
                    {formatRecurrenceFrequency(transaction.recurrenceType)}
                    <p className="transaction-description">
                      {transaction.executionDate}
                    </p>
                  </td>
                  <td>
                    <div
                      className={`transaction-amount ${recurrenceSettings.class}`}
                    >
                      {transaction.type === "EXPENSE" ? "-" : ""}
                      {transaction.totalAmount}€
                    </div>
                  </td>
                  <td className="transaction-amount-container">
                    <p className="transaction-type">
                      {recurrenceSettings.displayName}
                    </p>
                    <div
                      className={`transaction-amount ${recurrenceSettings.class}`}
                    >
                      {transaction.type === "EXPENSE" ? "-" : ""}
                      {transaction.recurrentAmount}€
                    </div>
                  </td>
                  <td style={{textAlign: "center"}}>
                    <div
                      style={{
                        display: "flex",
                        gap: "0.6rem",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <span style={{cursor: "not-allowed"}}>
                        <EditIcon width="18px" color="var(--light-gray)" />
                      </span>
                      <span
                        style={{cursor: "pointer"}}
                        onClick={(e) => handleRecurrenceDelete(transaction)}
                      >
                        <TrashIcon width="20px" color="var(--light-gray)" />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {(!recurrentTransactions || recurrentTransactions.length === 0) && (
        <EmptyPanel
          icon={<IdeaIcon color={"var(--lighter-gray)"} width={"80px"} />}
          title={"You don't have any recurrence"}
          text={
            "Create your first recurrence transaction and it will appear here"
          }
          color="var(--lighter-gray)"
          maxWidth="400px"
          padding="2rem"
        />
      )}
    </div>
  )
}
