import {useState} from "react"
import {useAuth} from "../../contexts/AuthContext"
import {TextInput} from "../inputs/TextInput"
import SecondaryButton from "../buttons/SecondaryButton"
import toast from "react-hot-toast"

export default function UpdateProfileInfo() {
  const {currentUser, updateUser} = useAuth()
  const [updatedName, setUpdatedName] = useState(currentUser.name)
  const [updatedSurname, setUpdatedSurname] = useState(currentUser.surname)
  const [updatedPhone, setUpdatedPhone] = useState(currentUser.phone)
  const [hasChange, setHasChange] = useState(false)

  const handleChange = (e) => {
    setHasChange(true)
    switch (e.target.id) {
      case "update_profile_name":
        setUpdatedName(e.target.value)
        break
      case "update_profile_lastame":
        setUpdatedSurname(e.target.value)
        break
      case "update_profile_phone":
        setUpdatedPhone(e.target.value)
        break
    }
  }

  const onSend = async (e) => {
    e.preventDefault()
    if (!hasChange) return

    const user = {
      uid: currentUser.uid,
      username: currentUser.username,
      email: currentUser.email,
      name: updatedName,
      surname: updatedSurname,
      phone: updatedPhone,
    }

    if (!validateUserChanges(user)) {
      return
    }

    const updatedUser = await updateUser(user)
    if (updatedUser) {
      toast.success("User updated")
      setHasChange(false)
    } else {
      toast.error("Could not update user")
    }
  }

  return (
    <form onSubmit={onSend}>
      <div className="form-row" style={{gap: "1rem"}}>
        <TextInput
          inputId={"update_profile_name"}
          inputName={"Name"}
          inputPlaceholder={"Name"}
          inputValue={updatedName}
          inputLabel={"First name"}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={handleChange}
        />

        <TextInput
          inputId={"update_profile_lastame"}
          inputName={"Lastname"}
          inputPlaceholder={"Last name"}
          inputValue={updatedSurname}
          inputLabel={"Last name"}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={handleChange}
        />

        <TextInput
          inputId={"update_profile_phone"}
          inputName={"Phone"}
          inputPlaceholder={"Phone"}
          inputValue={updatedPhone}
          inputLabel={"Phone"}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={handleChange}
        />
      </div>

      {hasChange && (
        <SecondaryButton
          buttonText={"Update profile data"}
          textColor="var(--primary)"
          borderRadius="12px"
          fontWeight="300"
          borderWidth="1px"
          type="submit"
        />
      )}
    </form>
  )
}

function validateUserChanges(user) {
  if (user.name.length < 2) {
    toast.error("Name must be at least 2 characters long")
    return false
  }

  if (user.surname.length < 2) {
    toast.error("Surname must be at least 2 characters long")
    return false
  }

  return true
}
