import BudgetsPage from "./../../../static/resources/images/onboarding/15_budgets_page.png"

export default function BudgetsStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Budgets</h2>
      </div>
      <div className="step-content">
        <p>
          Accounts, categories, transactions or even recurrent transactions are all individual pieces of the puzzle. But what about the big picture?
          When organizing your finances, it's important to have a clear view of your financial budgets. From the transactions page it might be a
          bit hard to group all the transactions related to a specific expense category and operate with them. That's where the budgets come in.
        </p>
        <p>
          Let's go to the Budgets page by clicking in the <i>Add</i> button in the Dashboard's budgets section or by clicking
          the <i>Budgets</i> button in the menu.
        </p>
        <img src={BudgetsPage} alt="Budgets Page" />
      </div>
    </div>
  )
}
