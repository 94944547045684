import {useEffect, useState} from "react"
import {useKPIs} from "../../../contexts/KpisContext"
import KpiAreaChart from "../KpiAreaChart"

export default function DailyActiveUsers() {
  const {dailyActiveUsers} = useKPIs()
  const [todayActiveUsers, setTodayActiveUsers] = useState()
  const [yesterdayActiveUsers, setYesterdayActiveUsers] = useState()
  const [data, setData] = useState()
  const [dataLabels, setDataLabels] = useState()

  useEffect(() => {
    if (dailyActiveUsers && dailyActiveUsers.length > 0) {
      setTodayActiveUsers(dailyActiveUsers[0].active_users)
      setYesterdayActiveUsers(dailyActiveUsers[1].active_users)
      const dataArray = dailyActiveUsers
        .map((transaction) => transaction.active_users)
        .reverse()

      setData(dataArray)

      const dataLabelsArray = dailyActiveUsers
        .map((transaction) => transaction.date)
        .reverse()

      setDataLabels(dataLabelsArray)
    }
  }, [dailyActiveUsers])

  return (
    <div className="kpi">
      <div className="kpi-header">
        <p className="kpi-title">Daily active users</p>

        <div className="today-kpi-value">
          <h3>{todayActiveUsers}</h3>
          <p>{todayActiveUsers - yesterdayActiveUsers}</p>
        </div>
      </div>

      <KpiAreaChart
        data={data}
        dataLabels={dataLabels}
        datasetLabel={"Active users"}
      />
    </div>
  )
}
