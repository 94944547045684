import {useState} from "react"
import {FormRow, SubmitButton} from "../../forms/FormUtils"
import CloseIcon from "../../icons/CloseIcon"
import DescriptionIcon from "../../icons/DescriptionIcon"
import {TextInput} from "../../inputs/TextInput"
import {
  TransactionAccountInput,
  TransactionAmountInput,
  TransactionCategoryInput,
  TransactionTypeInput,
} from "../../inputs/TransationInputs"
import {useTransactionContext} from "../../../contexts/TransactionContext"
import {useAccounts} from "../../../contexts/AccountContext"
import {DateInput} from "../../inputs/DateInput"
import {useAuth} from "../../../contexts/AuthContext"
import useRecurrenceContext from "../../../contexts/RecurrentTransactionContext"
import toast from "react-hot-toast"

export default function CreateRecurrentTransactionForm({hideForm}) {
  const {categories} = useTransactionContext()
  const {accounts} = useAccounts()
  const {currentUser} = useAuth()
  const {createRecurrentTransaction} = useRecurrenceContext()

  const [showDescription, setShowDescription] = useState(false)
  const [transName, setTransName] = useState()
  const [transDescription, setTransDescription] = useState()
  const [transValue, setTransValue] = useState()
  const [transType, setTransType] = useState("EXPENSE")
  const [transCategory, setTransCategory] = useState()
  const [transAccount, setTransAccount] = useState()
  const [transRecurrenceType, setTransRecurrenceType] = useState("MONTHLY")
  const [transExecutionDate, setTransExecutionDate] = useState()

  const handleChange = (e) => {
    switch (e.target.id) {
      case "transaction_name":
        setTransName(e.target.value)
        break
      case "transaction_description":
        setTransDescription(e.target.value)
        break
      case "transaction_type":
        setTransType(e.target.value)
        break
      case "transaction_amount":
        setTransValue(e.target.value)
        break
      case "transaction_category":
        setTransCategory(e.target.value)
        break

      case "transaction_account":
        setTransAccount(e.target.value)
        break

      case "recurrence_type":
        setTransRecurrenceType(e.target.value)
        break

      case "execution_date":
        setTransExecutionDate(e.target.value)
        break
    }
  }

  const sendRecurrentTransaction = async (e) => {
    const transactionBody = {
      name: transName,
      description: transDescription,
      type: transType,
      recurrentAmount: transValue,
      date: transExecutionDate,
      userID: currentUser.uid,
      currency: "EUR",
      accountID: transAccount,
      categoryID: transCategory,
      recurrenceType: transRecurrenceType,
      executionDate: transExecutionDate,
    }

    const recurrentTransaction = await createRecurrentTransaction(
      transactionBody
    )

    if (recurrentTransaction) {
      toast.success("Recurrent transaction created")
    }
  }

  return (
    <form className="create-transaction-form">
      <FormRow>
        <TextInput
          labelText={"Name"}
          inputId={"transaction_name"}
          inputName={"Name"}
          inputPlaceholder={"Recurrent transaction name"}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={handleChange}
        />
        <span
          style={{cursor: "pointer"}}
          onClick={() => {
            setShowDescription(!showDescription)
          }}
        >
          {!showDescription ? (
            <DescriptionIcon color={"#334050"} />
          ) : (
            <CloseIcon isFilled={false} color={"#334050"} />
          )}
        </span>
      </FormRow>

      {showDescription && (
        <TextInput
          labelText={"Description"}
          inputId={"transaction_description"}
          inputName={"Description"}
          inputPlaceholder={"Description"}
          isRequired={false}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={handleChange}
        />
      )}

      <FormRow>
        <TransactionTypeInput
          onChange={handleChange}
          selectedType={transType}
        />
        <TransactionAmountInput
          onChange={handleChange}
          inputPlaceholder="Recurrent amount"
        />
      </FormRow>
      <FormRow marginTop={"-8px"}>
        {transType != "TRANSFER" && (
          <TransactionCategoryInput
            inputId={"transaction_category"}
            inputLabel={"Category"}
            showLabel={true}
            categories={categories}
            onChange={handleChange}
            categoryType={transType}
            selectedCategory={transCategory}
          />
        )}
        <TransactionAccountInput
          inputId={"transaction_account"}
          inputLabel={transType == "TRANSFER" ? "To" : "Account"}
          showLabel={true}
          accounts={accounts}
          onChange={handleChange}
          selectedAccountId={transAccount}
        />
      </FormRow>
      <FormRow>
        <RecurrenceTypeInput
          inputLabel={"Recurrence"}
          onChange={handleChange}
          selectedType={transRecurrenceType}
        />
        <DateInput
          onChange={handleChange}
          inputValue={transExecutionDate}
          inputID="execution_date"
          inputLabel="Execution date"
        />
      </FormRow>
      <SubmitButton
        buttonText={"Add recurrence"}
        onSend={sendRecurrentTransaction}
        transType={transType}
      />
    </form>
  )
}

const RecurrenceTypeInput = ({onChange, selectedType, inputLabel}) => {
  return (
    <div className="input-container">
      {inputLabel && <label htmlFor="recurrence_type">{inputLabel}</label>}
      <select
        style={{cursor: "pointer"}}
        id="recurrence_type"
        name="Recurrence type"
        onChange={onChange}
        defaultValue={selectedType}
      >
        <option disabled={true} value="WEEKLY">
          Weekly
        </option>
        <option value="MONTHLY">Monthly</option>
        <option disabled={true} value="YEARLY">
          Yearly
        </option>
      </select>
    </div>
  )
}
