import {useEffect, useState} from "react"

export default function OnScrollText({
  text,
  initialColor,
  accentColor,
  textAlign = "center",
  effectStartIndex = 0,
  intialThreshold = 100,
  threshold = 50,
  textClass = "",
  verticalPadding = "0px",
}) {
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      setScrollPosition(position)
    }
    window.addEventListener("scroll", handleScroll, {passive: true})

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const calculateColor = (index) => {
    if (index < effectStartIndex) return accentColor

    if (scrollPosition > intialThreshold + index * threshold) return accentColor

    return initialColor
  }

  const words = text.split(" ")

  return (
    <div
      className={textClass}
      style={{
        paddingBottom: verticalPadding,
        paddingTop: verticalPadding,
        marginBottom: "3rem",
      }}
    >
      {words.map((word, index) => (
        <span key={index} style={{color: calculateColor(index)}}>
          {index != 0 ? " " : ""}
          {word}
        </span>
      ))}
    </div>
  )
}
