import DashboardMockup from "./../../../static/resources/images/home/dashboard_mockup.png"

export default function Onboarding_GoodLuck() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Try it out!</h2>
      </div>
      <div className="step-content">
        <p>
          It's time for you to explore Finanzia. But don't worry, if you get
          lost you'll always have access to the knowledge base. You can check it
          out by clicking on the <i> ?</i> icon that appears besides the{" "}
          <i>Dashboard</i> title.
        </p>
        <img src={DashboardMockup} />
      </div>
    </div>
  )
}
