import {currentTime} from "../../utils/transactionUtils"

const TimeInput = ({
  inputID = "time_input",
  inputValue,
  hasDefaultValue = false,
  onChange,
}) => {
  const formattedTime =
    inputValue && inputValue.length > 5 ? inputValue.slice(0, 5) : inputValue
  return (
    <div className="input-container">
      <input
        id={inputID}
        type="time"
        defaultValue={hasDefaultValue ? currentTime() : formattedTime}
        onChange={onChange}
      ></input>
    </div>
  )
}

export {TimeInput}
