import MonthlyOverview from "./../../../static/resources/images/onboarding/13_monthly_overview.png"

export default function MonthlyOverviewStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Monthly overview</h2>
      </div>
      <div className="step-content">
        <p>
          Below the financial summary you will find a more detailed overview of
          the evolution of your income and expenses over the last 12 months:
        </p>

        <img
          style={{
            maxHeight: "400px",
            width: "auto",
          }}
          src={MonthlyOverview}
          alt="Accounts tab"
        />
      </div>
    </div>
  )
}
