import AccountTab from "../../../static/resources/images/onboarding/2_account_tab.png"

export default function CreateAccountStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Creating an account</h2>
      </div>
      <div className="step-content">
        <p>
          {" "}
          As you navigate to the accounts tab, you will be greeted with an
          account creation form. You will be ablte to choose:
        </p>

        <ul>
          <li> The account name</li>
          <li> The account's initial balance</li>
          <li> A description for the account</li>
        </ul>
        <img
          style={{maxHeight: "350px", width: "auto"}}
          src={AccountTab}
          alt="Accounts tab"
        />
        <p>
          Then click on the <i>Create account</i> button and your acount will be
          ready to be used.
        </p>
      </div>
    </div>
  )
}
