import "./EmptyPanel.css"

export default function EmptyPanel({
  icon,
  text,
  title,
  color = "var(--light-gray)",
  textAlign = "center",
  padding = "1rem",
  cta = null,
  additionalClasses = "",
  maxWidth = "100%",
  margin = "auto",
}) {
  return (
    <div
      className={`empty-section ${additionalClasses}`}
      style={{
        color: color,
        textAlign: textAlign,
        padding: padding,
        maxWidth: maxWidth,
        margin: margin,
      }}
    >
      {icon && <div className="empty-section-icon">{icon}</div>}
      <div className="empty-section-text">
        <h4>{title}</h4>
        <p>{text}</p>
      </div>

      {cta && <div className="empty-section-cta">{cta}</div>}
    </div>
  )
}
