import {initializeApp} from "firebase/app"
import {getAuth, GoogleAuthProvider} from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAzA-sVyDnxg20Rb9K7dB0DNc0MxL1QgPo",
  authDomain: "finanzia-4c0d8.firebaseapp.com",
  projectId: "finanzia-4c0d8",
  storageBucket: "finanzia-4c0d8.appspot.com",
  messagingSenderId: "679473367443",
  appId: "1:679473367443:web:01efa2c37f732854378066",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const googleProvider = new GoogleAuthProvider()

export {auth, googleProvider}
