import {useState} from "react"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {FormRow, SubmitButton} from "../forms/FormUtils"
import {TextInput} from "../inputs/TextInput"
import {useAuth} from "../../contexts/AuthContext"
import {CategoryList} from "./CategoryGroups"
import toast from "react-hot-toast"
import CloseIcon from "../icons/CloseIcon"
import {usePopup} from "../../contexts/PopupContext"
import {useResponsive} from "../../contexts/ResponsiveContext"

function CreateCategoryForm({hideForm}) {
  const {categories, createCategory, getAllCategories} = useTransactionContext()
  const {currentUser} = useAuth()
  const {hidePopup} = usePopup()
  const {isPhone} = useResponsive()

  const [categoryName, setCategoryName] = useState()
  const [categoryType, setCategoryType] = useState("INCOME")
  const [categoryDescription, setCategoryDescription] = useState()

  const handleChange = (e) => {
    switch (e.target.id) {
      case "category_name":
        setCategoryName(e.target.value)
        break
      case "category_type":
        setCategoryType(e.target.value)
        break
      case "category_description":
        setCategoryDescription(e.target.value)
        break
    }
  }

  const sendCategory = async (e) => {
    const categoryBody = {
      name: categoryName,
      description: categoryDescription,
      currentBalance: 0,
      userID: currentUser.uid,
      type: categoryType,
    }
    const categoryCreated = await createCategory(categoryBody)

    // TODO: Error handling and status reporting
    if (categoryCreated) {
      toast.success("Category created")

      if (isPhone) {
        hidePopup()
      }

      getAllCategories(currentUser.uid)
    } else {
      toast.error("An error ocurred when creating the category")
    }
  }

  return (
    <section className="container">
      <div style={{display: "flex", gap: "1rem", alignItems: "flex-end"}}>
        <h2>Create category</h2>
      </div>
      <form className="create-category-form">
        <FormRow>
          <TextInput
            inputId={"category_name"}
            inputName={"Category name"}
            inputPlaceholder={"Name"}
            additionalClasses={"input-container"}
            textAlign="center"
            onChange={handleChange}
          />
          <CategoryTypeSelector onChange={handleChange} />
        </FormRow>
        <TextInput
          inputId={"category_description"}
          inputName={"Category description"}
          inputPlaceholder={"Description"}
          additionalClasses={"input-container"}
          textAlign={"center"}
          onChange={handleChange}
        />
        <SubmitButton buttonText={"Create category"} onSend={sendCategory} />
      </form>
      <CategoryList title={"Category summary"} />
    </section>
  )
}

function CategoryTypeSelector({onChange, selectedType, isDisabled = false}) {
  return (
    <div className="input-container">
      <select
        id="category_type"
        name="Category Name"
        onChange={onChange}
        defaultValue={selectedType}
        value={selectedType}
        disabled={isDisabled}
        style={{cursor: isDisabled ? "not-allowed" : "pointer"}}
      >
        <option value="INCOME">Income</option>
        <option value="EXPENSE">Expense</option>
      </select>
    </div>
  )
}

function EditCategoryForm({onCategoryEddited, category}) {
  const [categoryName, setCategoryName] = useState(category.name)
  const [categoryDescription, setCategoryDescription] = useState(
    category.description
  )
  const [categoryType, setCategoryType] = useState(category.type)

  const handleChange = (e) => {
    switch (e.target.id) {
      case "category_name":
        setCategoryName(e.target.value)
        break
      case "category_description":
        setCategoryDescription(e.target.value)
        break
      case "category_type":
        setCategoryType(e.target.value)
        break
    }
  }

  return (
    <section className="container">
      <form className="create-category-form">
        <FormRow>
          <TextInput
            inputId={"category_name"}
            inputName={"Category name"}
            inputPlaceholder={"Name"}
            additionalClasses={"input-container"}
            textAlign="center"
            onChange={handleChange}
            inputValue={categoryName}
          />
          <CategoryTypeSelector
            onChange={handleChange}
            selectedType={categoryType}
            isDisabled={true}
          />
        </FormRow>
        <TextInput
          inputId={"category_description"}
          inputName={"Category description"}
          inputPlaceholder={"Description"}
          additionalClasses={"input-container"}
          textAlign={"center"}
          onChange={handleChange}
          inputValue={categoryDescription}
        />
        <SubmitButton
          buttonText={"Edit category"}
          onSend={() =>
            onCategoryEddited(categoryName, categoryDescription, categoryType)
          }
        />
      </form>
    </section>
  )
}

export {CreateCategoryForm, EditCategoryForm}
