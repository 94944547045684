export function getTransactionSettings(transaction) {
  let className
  let typeName
  switch (transaction.type) {
    case "INCOME":
      className = "income"
      typeName = "Income"
      break
    case "EXPENSE":
      className = "expense"
      typeName = "Expense"
      break
    default:
      className = "transfer"
      typeName = "Transfer"
      break
  }

  const info = {
    class: className,
    displayName: typeName,
  }

  return info
}
