import {useAccounts} from "../../contexts/AccountContext"

export default function AccountGroupInput({
  inputId,
  inputName,
  inputValue,
  inputLabel,
  onChange,
  hasPlaceholder = true,
  defaultValue = null,
}) {
  const {accountGroups} = useAccounts()
  return (
    <div className="input-container">
      <label htmlFor={inputId}>{inputLabel}</label>
      <select
        id={inputId}
        name="Account group"
        value={inputValue}
        onChange={onChange}
        defaultValue={null}
      >
        {hasPlaceholder && (
          <option
            value=""
            disabled
            selected={inputValue === null || inputValue === ""}
            hidden
          >
            Select an account group
          </option>
        )}
        {accountGroups &&
          accountGroups.map((group) => {
            return (
              <option key={group.id} value={group.id}>
                {group.public_name}
              </option>
            )
          })}
      </select>
    </div>
  )
}
