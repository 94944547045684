import {UserCredential} from "firebase/auth"
import {IAuthRepo} from "../../infraestructure/firebase/authRepository"
import {Account} from "../../types"

export interface IAuthService {
  signIn(email: string, password: string): Promise<UserCredential>
  registerUser(email: string, password: string): Promise<UserCredential>
}

export class AuthService implements IAuthService {
  repo: IAuthRepo
  constructor(authRepository: IAuthRepo) {
    this.repo = authRepository
  }

  async signIn(email: string, password: string) {
    return await this.repo.signInWithEmailAndPassword(email, password)
  }

  async registerUser(email: string, password: string) {
    return await this.repo.createUserWithEmailAndPassword(email, password)
  }
}
