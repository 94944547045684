import React from 'react'
import { Line } from 'react-chartjs-2';
import '../budgets/Budgets.css'

export default function BudgetHistoryChart ({
    budgetColor,
    history
}) {
    const data = {
        labels: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
        datasets: [{
            backgroundColor: "#FFFFFF",
            borderColor: `${budgetColor}`,
            data: history,
            pointRadius: 0,
            borderWidth: 2
        }]
    };

    const optionObject = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            },
            scales: {
            x: {
                display: false,
                title: {
                    display: false
                }
            },
            y: {
                display: false,
                title: {
                    display: false
                }
            }
        }
    };

    return (
        <div className="history-budget-chart-container">
            <div className="history-budget-chart">
                <Line data={data} options={optionObject} />
            </div>
        </div>
    )
}
