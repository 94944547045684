import React, {useEffect, useState, useRef} from "react"
import {useSpring, animated} from "@react-spring/web"
import {useDrag} from "@use-gesture/react"
import useMeasure from "react-use-measure" // A hook for getting the dynamic height

const BottomSheet = ({children, isOpen, onClose}) => {
  const [{y, height}, api] = useSpring(() => ({y: 100, height: "auto"})) // Add height spring
  const [ref, {height: contentHeight}] = useMeasure() // Get the dynamic content height
  const [keyboardHeight, setKeyboardHeight] = useState(0)

  // Detect when the keyboard is shown/hidden
  useEffect(() => {
    const handleResize = () => {
      // You can compare the current window height to the initial height to determine if the keyboard is open
      const isKeyboardVisible = window.innerHeight < window.outerHeight
      setKeyboardHeight(
        isKeyboardVisible ? window.outerHeight - window.innerHeight : 0
      )
    }

    // Add event listeners for keyboard show/hide (works on mobile browsers)
    window.addEventListener("resize", handleResize)
    window.addEventListener("focusin", handleResize)
    window.addEventListener("focusout", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("focusin", handleResize)
      window.removeEventListener("focusout", handleResize)
    }
  }, [])

  // Update spring position and height when `isOpen` or content height changes
  useEffect(() => {
    if (isOpen) {
      api.start({y: 0, height: contentHeight - keyboardHeight}) // Adjust height based on keyboard height
    } else {
      api.start({y: 100}) // Close the sheet
    }
  }, [isOpen, contentHeight, keyboardHeight, api])

  const bind = useDrag(
    ({movement: [_, my], down, cancel}) => {
      if (my < -70) cancel()
      api.start({y: down ? my : 0, immediate: down})
      if (!down && my > 150) onClose()
    },
    {from: () => [0, y.get()], filterTaps: true, bounds: {top: 0}}
  )

  return (
    <div className="popup-container">
      <animated.div
        {...bind()}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          height: height.to((h) => h + 14 + "px"), // Animate height
          background: "white",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
          touchAction: "none",
          transform: y.to((y) => `translateY(${y / 4}%)`),
          zIndex: 100,
        }}
      >
        <span
          style={{
            minHeight: "5px",
            width: "15%",
            borderRadius: "8px",
            background: "#c5c5c5",
            content: "''",
            display: "block",
            margin: "auto",
            marginTop: "14px",
          }}
        ></span>
        <div ref={ref} style={{padding: "10px 20px 30px", paddingTop: "0px"}}>
          {children}
        </div>
      </animated.div>
    </div>
  )
}

export default BottomSheet
