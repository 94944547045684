import {useKPIs} from "../../contexts/KpisContext"
import DailyActiveUsers from "./growth/DailyActiveUsers"
import DailyNewUsers from "./growth/DailyNewUsers"
import DailyRecurrences from "./usage/DailyRecurrences"
import DailyTransactions from "./usage/DailyTransactions"

export default function GrowthKpis() {
  return (
    <section>
      <h2>Growth metrics</h2>
      <div className="kpi-row">
        <DailyActiveUsers />
        <DailyNewUsers />
      </div>
    </section>
  )
}
