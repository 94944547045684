import {useEffect, useState} from "react"
import {useFeatureContext} from "../../contexts/FeatureContext"
import {usePopup} from "../../contexts/PopupContext"

export default function FeatureRow({feature}) {
  const {showFeaturePopup} = usePopup()
  const showFeatureDetails = (e) => {
    showFeaturePopup(feature.slug)
  }
  return (
    <div onClick={showFeatureDetails} className="feature-row">
      <h3>{feature.metadata.title}</h3>

      <VotePercent featureMetadata={feature.metadata} slug={feature.slug} />
    </div>
  )
}

function VotePercent({featureMetadata, slug}) {
  const {voteTotals, getVoteTotals} = useFeatureContext()
  const [votePercent, setVotePercent] = useState(undefined)

  useEffect(() => {
    if (!voteTotals) {
      getVoteTotals()
      return
    }

    let totalVotes = 0
    let votesForFeature = 0

    voteTotals.forEach((voteTotal) => {
      if (voteTotal.voted_feature === slug) {
        votesForFeature = parseInt(voteTotal.votes)
      }
      totalVotes += parseInt(voteTotal.votes)
    })

    if (votesForFeature === 0) {
      setVotePercent(0)
    } else {
      setVotePercent(Math.round((votesForFeature / totalVotes) * 100))
    }
  }, [voteTotals, slug, getVoteTotals]) // Ensure getVoteTotals and slug are dependencies


  return (
    <div className="vote-percent">
      {votePercent !== undefined && <h4>{votePercent}%</h4>}
      <p>of votes</p>
    </div>
  )
}
