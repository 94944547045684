import "./Tabs.css"

export default function Tab({text, icon, tabKey, onClick, isActive}) {
  return (
    <div
      onClick={onClick}
      key={tabKey}
      className={isActive ? "tab active-tab" : "tab"}
    >
      {icon && <img src={icon} />}
      {text}
    </div>
  )
}
