import {useEffect} from "react"
import useRecurrenceContext from "../../contexts/RecurrentTransactionContext"
import InfoIcon from "../icons/InfoIcon"
import Insight, {InsightAccent} from "../insights/Insight"
import {
  getInsightColor,
  getInsightIcon,
  replacePlaceholdersWithData,
} from "../insights/InsightsUtils"
import IdeaIcon from "../icons/IdeaIcon"
import EmptyPanel from "../layout/EmptyPanel"

export default function RecurrenceInsights() {
  const {recurrenceInsights, getRecurrenceInsights} = useRecurrenceContext()

  useEffect(() => {
    if (!recurrenceInsights) getRecurrenceInsights()
  }, [recurrenceInsights])

  if (recurrenceInsights && recurrenceInsights.length === 0) {
    return (
      <div>
        <div className="breakdown-header">
          <h2 className="title"> Insights</h2>
        </div>

        <EmptyPanel
          icon={<IdeaIcon color={"var(--lighter-gray)"} width={"80px"} />}
          title={"No insights found"}
          text={
            "Make sure you have configured at least one recurrence and your insights will be calculated soon."
          }
          color="var(--lighter-gray)"
        />
      </div>
    )
  }
  return (
    <div>
      <div className="breakdown-header">
        <h2 className="title"> Insights</h2>
      </div>

      <div className="insights-container">
        {recurrenceInsights &&
          recurrenceInsights.map((insight, index) => (
            <Insight
              key={index}
              icon={getInsightIcon(insight.type)}
              text={<>{replacePlaceholdersWithData(insight)}</>}
            />
          ))}
      </div>
    </div>
  )
}
