import {useEffect, useState} from "react"
import {FormRow, SubmitButton} from "../forms/FormUtils"
import {DateInput} from "../inputs/DateInput"
import {NumberInput} from "../inputs/NumberInput"
import {TextInput} from "../inputs/TextInput"
import {TimeInput} from "../inputs/TimeInput"
import "./../forms/Forms.css"
import {
  TransactionAccountInput,
  TransactionAmountInput,
  TransactionCategoryInput,
  TransactionTypeInput,
} from "../inputs/TransationInputs"
import {
  currentDate,
  currentTime,
  toLocalDate,
  validateTransactionValue,
} from "../../utils/transactionUtils"
import {useAuth} from "../../contexts/AuthContext"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useResponsive} from "../../contexts/ResponsiveContext"
import {usePopup} from "../../contexts/PopupContext"
import toast from "react-hot-toast"
import DescriptionIcon from "../icons/DescriptionIcon"
import RecurrentIcon from "../icons/RecurrentIcon"
import OneTimeIcon from "../icons/OneTimeIcon"
import CloseIcon from "../icons/CloseIcon"
import CreateRecurrentTransactionForm from "./forms/RecurrentTransactionForm"
import {useAccounts} from "../../contexts/AccountContext"
import PrimaryButton from "../buttons/PrimaryButton"
import {useNavigate} from "react-router"
import SecondaryButton from "../buttons/SecondaryButton"

function CreateTransactionForm({hideForm = null}) {
  const {currentUser} = useAuth()
  const {accounts} = useAccounts()
  const {createTransaction, getAllTransactions, categories} =
    useTransactionContext()
  const {isPhone} = useResponsive()
  const {hidePopup} = usePopup()

  const [transaction, setTransaction] = useState()
  const [transName, setTransName] = useState()
  const [transDescription, setTransDescription] = useState()
  const [transValue, setTransValue] = useState()
  const [transType, setTransType] = useState("EXPENSE")
  const [transDate, setTransDate] = useState(currentDate())
  const [transTime, setTransTime] = useState(currentTime())
  const [transAccount, setTransAccount] = useState()
  const [transFromAccount, setTransFromAccount] = useState(null)
  const [transCategory, setTransCategory] = useState(null)
  const [showDescription, setShowDescription] = useState(false)

  const [formTransactionType, setFormTransactionType] = useState("ONE_TIME")

  const navigate = useNavigate()

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setTransAccount(accounts[0].id)
      if (transType === "TRANSFER") {
        setTransFromAccount(accounts[0].id)
        // Hardcoded default category for transactions
      } else {
        setTransFromAccount(null)
      }
    }

    if (categories && categories.length > 0) {
      switch (transType) {
        case "TRANSFER":
          setTransCategory(1)
          break

        default:
          const defaultCategory = categories.find(
            (cat) => cat.type === transType
          )

          setTransCategory(defaultCategory.id)
          break
      }
    }
  }, [accounts, transType, categories])

  const updateTransName = (e) => {
    setTransName(e.target.value)
  }

  const updateTransDescription = (e) => {
    setTransDescription(e.target.value)
  }

  const updateTransValue = (e) => {
    if (!isNaN(e.target.value) || e.target.value === "") {
      // Only set the value without formatting here to allow user to continue typing
      setTransValue(e.target.value)
    }
  }

  const updateTransType = (e) => {
    setTransType(e.target.value)
  }

  const updateTransDate = (e) => {
    setTransDate(e.target.value)
  }

  const updateTransTime = (e) => {
    setTransTime(e.target.value)
  }

  const updateTransAccount = (e) => {
    setTransAccount(e.target.value)
  }

  const updateTransFromAccount = (e) => {
    setTransFromAccount(e.target.value)
  }

  const updateTransCategory = (e) => {
    setTransCategory(e.target.value)
  }

  const sendTransaction = async (e) => {
    if (!validateTransactionValue(transValue)) {
      toast.error("Invalid transaction value")
      return
    }
    const roundedValue = parseFloat(transValue).toFixed(2)
    const transactionBody = {
      name: transName,
      description: transDescription,
      type: transType,
      value: roundedValue,
      date: transDate,
      time: transTime,
      userID: currentUser.uid,
      currency: "EUR",
      accountID: transAccount,
      fromAccountID: transFromAccount,
      categoryID: transCategory,
    }

    const transactionCreated = await createTransaction(transactionBody)

    if (transactionCreated) {
      toast.success("Transaction created")
      setTransaction(transactionBody)
      setTransName("")
      setTransValue("")
      setTransDescription("")
      if (isPhone) {
        hidePopup()
      }
      getAllTransactions(currentUser.uid)
    } else {
      toast.error("Something went wrong. Try again please :)")
    }
  }
  if (accounts && categories) {
    return (
      <section className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div style={{display: "flex", gap: "0.8rem", alignItems: "flex-end"}}>
            <h2>
              {formTransactionType === "ONE_TIME"
                ? "Add transaction"
                : "Add recurrence"}
            </h2>
            <span
              style={{cursor: "pointer"}}
              onClick={() => {
                formTransactionType === "ONE_TIME"
                  ? setFormTransactionType("RECURRENT")
                  : setFormTransactionType("ONE_TIME")
              }}
            >
              {formTransactionType === "ONE_TIME" && (
                <RecurrentIcon width={"24px"} color={"#334050"} />
              )}
              {formTransactionType === "RECURRENT" && (
                <OneTimeIcon width={"22px"} color={"#334050"} />
              )}
            </span>
          </div>
          {hideForm && (
            <span onClick={hideForm} style={{cursor: "pointer"}}>
              <CloseIcon isFilled={false} width={"24px"} color={"#334050"} />
            </span>
          )}
        </div>
        {formTransactionType === "ONE_TIME" && (
          <form className="create-transaction-form">
            <FormRow>
              <TextInput
                labelText={"Name"}
                inputId={"transaction_name"}
                inputName={"Name"}
                inputPlaceholder={"Name"}
                additionalClasses={"input-container"}
                textAlign="center"
                onChange={updateTransName}
                inputValue={transName}
              />
              <span
                style={{cursor: "pointer"}}
                onClick={() => {
                  setShowDescription(!showDescription)
                }}
              >
                {!showDescription ? (
                  <DescriptionIcon color={"#334050"} />
                ) : (
                  <CloseIcon isFilled={false} color={"#334050"} />
                )}
              </span>
            </FormRow>

            {showDescription && (
              <TextInput
                labelText={"Description"}
                inputId={"transaction_description"}
                inputName={"Description"}
                inputPlaceholder={"Description"}
                isRequired={false}
                additionalClasses={"input-container"}
                textAlign="center"
                onChange={updateTransDescription}
                inputValue={transDescription}
              />
            )}

            <FormRow>
              <TransactionTypeInput
                onChange={updateTransType}
                selectedType={transType}
              />
              <TransactionAmountInput
                onChange={updateTransValue}
                inputValue={transValue}
              />
            </FormRow>
            <FormRow marginTop={"-8px"}>
              {transType === "TRANSFER" && (
                <TransactionAccountInput
                  inputId={"transaction_from_account"}
                  inputLabel={"From"}
                  showLabel={true}
                  accounts={accounts}
                  onChange={updateTransFromAccount}
                  selectedAccountId={transFromAccount}
                />
              )}
              {transType !== "TRANSFER" && (
                <TransactionCategoryInput
                  inputId={"transaction_category"}
                  inputLabel={"Category"}
                  showLabel={true}
                  categories={categories}
                  onChange={updateTransCategory}
                  categoryType={transType}
                  selectedCategory={transCategory}
                />
              )}

              <TransactionAccountInput
                inputId={"transaction_account"}
                inputLabel={transType === "TRANSFER" ? "To" : "Account"}
                showLabel={true}
                accounts={accounts}
                onChange={updateTransAccount}
                selectedAccountId={transAccount}
              />
            </FormRow>
            <FormRow>
              <DateInput inputValue={transDate} onChange={updateTransDate} />
              <TimeInput hasDefaultValue={true} onChange={updateTransTime} />
            </FormRow>
            <SubmitButton
              buttonText={"Create transaction"}
              onSend={sendTransaction}
              transType={transType}
            />
          </form>
        )}
        {formTransactionType === "RECURRENT" && (
          <CreateRecurrentTransactionForm />
        )}

        <span
          style={{
            display: "block",
            width: "40px",
            margin: "1rem auto",
            content: "''",
            height: "1px",
            backgroundColor: "var(--light-gray)",
          }}
        ></span>

        <SecondaryButton
          buttonText={"Upload from image"}
          onClick={() => {
            hidePopup()
            navigate("/app/transactions/bulk-upload")
          }}
          textColor="var(--primary)"
        />
      </section>
    )
  }
}

function EditTransactionForm({transaction, onTransactionEdited}) {
  const {currentUser} = useAuth()
  const {editTransaction, categories} = useTransactionContext()

  const {getAllAccounts, accounts} = useAccounts()

  const [transName, setTransName] = useState(transaction.name)
  const [transDescription, setTransDescription] = useState(
    transaction.description
  )
  const [transValue, setTransValue] = useState(transaction.value)
  const [transType, setTransType] = useState(transaction.type)
  const [transDate, setTransDate] = useState(toLocalDate(transaction.date))
  const [transTime, setTransTime] = useState(transaction.time)
  const [transAccount, setTransAccount] = useState(transaction.accountID)
  const [transFromAccount, setTransFromAccount] = useState(
    transaction.fromAccountID
  )
  const [transCategory, setTransCategory] = useState(transaction.categoryID)

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setTransAccount(transaction.accountID)
      if (transType === "TRANSFER") {
        setTransFromAccount(transaction.fromAccountID)
        // Hardcoded default category for transactions
      } else {
        setTransFromAccount(null)
      }
    }

    if (categories && categories.length > 0) {
      switch (transType) {
        case "TRANSFER":
          setTransCategory(1)
          break

        default:
          const defaultCategory = categories.find(
            (cat) => cat.type === transType
          )

          setTransCategory(transaction.categoryID)
          break
      }
    }
  }, [accounts, transType, categories])

  const handleChange = (e) => {
    switch (e.target.id) {
      case "transaction_name":
        setTransName(e.target.value)
        break

      case "transaction_description":
        setTransDescription(e.target.value)
        break

      case "transaction_type":
        setTransType(e.target.value)
        break

      case "transaction_amount":
        setTransValue(e.target.value)
        break

      case "transaction_from_account":
        setTransFromAccount(e.target.value)
        break

      case "transaction_category":
        setTransCategory(e.target.value)
        break

      case "transaction_account":
        setTransAccount(e.target.value)
        break

      case "transaction_date":
        setTransDate(e.target.value)
        break

      case "transaction_time":
        setTransTime(e.target.value)
        break
    }
  }

  const sendTransaction = async (e) => {
    const transactionBody = {
      name: transName,
      description: transDescription,
      type: transType,
      value: parseFloat(transValue).toFixed(2),
      date: transDate,
      time: transTime,
      userID: currentUser.uid,
      currency: "EUR",
      accountID: transAccount,
      fromAccountID: transFromAccount,
      categoryID: transCategory,
      id: transaction.id,
      createdAt: transaction.createdAt,
    }

    const editedTransaction = await editTransaction(transactionBody)

    if (editedTransaction) {
      onTransactionEdited(editedTransaction)
      getAllAccounts(editedTransaction.userID)
    }
  }

  return (
    <section className="container">
      <form className="create-transaction-form">
        <TextInput
          labelText={"Name"}
          inputId={"transaction_name"}
          inputName={"Transaction name"}
          inputPlaceholder={"Name"}
          additionalClasses={"input-container"}
          textAlign="center"
          inputValue={transName}
          onChange={handleChange}
        />

        <TextInput
          labelText={"Description"}
          inputId={"transaction_description"}
          inputName={"Transaction description"}
          inputPlaceholder={"Description"}
          isRequired={false}
          additionalClasses={"input-container"}
          inputValue={transDescription || undefined}
          textAlign="center"
          onChange={handleChange}
        />

        <FormRow>
          <TransactionTypeInput
            onChange={handleChange}
            selectedType={transType}
          />
          <TransactionAmountInput
            inputValue={transValue}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          {transType == "TRANSFER" && (
            <TransactionAccountInput
              inputId={"transaction_from_account"}
              inputLabel={"From"}
              showLabel={true}
              accounts={accounts}
              onChange={handleChange}
              selectedAccountId={transFromAccount}
            />
          )}
          {transType != "TRANSFER" && (
            <TransactionCategoryInput
              inputId={"transaction_category"}
              inputLabel={"Category"}
              showLabel={true}
              categories={categories}
              onChange={handleChange}
              categoryType={transType}
              selectedCategory={transCategory}
            />
          )}

          <TransactionAccountInput
            inputId={"transaction_account"}
            inputLabel={transType == "TRANSFER" ? "To" : "Account"}
            showLabel={true}
            accounts={accounts}
            onChange={handleChange}
            selectedAccountId={transAccount}
          />
        </FormRow>
        <FormRow>
          <DateInput
            inputID={"transaction_date"}
            onChange={handleChange}
            inputValue={transDate}
          />
          <TimeInput
            inputID={"transaction_time"}
            inputValue={transTime}
            onChange={handleChange}
          />
        </FormRow>
        <SubmitButton
          buttonText={"Edit transaction"}
          onSend={sendTransaction}
        />
      </form>
    </section>
  )
}

export {CreateTransactionForm, EditTransactionForm}
