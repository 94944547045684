const dateIsToday = (date) => {
  // If no date is provided, return false
  if (!date) {
    return false
  }

  let currentDate = new Date()

  currentDate.setHours(0, 0, 0, 0)
  date.setHours(0, 0, 0, 0)

  // Check if the dates are the same
  return date.getTime() === currentDate.getTime()
}

const dateWasYesterday = (date) => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  const comparisonDate = new Date(date)
  comparisonDate.setHours(0, 0, 0, 0)

  const dayDifference = (currentDate - comparisonDate) / (1000 * 60 * 60 * 24)

  return dayDifference === 1
}

const currentDate = () => {
  const today = new Date()
  const offset = today.getTimezoneOffset() * 60000 // offset in milliseconds
  const localISOTime = new Date(Date.now() - offset).toISOString().split("T")[0]
  return localISOTime
}

const currentDatePlusMonths = (months = 1) => {
  const today = new Date()
  const offset = today.getTimezoneOffset() * 60000 // offset in milliseconds
  const localISOTime = new Date(Date.now() - offset).toISOString().split("T")[0]
  const newDate = new Date(localISOTime)
  newDate.setMonth(newDate.getMonth() + months)
  return newDate.toISOString().split("T")[0]
}

const currentTime = () => {
  const now = new Date()
  const hours = now.getHours().toString().padStart(2, "0")
  const minutes = now.getMinutes().toString().padStart(2, "0")

  const formattedTime = `${hours}:${minutes}`

  return formattedTime
}

function formatDate(transactionDate) {
  const date = new Date(transactionDate)

  if (dateIsToday(date)) {
    return "Hoy"
  } else if (dateWasYesterday(date)) {
    return "Ayer"
  } else {
    return date.toLocaleDateString()
  }
}

function formatTime(time) {
  const [hours, minutes] = time.split(":")
  // Return the formatted time string without seconds
  return `${hours}:${minutes}`
}

function getTransactionSign(transactionType) {
  switch (transactionType) {
    case "INCOME": {
      return "+"
    }
    case "EXPENSE": {
      return "-"
    }
    case "TRANSFER": {
      return ""
    }
  }
}

function toLocalDate(utcDateString) {
  const date = new Date(utcDateString)
  const offset = date.getTimezoneOffset() * 60000
  // This will ensure that the date is not affected by the time part and the timezone conversion
  const adjustedDate = new Date(date.getTime() - offset)
  return adjustedDate.toISOString().split("T")[0]
}

function isNewMonth(transaction, previousTransaction) {
  // Extract the month and year from the transaction date
  const transactionDate = new Date(transaction.date)
  const transactionMonth = transactionDate.getMonth() // getMonth() returns month from 0-11
  const transactionYear = transactionDate.getFullYear()

  // Extract the month and year from the previous transaction date
  const previousTransactionDate = new Date(previousTransaction.date)
  const previousTransactionMonth = previousTransactionDate.getMonth()
  const previousTransactionYear = previousTransactionDate.getFullYear()

  // Check if the year or the month is different
  const newMonth =
    transactionMonth !== previousTransactionMonth ||
    transactionYear !== previousTransactionYear

  // Format the date as 'month-year'
  const formattedDate = `${transactionDate.toLocaleString("default", {
    month: "long",
  })} ${transactionYear}`

  // Return the object with the result and the formatted date
  return {
    newMonth: newMonth,
    date: formattedDate,
  }
}

function validateTransactionValue(value) {
  if (value === "") {
    return false
  }
  const parsedValue = parseFloat(value)
  if (isNaN(parsedValue)) {
    return false
  }
  return true
}

export {
  dateIsToday,
  dateWasYesterday,
  currentDate,
  currentDatePlusMonths,
  currentTime,
  formatDate,
  formatTime,
  getTransactionSign,
  toLocalDate,
  isNewMonth,
  validateTransactionValue,
}
