import React, {useEffect, useState} from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import {Bar} from "react-chartjs-2"
import {useStats} from "../../contexts/StatsContext"

export default function IncomeExpenses() {
  const {historicalData} = useStats()
  const [data, setData] = useState()
  const [labels, setLabels] = useState()

  useEffect(() => {
    if (
      historicalData &&
      historicalData.historic_income &&
      historicalData.historic_expenses
    ) {
      const income = historicalData.historic_income.map((value) =>
        parseFloat(value)
      )
      const expenses = historicalData.historic_expenses.map((value) =>
        parseFloat(value)
      )

      setData({
        income: income,
        expenses: expenses,
      })
      setLabels(historicalData.historic_labels)
    }
  }, [historicalData])

  return (
    <div>
      <div className="breakdown-header">
        <h2 className="title">Monthly overview</h2>
      </div>
      {data && labels && (
        <IncomeExpensesChart historicalData={data} historicalLabels={labels} />
      )}
    </div>
  )
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function IncomeExpensesChart({historicalData, historicalLabels}) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
      },
    },
  }

  const labels = [...historicalLabels]

  const data = {
    labels,
    datasets: [
      {
        label: "Income",
        data: historicalData.income,
        backgroundColor: "#02dca2",
        borderRadius: 10,
        borderSkipped: false,
        barPercentage: 0.7, // Adjust this value to control the width of the bars
        categoryPercentage: 0.6, // Adjust this value to control the spacing between bars
      },
      {
        label: "Expenses",
        data: historicalData.expenses,
        backgroundColor: "#334050",
        borderRadius: 10,
        borderSkipped: false,

        barPercentage: 0.7, // Adjust this value to control the width of the bars
        categoryPercentage: 0.6, // Adjust this value to control the spacing between bars
      },
    ],
  }

  return (
    <div style={{height: "300px"}}>
      <Bar options={options} data={data} height={50} />
    </div>
  )
}
