import {useAccounts} from "../../contexts/AccountContext"
import {usePopup} from "../../contexts/PopupContext"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useTransactionFilters} from "../../contexts/TransactionFiltersContext"
import IdeaIcon from "../icons/IdeaIcon"
import EmptyPanel from "../layout/EmptyPanel"
import AddTransactionPopup from "../popups/specific/AddNewTransaction"
import {TransactionDetails} from "./TransactionGroups"
import {getTransactionSettings} from "./transactionUtils"

export default function TransactionsTable() {
  const {accounts} = useAccounts()
  const {categories} = useTransactionContext()
  const {transactionFilters, filteredTransactions} = useTransactionFilters()
  const {showPopup} = usePopup()

  const handleRowclick = (transaction, account, category) => {
    showPopup({
      content: (
        <TransactionDetails
          transaction={transaction}
          account={account}
          category={category}
        />
      ),
    })
  }

  return (
    <div className="transaction-table">
      <table
        style={{
          width: "100%",
          height: "100%",
          borderCollapse: "collapse",
          borderSpacing: "0",
        }}
      >
        <thead className="table-header">
          <tr>
            <th>Name</th>
            <th>Account</th>
            <th>Category</th>
            <th>Date</th>
            <th style={{textAlign: "right"}}>Value</th>
          </tr>
        </thead>
        <tbody style={{rowGap: "20px"}}>
          {filteredTransactions.length > 0 &&
            filteredTransactions.map((transaction) => {
              const account = accounts.find(
                (acc) => acc.id == transaction.accountID
              )

              const fromAccount = accounts.find(
                (acc) => acc.id == transaction.fromAccountID
              )

              const category = categories.find(
                (cat) => cat.id == transaction.categoryID
              )

              const transactionSettings = getTransactionSettings(transaction)
              return (
                <tr
                  key={transaction.id}
                  style={{cursor: "pointer"}}
                  onClick={() => handleRowclick(transaction, account, category)}
                >
                  <td>
                    <p className="transaction-name">{transaction.name}</p>
                    <p className="transaction-description">
                      {transaction.description}
                    </p>
                  </td>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: `${
                        transaction.type === "TRANSFER" ? "0" : "10px"
                      }`,
                    }}
                  >
                    {account && account.name}
                    <span style={{fontSize: "0.8rem", fontWeight: "200"}}>
                      {transaction.type === "TRANSFER" &&
                        fromAccount &&
                        `from ${fromAccount.name}`}
                    </span>
                  </td>
                  <td>{category && category.name}</td>
                  <td>
                    {`${transaction.time.slice(0, 5)} · ${new Date(
                      transaction.date
                    ).toLocaleDateString()}`}
                  </td>
                  <td className="transaction-amount-container">
                    <p className="transaction-type">
                      {transactionSettings.displayName}
                    </p>
                    <div
                      className={`transaction-amount ${transactionSettings.class}`}
                    >
                      {transaction.type === "EXPENSE" ? "-" : ""}
                      {transaction.value}€
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {filteredTransactions && filteredTransactions.length === 0 && (
        <EmptyPanel
          icon={<IdeaIcon color={"var(--lighter-gray)"} width={"80px"} />}
          title={"You don't have transactions"}
          text={"It's time to create your first transaction"}
          color="var(--lighter-gray)"
          maxWidth="400px"
          padding="2rem"
          cta={<AddTransactionPopup buttonColor={"var(--light-gray)"} />}
        />
      )}
    </div>
  )
}
