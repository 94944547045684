import {useEffect, useState} from "react"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {FormRow, SubmitButton} from "../forms/FormUtils"
import {TextInput} from "../inputs/TextInput"
import {useAuth} from "../../contexts/AuthContext"
import {
  TransactionAccountInput,
  TransactionAmountInput,
} from "../inputs/TransationInputs"
import {currentDate, currentTime} from "../../utils/transactionUtils"
import {AcccountList} from "./AccountGroups"
import toast from "react-hot-toast"
import {usePopup} from "../../contexts/PopupContext"
import CloseIcon from "../icons/CloseIcon"
import {ClosePopupButton} from "../popups/SimplePopup"
import {useResponsive} from "../../contexts/ResponsiveContext"
import {useAccounts} from "../../contexts/AccountContext"
import AccountGroupInput from "../inputs/AccountGroupInput"

function CreateAccountForm({
  hideForm,
  hasAccountSummary = true,
  hasClosePopup = false,
}) {
  const {createTransaction, getAllTransactions} = useTransactionContext()

  const {createAccount, getAllAccounts, accountGroups} = useAccounts()
  const {currentUser} = useAuth()
  const {hidePopup} = usePopup()
  const {isPhone} = useResponsive()

  const [accountName, setAccountName] = useState()
  const [accountDescription, setAccountDescription] = useState("")
  const [accountBalance, setAccountBalance] = useState()
  const [accountGroup, setAccountGroup] = useState(null)

  const handleChange = (e) => {
    switch (e.target.id) {
      case "account_name":
        setAccountName(e.target.value)
        break

      case "account_description":
        setAccountDescription(e.target.value)
        break

      case "initial_balance":
        if (isNaN(e.target.value) || e.target.value === "") break
        setAccountBalance(e.target.value)
        break

      case "account_group":
        setAccountGroup(e.target.value)
        break
    }
  }

  const sendAccount = async (e) => {
    if (!accountGroup) {
      toast.error("Please select an account group")
      return
    }

    const formattedAccountBalance = parseFloat(accountBalance).toFixed(2)

    const accountBody = {
      name: accountName,
      description: accountDescription,
      groupID: accountGroup,
      currentBalance: 0,
      userID: currentUser.uid,
    }

    const accountCreated = await createAccount(accountBody)

    if (accountCreated) {
      if (formattedAccountBalance != 0) {
        await createInitialTransaction(accountCreated)
      }
      getAllAccounts(currentUser.uid)
      toast.success("Account created")

      setAccountName("")
      setAccountDescription("")
      setAccountBalance("")
      setAccountGroup(null)

      if (isPhone) {
        hidePopup()
      }
    } else {
      toast.error("Something went wrong. Try again please :)")
    }
  }

  const createInitialTransaction = async (newAccount) => {
    let type
    let amount

    const formattedAccountBalance = parseFloat(accountBalance).toFixed(2)

    if (accountBalance > 0) {
      type = "INCOME"
      amount = formattedAccountBalance
    } else {
      type = "EXPENSE"
      amount = -1 * formattedAccountBalance
    }

    const transactionBody = {
      name: "Initial balance",
      description: "",
      type: type,
      value: amount,
      date: currentDate(),
      time: currentTime(),
      userID: currentUser.uid,
      currency: "EUR",
      accountID: newAccount.id,
      fromAccountID: null,
      categoryID: 1,
    }

    const initialTransaction = await createTransaction(transactionBody)

    if (initialTransaction) {
      getAllTransactions(currentUser.uid)
    }
  }

  return (
    <section className="container account-action-container">
      <div style={{display: "flex", gap: "1rem", alignItems: "flex-end"}}>
        <h2>Create account</h2>

        {hasClosePopup && <ClosePopupButton />}
      </div>

      <form className="create-account-form">
        <FormRow>
          <TextInput
            inputId={"account_name"}
            inputName={"Name"}
            inputPlaceholder={"Name"}
            additionalClasses={"input-container"}
            textAlign="center"
            inputValue={accountName}
            onChange={handleChange}
          />
          <TransactionAmountInput
            inputId={"initial_balance"}
            onChange={handleChange}
            inputPlaceholder={"Initial balance"}
            inputValue={accountBalance}
          />
        </FormRow>
        <AccountGroupInput
          onChange={handleChange}
          inputId={"account_group"}
          inputValue={accountGroup}
        />
        <TextInput
          inputId={"account_description"}
          inputName={"Description"}
          inputPlaceholder={"Description"}
          additionalClasses={"input-container"}
          inputValue={accountDescription}
          textAlign="center"
          onChange={handleChange}
        />
        <SubmitButton buttonText={"Create account"} onSend={sendAccount} />
      </form>

      {hasAccountSummary && <AcccountList title={"Accounts summary"} />}
    </section>
  )
}

const EditAccountForm = ({account, onAccountEdited}) => {
  const [accountName, setAccountName] = useState(account.name)
  const [accountDescription, setAccountDescription] = useState(
    account.description
  )
  const [accountGroup, setAccountGroup] = useState(account.groupID)

  const handleChange = (e) => {
    switch (e.target.id) {
      case "account_name":
        setAccountName(e.target.value)
        break

      case "account_description":
        setAccountDescription(e.target.value)
        break

      case "account_group":
        setAccountGroup(e.target.value)
        break
    }
  }

  return (
    <section className="container">
      <form className="create-account-form">
        <FormRow>
          <TextInput
            inputId={"account_name"}
            inputName={"Account name"}
            inputPlaceholder={"Name"}
            additionalClasses={"input-container"}
            textAlign="center"
            onChange={handleChange}
            inputValue={accountName}
            inputLabel={"Account name"}
          />
        </FormRow>
        <AccountGroupInput
          onChange={handleChange}
          inputId={"account_group"}
          inputValue={accountGroup}
          defaultValue={account.groupID}
          hasPlaceholder={false}
          inputLabel={"Account group"}
        />

        <TextInput
          inputId={"account_description"}
          inputName={"Account description"}
          inputPlaceholder={"Description"}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={handleChange}
          inputValue={accountDescription}
          inputLabel={"Account description"}
        />
        <SubmitButton
          buttonText={"Edit account"}
          onSend={() =>
            onAccountEdited(accountName, accountDescription, accountGroup)
          }
        />
      </form>
    </section>
  )
}

export {CreateAccountForm, EditAccountForm}
