import axios from "axios"
import {createContext, useContext, useState} from "react"
import {apiClient} from "../infraestructure/api/client"

const KpiContext = createContext()

export function useKPIs() {
  return useContext(KpiContext)
}

export function KpiProvider({children}) {
  const [dailyTransactions, setDailyTransactions] = useState([])
  const [dailyRecurrences, setDailyRecurrences] = useState([])
  const [dailyBudgets, setDailyBudgets] = useState([])
  const [dailyActiveUsers, setDailyActiveUsers] = useState([])
  const [dailyNewUsers, setDailyNewUsers] = useState([])
  const [lastActiveUsers, setLastActiveUsers] = useState([])

  // Usage metrics
  const getDailyTransactions = async () => {
    try {
      const response = await apiClient.get("/admin/kpi/daily-transactions")
      setDailyTransactions(response.daily_transactions)
    } catch (error) {
      console.log("Error retrieving daily transactions:", error)
      return false
    }
  }

  const getDailyRecurrences = async () => {
    try {
      const response = await apiClient.get("/admin/kpi/daily-recurrences")
      setDailyRecurrences(response.daily_recurrences)
    } catch (error) {
      console.log("Error retrieving daily recurrences:", error)
      return false
    }
  }

  const getDailyBudgets = async () => {
    try {
      const response = await apiClient.get("/admin/kpi/daily-budgets")
      setDailyBudgets(response.daily_budgets)
    } catch (error) {
      console.log("Error retrieving daily budgets:", error)
      return false
    }
  }

  // Growth metrics
  const getDailyActiveUsers = async () => {
    try {
      const response = await apiClient.get("/admin/kpi/daily-users")

      setDailyActiveUsers(response.daily_active_users)
    } catch (error) {
      console.log("Error retrieving daily active users:", error)
      return false
    }
  }

  const getDailyNewUsers = async () => {
    try {
      const response = await apiClient.get("/admin/kpi/new-users")
      setDailyNewUsers(response.daily_new_users)
    } catch (error) {
      console.log("Error retrieving daily new users:", error)
      return false
    }
  }

  const getLastActiveUsers = async () => {
    try {
      const response = await apiClient.get("/admin/kpi/active-users")
      setLastActiveUsers(response.last_active_users)
    } catch (error) {
      console.log("Error retrieving last active users:", error)
      return false
    }
  }

  const getKPIs = async () => {
    await Promise.all([
      getDailyTransactions(),
      getDailyRecurrences(),
      getDailyBudgets(),
      getDailyActiveUsers(),
      getDailyNewUsers(),
      getLastActiveUsers(),
    ])
  }

  const value = {
    getKPIs,
    getDailyTransactions,
    getDailyRecurrences,
    getDailyBudgets,
    getDailyActiveUsers,
    getDailyNewUsers,
    dailyTransactions,
    dailyRecurrences,
    dailyBudgets,
    dailyActiveUsers,
    dailyNewUsers,
    lastActiveUsers,
  }
  return <KpiContext.Provider value={value}>{children}</KpiContext.Provider>
}
