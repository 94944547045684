import {currentDate, toLocalDate} from "../../utils/transactionUtils"
import "./Inputs.css"

const DateInput = ({
  inputID = "date_input",
  hasDefaultValue = false,
  inputValue,
  onChange,
  inputLabel = "",
}) => {
  const valueToShow = hasDefaultValue ? currentDate() : inputValue
  return (
    <div className="date-input input-container">
      {inputLabel && <label htmlFor={inputID}>{inputLabel}</label>}
      <div className="input-container">
        <input
          id={inputID}
          type="date"
          value={valueToShow}
          onChange={onChange}
        ></input>
      </div>
    </div>
  )
}

export {DateInput}
