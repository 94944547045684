import {createContext, useContext, useEffect, useState} from "react"
import {useAuth} from "./AuthContext"
import {useTransactionContext} from "./TransactionContext"
import axios from "axios"
import {apiClient} from "../infraestructure/api/client"

const StatsContext = createContext()

export function useStats() {
  return useContext(StatsContext)
}

export function StatsProvider({children}) {
  const {currentUser} = useAuth()
  const {transactions} = useTransactionContext()
  const [financialSummary, setFinancialSummary] = useState()
  const [historicalData, setHistoricaData] = useState()
  const [selectedRange, setSelectedRange] = useState([])
  const [selectedAccount, setSelectedAccount] = useState()
  const [isFetching, setIsFetching] = useState(false) // State to track if fetching is in progress

  const getFinancialSummary = async (accountID = null) => {
    if (isFetching) return // Early return if already fetching
    setIsFetching(true) // Set fetching to true

    const query = {
      userID: currentUser.uid,
      startDate: selectedRange[0],
      endDate: selectedRange[1],
      accountID: selectedAccount,
    }
    try {
      const response = await apiClient.get("/dashboard/summary", {
        params: query,
      })
      setFinancialSummary(response)
    } catch (error) {
      console.log("ERROR", error)
    } finally {
      setIsFetching(false)
    }
  }

  const getCategoryBreakdown = async (categoryType) => {
    const query = {
      categoryType: categoryType,
      userID: currentUser.uid,
      startDate: selectedRange[0],
      endDate: selectedRange[1],
    }

    try {
      const response = await apiClient.get("/dashboard/catBreakdown", {
        params: query,
      })
      return response
    } catch (error) {
      console.log("ERROR", error)
      return false
    }
  }

  const getHitoricalData = async (accountID = null) => {
    if (isFetching) return // Early return if already fetching
    setIsFetching(true) // Set fetching to true
    if (!selectedRange[0]) return
    const query = {
      userID: currentUser.uid,
      startDate: selectedRange[0],
      endDate: selectedRange[1],
      accountID: selectedAccount,
    }
    try {
      const response = await apiClient.get("/dashboard/historical", {
        params: query,
      })
      setHistoricaData(response)
    } catch (error) {
      console.log("ERROR", error)
    } finally {
      setIsFetching(false) // Reset fetching state
    }
  }

  useEffect(() => {
    if (!transactions || !currentUser) return
    const fetchFinancialSummary = async () => {
      if (currentUser?.uid && selectedRange[0]) {
        await getFinancialSummary()
      }
    }

    const fetchHistoricalData = async () => {
      if (currentUser?.uid && selectedRange[0]) {
        await getHitoricalData()
      }
    }

    fetchFinancialSummary()
    fetchHistoricalData()
  }, [transactions, selectedRange, selectedAccount, currentUser]) // Dependencies array

  const value = {
    setSelectedRange,
    getFinancialSummary,
    setSelectedAccount,
    getCategoryBreakdown,
    financialSummary,
    historicalData,
    selectedRange,
  }
  return <StatsContext.Provider value={value}>{children}</StatsContext.Provider>
}
