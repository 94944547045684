export default function Onboarding_HowItWorks() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>How it works?</h2>
      </div>
      <div className="step-content">
        <p>
          Understanding Finanzia is easy. Here's what you should know to get
          started:
        </p>
        <h3> Accounts </h3>
        <p>
          An account is a container that represents some part of your finance.
          For example:{" "}
        </p>{" "}
        <ul>
          <li>A bank account</li>
          <li> A savings account</li>
          <li> Index funds or ETFs</li>
        </ul>
        <p>
          Each account can have multiple transactions, from many different
          categories. But first, you should start by configuring your accounts.
        </p>
        <h3> Categories </h3>
        <p>
          Categories allow you to group transactions from multiple accounts into
          a common group based on its nature. In FINANZIA there are two types of
          categories:
        </p>
        <ul>
          <li>Income categories</li>
          <li>Expense categories</li>
        </ul>
        <h3> Transactions </h3>
        <p>
          Transactions are the backbone of FINANZIA, where everything starts.
          The idea is that you record a transaction for every:{" "}
        </p>
        <ul>
          <li>Income</li>
          <li>Expense</li>
          <li>Transfer</li>
        </ul>
        <p>
          This way, you will be able to effectively track your finances and get
          precise analytics. After setting up your account and categories, try
          to create you first transactions and let us know!
        </p>
      </div>
    </div>
  )
}
