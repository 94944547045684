import {useNavigate} from "react-router"
import SecondaryButton from "../buttons/SecondaryButton"
import {MenuItem, MenuLink} from "./NavigationComponents"
import PrimaryButton from "../buttons/PrimaryButton"
import HamburgerMenu from "./HamburgerMenu"
import {useResponsive} from "../../contexts/ResponsiveContext"
import {useNavigationContext} from "../../contexts/NavigationContext"
import {useAuth} from "../../contexts/AuthContext"

export default function TopMenu() {
  const {isHamburgerMenuOpen, toggleHamburgerMenu} = useNavigationContext()
  const navigate = useNavigate()
  const {isPhone} = useResponsive()
  return (
    <nav
      className={`top-menu-container ${
        isHamburgerMenuOpen ? "is-hamburger" : ""
      }`}
    >
      <div className="top-menu">
        <div className="top-menu-left">
          <div
            className="logo"
            style={{cursor: "pointer"}}
            onClick={() => {
              navigate("/")
              
            }}
          >
            <h1>
              FINANZ
              <span style={{color: "var(--primary-green)", fontWeight: "700"}}>
                IA
              </span>
            </h1>
          </div>
        </div>
        {!isPhone && (
          <div className="top-menu-right">
            <MenuLink
              text={"Blog"}
              toLink={"/blog"}
              onClick={() => {
                navigate("/blog")
              }}
            />
            <SecondaryButton
              buttonText={"Log in"}
              textColor={"var(--primary-green)"}
              onClick={() => {
                navigate("/cuenta/iniciar-sesion")
              }}
              borderRadius="20px"
            />

            <PrimaryButton
              buttonText={"Create account"}
              onClick={() => navigate("/cuenta/register")}
              borderWidth={"2px"}
              borderRadius={"20px"}
            />
          </div>
        )}
        {isPhone && (
          <HamburgerMenu>
            <MenuLink
              text={"Blog"}
              toLink={"/blog"}
              onClick={() => {
                navigate("/blog")
                toggleHamburgerMenu()
              }}
            />
            <SecondaryButton
              buttonText={"Log in"}
              textColor={"var(--primary-green)"}
              onClick={() => {
                navigate("/cuenta/iniciar-sesion")
                toggleHamburgerMenu()
              }}
            />

            <PrimaryButton
              buttonText={"Create account"}
              onClick={() => {
                navigate("/cuenta/register")
                toggleHamburgerMenu()
              }}
              borderWidth={"2px"}
            />
          </HamburgerMenu>
        )}
      </div>
    </nav>
  )
}
