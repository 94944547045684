import {useAccounts} from "../../contexts/AccountContext"
import {usePopup} from "../../contexts/PopupContext"
import {useResponsive} from "../../contexts/ResponsiveContext"
import {useTransactionContext} from "../../contexts/TransactionContext"
import {useTransactionFilters} from "../../contexts/TransactionFiltersContext"
import {TransactionDetails, TransactionRow} from "./TransactionGroups"
import {getTransactionSettings} from "./transactionUtils"

export default function FilteredTransactionsList({hasTitle = true}) {
  const {accounts} = useAccounts()
  const {categories} = useTransactionContext()
  const {filteredTransactions} = useTransactionFilters()
  const {showPopup} = usePopup()
  const {isTablet} = useResponsive()

  return (
    <div className="transaction-list-container container" style={{}}>
      {hasTitle && (
        <div className="section-header">
          <h2>Transactions</h2>
        </div>
      )}
      <div
        className="transaction-list"
        style={{
          maxHeight: isTablet ? "88dvh" : "",
        }}
      >
        {filteredTransactions &&
          filteredTransactions.map((transaction, index) => {
            const account = accounts.find(
              (acc) => acc.id === transaction.accountID
            )

            const category = categories.find(
              (cat) => cat.id === transaction.categoryID
            )

            const fromAccount =
              transaction.type === "TRANSFER"
                ? accounts.find((acc) => acc.id === transaction.fromAccountID)
                : ""

            const transactionSettings = getTransactionSettings(transaction)
            return (
              <TransactionRow
                transaction={transaction}
                account={account}
                category={category}
                fromAccount={fromAccount}
                onClick={(transaction, account, category) => {
                  showPopup({
                    content: (
                      <TransactionDetails
                        transaction={transaction}
                        account={account}
                        category={category}
                      />
                    ),
                  })
                }}
              />
            )
          })}
      </div>
    </div>
  )
}
