import {createContext, useContext, useEffect, useState} from "react"
import {useAuth} from "./AuthContext"
import {OnboardingPopup} from "../components/onboarding/OnboardingPopup"

const OnboardingContext = createContext()

export function useOnboarding() {
  return useContext(OnboardingContext)
}

export function OnboardingProvider({children}) {
  const {currentUser} = useAuth()
  const [isFirstTime, setIsFirstTime] = useState(false)
  const [onboardingStep, setOnboardingStep] = useState(0)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const NUMBER_OF_STEPS = 2

  useEffect(() => {
    if (!currentUser) return

    const hasVisited = localStorage.getItem("hasVisitedDashboard")

    if (!hasVisited) {
      setIsPopupOpen(true)
    }
  }, currentUser)
 
  const hidePopup = () => {
    setIsPopupOpen(false)
    localStorage.setItem("hasVisitedDashboard", true)
  }

  const increaseStep = () => {
    if (onboardingStep === NUMBER_OF_STEPS) {
      hidePopup()
      setOnboardingStep(0)
      return
    }
    setOnboardingStep(onboardingStep + 1)
  }
  const decreaseStep = () => {
    if (onboardingStep === 0) return
    setOnboardingStep(onboardingStep - 1)
  }

  const value = {
    hidePopup,
    onboardingStep,
    increaseStep,
    decreaseStep,
    NUMBER_OF_STEPS,
  }
  return (
    <OnboardingContext.Provider value={value}>
      {children}
      {isPopupOpen && <OnboardingPopup />}
    </OnboardingContext.Provider>
  )
}
