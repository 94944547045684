import "./Inputs.css"

export default function EmailInput({
  inputId,
  inputName,
  inputPlaceholder,
  inputLabel,
  onChange,
  inputValue,
  additionalClasses,
  textAlign = "left",
  isDisabled = false,
  marginTop = "0px",
}) {
  const inputClasses = `email-input ${additionalClasses}`
  return (
    <div className={inputClasses}>
      <label htmlFor={inputId}>{inputLabel}</label>
      <input
        style={{textAlign: textAlign, marginTop: marginTop}}
        placeholder={inputPlaceholder}
        id={inputId}
        name={inputName}
        type="email"
        value={inputValue}
        onChange={onChange}
        disabled={isDisabled}
      ></input>
    </div>
  )
}
