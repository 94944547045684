import React from "react"
import {Box, Chip, TextField, Autocomplete} from "@mui/material"
import {useTransactionFilters} from "../../../contexts/TransactionFiltersContext"
import {useTransactionContext} from "../../../contexts/TransactionContext"

const CategoryFilter = () => {
  const {categories} = useTransactionContext()
  const {filterByCategory, selectedCategories, setSelectedCategories} =
    useTransactionFilters()

  const handleChange = (event, value) => {
    setSelectedCategories(value)
    filterByCategory(value)
  }

  return (
    <Autocomplete
      multiple
      id="category-autocomplete"
      options={categories}
      getOptionLabel={(option) => option.name}
      value={selectedCategories}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Categories"
          variant="outlined"
          sx={{
            "& .MuiInputLabel-root.Mui-focused": {
              color: "var(--primary)", // Change to your desired color
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "var(--primary)", // Change to your desired color
              },
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.name}
            {...getTagProps({index})}
            key={option.id}
            sx={{
              borderRadius: "8px",
              backgroundColor: "var(--gray)",
              color: "var(--white)",
            }}
          />
        ))
      }
      sx={{my: 1, width: "100%"}}
    />
  )
}

export default CategoryFilter
