import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {AuthProvider} from "./contexts/AuthContext"
import {BrowserRouter as Router} from "react-router-dom"
import {StatsProvider} from "./contexts/StatsContext"
import {TransactionProvider} from "./contexts/TransactionContext"
import {ResponsiveProvider} from "./contexts/ResponsiveContext"
import {NavigationProvider} from "./contexts/NavigationContext"
import {FeatureProvider} from "./contexts/FeatureContext"
import {OnboardingProvider} from "./contexts/OnboardingContext"
import {AccountProvider} from "./contexts/AccountContext"
import {KnowledgeProvider} from "./contexts/KnowledgeContext"
import {UserProvider} from "./contexts/UserContext"

import ReactGA from "react-ga4"

ReactGA.initialize("G-XL0KNXQMWG")

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <UserProvider>
          <ResponsiveProvider>
            <AccountProvider>
              <TransactionProvider>
                <StatsProvider>
                  <NavigationProvider>
                    <OnboardingProvider>
                      <KnowledgeProvider>
                        <FeatureProvider>
                          <App />
                        </FeatureProvider>
                      </KnowledgeProvider>
                    </OnboardingProvider>
                  </NavigationProvider>
                </StatsProvider>
              </TransactionProvider>
            </AccountProvider>
          </ResponsiveProvider>
        </UserProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
