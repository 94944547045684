import {useEffect, useState} from "react"
import {useKPIs} from "../../../contexts/KpisContext"
import KpiAreaChart from "../KpiAreaChart"

export default function DailyTransactions() {
  const {dailyTransactions} = useKPIs()
  const [todayTransactions, setTodayTransactions] = useState()
  const [yesterdayTransactions, setYesterdayTransactions] = useState()
  const [data, setData] = useState()
  const [dataLabels, setDataLabels] = useState()

  useEffect(() => {
    if (dailyTransactions && dailyTransactions.length > 0) {
      setTodayTransactions(dailyTransactions[0].new_transations)
      setYesterdayTransactions(dailyTransactions[1].new_transations)
      const dataArray = dailyTransactions
        .map((transaction) => transaction.new_transations)
        .reverse()

      setData(dataArray)

      const dataLabelsArray = dailyTransactions
        .map((transaction) => transaction.date)
        .reverse()

      setDataLabels(dataLabelsArray)
    }
  }, [dailyTransactions])

  return (
    <div className=" kpi">
      <div className="kpi-header">
        <p className="kpi-title">Daily transactions</p>

        <div className="today-kpi-value">
          <h3>{todayTransactions}</h3>
          <p>{todayTransactions - yesterdayTransactions}</p>
        </div>
      </div>

      <KpiAreaChart
        data={data}
        dataLabels={dataLabels}
        datasetLabel="New transactions"
      />
    </div>
  )
}
