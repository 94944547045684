import React from "react"
import {useEffect, useState} from "react"
import {useAuth} from "../../contexts/AuthContext"
import {useResponsive} from "../../contexts/ResponsiveContext"
import PageHeader from "../../components/pages/PageHeader"
import BudgetCard from "../../components/budgets/BudgetComponents"
import {useBudgetContext} from "../../contexts/BudgetsContext"
import {useTransactionContext} from "../../contexts/TransactionContext"
import "./BudgetsPage.css"
import FormIcon from "../../components/icons/FormIcon"
import {DashboardPanel} from "../../components/dashboard/DashboardBasics"
import {CreateBudgetForm} from "../../components/budgets/BudgetForms"
import AddIcon from "../../components/icons/AddIcon"
import {usePopup} from "../../contexts/PopupContext"
import {ClosePopupButton} from "../../components/popups/SimplePopup"
import {useAccounts} from "../../contexts/AccountContext"

export default function BudgetsPage() {
  const {budgets, getAllBudgets, budgetsHistory, getAllBudgetsHistory} =
    useBudgetContext()
  const {getAllCategories, categories} = useTransactionContext()
  const {getAllAccounts, accounts} = useAccounts()
  const {currentUser} = useAuth()

  const [budgetsInfo, setBudgetsInfo] = useState(undefined)
  const [showBudgetFilters, setShowBudgetFilters] = useState(false)
  const [showBudgetForm, setShowBudgetForm] = useState(true)
  const [showBudgetStats, setShowBudgetStats] = useState(false)
  const [selectedOption, setSelectedOption] = useState("ACTIVE")

  const {isPhone, isTablet} = useResponsive()

  useEffect(() => {
    if (!budgetsHistory) return

    setBudgetsInfo(
      budgets.map((budget, index) => {
        if (!budgetsHistory[index]) return
        const history =
          budgetsHistory[index].length > 0 ? budgetsHistory[index] : [0]

        return {
          name: budget.name,
          currentSpent: parseFloat(
            history.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr), 0)
          ).toFixed(2),
          budgetLimit: parseFloat(budget.budgetLimit).toFixed(2),
          startDate: budget.startDate,
          endDate: budget.endDate,
          history: history,
          id: budget.id,
        }
      })
    )
  }, [budgetsHistory, budgets])

  useEffect(() => {
    if (accounts.length === 0) {
      getAllAccounts(currentUser.uid)
    }

    if (categories.length === 0) {
      getAllCategories(currentUser.uid)
    }
  }, [currentUser])

  useEffect(() => {
    if (!currentUser) return

    getAllBudgets(currentUser.uid, selectedOption === "ACTIVE")
  }, [selectedOption, currentUser])

  if (!budgetsInfo || budgetsInfo.length === 0) {
    return (
      <main className="default-page budgets-page">
        <section className={`budgets-page-left ${isPhone ? "isPhone" : ""}`}>
          <PageHeader
            title="Budgets"
            hasMonthSelector={false}
            actions={isTablet && <AddBudgetButton />}
            rightSideActions={
              <RightSideActions
                seletedOption={selectedOption}
                updateSelectedOption={setSelectedOption}
              />
            }
          />
          <div className="no-budgets-message">
            {selectedOption === "ACTIVE" && (
              <div className="text-message">
                <p>{"You have no budgets set up yet."}</p>
                <p>{"Go and create one now!"}</p>
              </div>
            )}

            {selectedOption === "ARCHIVED" && (
              <div className="text-message">
                <p>{"You have no archived budgets yet."}</p>
                <p>
                  {
                    "Budget whith an already passed end date will be shown here."
                  }
                </p>
              </div>
            )}
            <div>
              <FormIcon width="75" color="#b5b9bf" />
            </div>
          </div>
        </section>
        {!isTablet && (
          <section className="dashboard-right" style={{width: "40%"}}>
            <DashboardPanel orientation="vertical">
              {showBudgetForm && <CreateBudgetForm />}
            </DashboardPanel>
          </section>
        )}
      </main>
    )
  } else {
    return (
      <main className="default-page budgets-page">
        <section className={`budgets-page-left ${isPhone ? "isPhone" : ""}`}>
          <PageHeader
            title="Budgets"
            hasMonthSelector={false}
            actions={isTablet && <AddBudgetButton />}
            rightSideActions={
              <RightSideActions
                seletedOption={selectedOption}
                updateSelectedOption={setSelectedOption}
              />
            }
          />
          <div className="budget-card-container">
            {budgetsInfo &&
              budgetsInfo.map((budget, index) => (
                <BudgetCard key={index} budget={budget} />
              ))}
          </div>
        </section>
        {!isTablet && (
          <section className="budgets-right">
            <DashboardPanel orientation="vertical">
              {showBudgetForm && <CreateBudgetForm />}
            </DashboardPanel>
          </section>
        )}
      </main>
    )
  }
}

const AddBudgetButton = () => {
  const {showPopup} = usePopup()

  const handleAddBudgetClick = () => {
    showPopup({content: <AddBudgetPopup />})
  }
  return (
    <span onClick={handleAddBudgetClick} style={{cursor: "pointer"}}>
      <AddIcon color={"var(--primary)"} width={"24px"} />
    </span>
  )
}

export const AddBudgetPopup = () => {
  return (
    <div>
      <div className="popup-header">
        <h2> Create budget</h2>
        <ClosePopupButton />
      </div>
      <CreateBudgetForm hideTitle={true} />
    </div>
  )
}

const RightSideActions = ({seletedOption, updateSelectedOption}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <span
        style={{
          cursor: "pointer",
          fontWeight: seletedOption === "ACTIVE" ? "700" : "400",
          color:
            seletedOption === "ACTIVE" ? "var(--primary)" : "var(--light-gray)",
        }}
        onClick={() => updateSelectedOption("ACTIVE")}
      >
        Active
      </span>
      <span
        style={{
          cursor: "pointer",
          fontWeight: seletedOption === "ARCHIVED" ? "700" : "400",
          color:
            seletedOption === "ARCHIVED"
              ? "var(--primary)"
              : "var(--light-gray)",
        }}
        onClick={() => updateSelectedOption("ARCHIVED")}
      >
        {" "}
        Archived
      </span>
    </div>
  )
}
