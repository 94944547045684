import AccountTab from "../../../static/resources/images/onboarding/2_account_tab.png"

export default function AccountsStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Accounts</h2>
      </div>
      <div className="step-content">
        <p>
          An account is a container that represents some part of your finance
          life. It can be:
        </p>
        <ul>
          <li>A bank account</li>
          <li> A savings account</li>
          <li> Index funds or ETFs</li>
        </ul>
        <p> Every transaction will have one (and only one) associated to it.</p>
        <div className="example">
          <p>
            <b>Example:</b> If you have a card called <i>BBVA</i> that
            represents your BBVA credit card, then you should choose <i>BBVA</i>{" "}
            as the account for all transactions that you make with the BBVA
            credit card.
          </p>
        </div>
        <h3>Navigating to the accounts tab</h3>
        <p>
          We should start by navigating to the accounts tab. This can be done
          by:
        </p>
        <ul>
          <li>
            <b> On mobile:</b> Tap on the <b>+</b> button in the center of the
            bottom menu and then select the <i>Add account</i> tab
          </li>
          <li>
            <b> On PC:</b> Choose the <i>Add account</i> tab in the right side
            panel of the dashboard.
          </li>
        </ul>
        <img
          style={{maxHeight: "350px", width: "auto"}}
          src={AccountTab}
          alt="Accounts tab"
        />
      </div>
    </div>
  )
}
