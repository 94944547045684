import {useStats} from "../../contexts/StatsContext"
import {DashboardPanel} from "./DashboardBasics"

import netWorthIcon from "./../../static/resources/icons/netWorth.svg"
import incomeIcon from "./../../static/resources/icons/income.svg"
import expensesIcon from "./../../static/resources/icons/expenses.svg"
import savingsIcon from "./../../static/resources/icons/savings.svg"
import {useEffect} from "react"
import StatEvolution from "../charts/StatEvolution"

export default function FinancialSummary({accountID = null}) {
  const {financialSummary, historicalData, setSelectedAccount} = useStats()
  useEffect(() => {
    setSelectedAccount(accountID)
  }, [accountID])
  if (financialSummary)
    return (
      <section className="financial-summary">
        <FinancialStat
          title={accountID ? "Balance" : "Net worth"}
          value={financialSummary.netWorth}
          color={"rgb(2, 220, 162, 1)"}
          icon={netWorthIcon}
          statType="netWorth"
        />

        <FinancialStat
          title={"Income"}
          value={financialSummary.monthlyIncome}
          color={"rgb(245, 245, 245, 1)"}
          backgroundColor={"#02DCA2"}
          icon={incomeIcon}
          statType="income"
        />

        <FinancialStat
          title={"Expenses"}
          value={financialSummary.monthlyExpense}
          color={"rgb(245, 245, 245, 1)"}
          backgroundColor={"#334050"}
          icon={expensesIcon}
          statType="expenses"
        />

        <FinancialStat
          title={"Balance"}
          value={{
            current:
              parseFloat(financialSummary.monthlyIncome.current) -
              parseFloat(financialSummary.monthlyExpense.current),
          }}
          color={"rgb(51, 64, 80, 1)"}
          icon={savingsIcon}
          statType="balance"
        />
      </section>
    )
}

function FinancialStat({
  title,
  value,
  color,
  backgroundColor,
  icon,
  statType, // New prop to determine which historical data to use
}) {
  const {historicalData} = useStats()

  // Determine which dataset to use based on statType
  const data = historicalData ? historicalData[`historic_${statType}`] : []

  if (value) {
    return (
      <DashboardPanel backgroundColor={backgroundColor} color={color}>
        <div className="stat-header">
          {icon && (
            <img src={icon} style={{fill: color}} alt={`${title} icon`} />
          )}
          <h2 className="stat-name">{title}</h2>
        </div>
        <p className="stat-value">
          {parseFloat(value.current).toFixed(2)}€
          <span className="stat-variation"> {value.variation}</span>
        </p>
        {historicalData && (
          <StatEvolution
            backgroundColor={color}
            label={title}
            data={data}
            dataLabels={historicalData.historic_labels}
            axisMax= {statType !== 'netWorth' ? computeMaxInHistorical(historicalData) : null}
          />
        )}
      </DashboardPanel>
    )
  }
  return null
}


function computeMaxInHistorical(historicalData){
  const incomeData = historicalData['historic_income'].map(parseFloat)
  const expenseData = historicalData['historic_expenses'].map(parseFloat)

  const maxIncome = Math.max(...incomeData)
  const maxExpense = Math.max(...expenseData)

  const max = Math.max(maxIncome,maxExpense) * 1.1
  

  return max

}
