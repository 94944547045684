import {useKPIs} from "../../../contexts/KpisContext"
import {UserRow} from "../../users/UserRow"

export default function LastActiveUsers() {
  const {lastActiveUsers} = useKPIs()
  return (
    <section>
      <h2>Activity</h2>
      <div
        className="kpi last-active-users"
        style={{
          maxHeight: "28dvh",
          paddingBottom: "1rem",
          boxSizing: "border-box",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {lastActiveUsers &&
          lastActiveUsers.length > 0 &&
          lastActiveUsers.map((user) => {
            return <UserRow user={user} />
          })}
      </div>
    </section>
  )
}
