import BudgetsForm from "./../../../static/resources/images/onboarding/16_budgets_form.png"

export default function CreateBudgetStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Creating a budget</h2>
      </div>
      <div className="step-content">
        <p>
          As you navigate to the budgets page, you will be greeted with an account creation form located at the right part of the screen.
          You will be able to choose:
        </p>
        <ul>
          <li>The budget's name</li>
          <li>The budget's description</li>
          <li>The budget's amount</li>
          <li>The budget's account</li>
          <li>The budget's categories</li>
          <li>The budget's start date</li>
          <li>The budget's period</li>
        </ul>

        <p>
          There might be some fields you might not know exactly what they are for. The account field is the account where the budget will
          be applied. This means that only transactions related to that account will be valid for that budget. The same applies to categories.
          You will be able to choose as many expense categories as you want for a certain budget. Finally, the period corresponds to the duration
          of the budget, starting from the start date that you will indicate in the form.
        </p>

        <div className="note">
          <p>
            <b>Note:</b> At the moment only one account is allowed for each budget. In the future we will enable the possibility to
            link several accounts to a single budget.
          </p>
        </div>

        <img
          style={{maxHeight: "350px", width: "auto"}}
          src={BudgetsForm}
          alt="Budgets Form" 
        />
        
        <p>
          Finally, just click in the <i>Create budget</i> button and your budget will be set.
        </p>
      </div>
    </div>
  )
}
