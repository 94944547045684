import React, {useEffect, useState} from "react"
import {useStats} from "../../../contexts/StatsContext"
import ArrowIcon from "../../icons/ArrowIcon"

const MonthSelector = () => {
  const [date, setDate] = useState(new Date())
  const {setSelectedRange} = useStats()

  useEffect(() => {
    setSelectedRange(getMonthStartAndEndDates(date))
  }, [date])

  const formatDateForSQL = (date) => {
    return date.toISOString().split("T")[0]
  }

  const getMonthStartAndEndDates = (date) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0) // Set to first day of the month at 00:00:00
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59) // Set to last day of the month at 23:59:59
    return [formatDateForSQL(start), formatDateForSQL(end)]
  }

  const navigateMonth = (offset) => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + offset, 1)
    setDate(newDate)
  }

  const renderMonthAndYear = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const currentYear = new Date().getFullYear()
    const selectedYear = date.getFullYear()
    const monthIndex = date.getMonth()
    const monthName = monthNames[monthIndex]

    // Check if the selected year is not the current year
    if (selectedYear !== currentYear) {
      // Display only the first three letters of the month's name and the year
      return `${monthName.substring(0, 3)} ${selectedYear}`
    } else {
      // Display the full month's name
      return monthName
    }
  }

  return (
    <div style={{display: "flex", alignItems: "fle-end"}}>
      <button onClick={() => navigateMonth(-1)}>
        <ArrowIcon direction="left" color={"#334050"} width="20px" />
      </button>
      <span
        style={{
          minWidth: "96px",
          textAlign: "center",
          paddingBottom: "4px",
        }}
      >
        {renderMonthAndYear()}
      </span>
      <button onClick={() => navigateMonth(1)}>
        <ArrowIcon direction="right" color={"#334050"} width="20px" />
      </button>
    </div>
  )
}

export default MonthSelector
