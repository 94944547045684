import React, {useEffect, useRef} from "react"
import {Chart} from "react-chartjs-2"
import ChartJS from "chart.js/auto"

export default function StatEvolution({
  data = [12, 34, 45, 56, 44, 78, 0, 0],
  backgroundColor = "rgba(75,192,192,0.4)",
  label,
  dataLabels,
  axisMax, // Default color if not provided
  axisMin,
}) {
  const chartRef = useRef(null) // Ref for the chart component

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current
      const ctx = chart.ctx
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height - 5)
      gradient.addColorStop(0, backgroundColor) // Fully opaque color at the top
      gradient.addColorStop(1, backgroundColor.replace("1)", "0)")) // Fully transparent color

      // Update the chart datasets to apply the gradient
      chart.data.datasets.forEach((dataset) => {
        dataset.backgroundColor = gradient // Assign the gradient here
      })
      chart.update() // Update the chart to render the gradient
    }
  }, [backgroundColor, chartRef, data])
  const dataObject = {
    labels: dataLabels,
    datasets: [
      {
        label: label,
        data: data, // Using the passed `data` array
        fill: true,
        fillOpactiy: 2,
        backgroundColor: backgroundColor.replace(", 1)", ", 0.5)"),
        borderColor: backgroundColor.replace(", 1)", ", 0.9)"),
        tension: 0.4, // Adjust this for more or less curve
        borderWidth: 2,
        pointRadius: 0,
      },
    ],
  }

  const optionObject = {
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        max: axisMax,
        min: axisMin,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  return (
    <Chart
      type="line"
      data={dataObject}
      options={optionObject}
      ref={chartRef}
    />
  )
}
