import {usePopup} from "../../../contexts/PopupContext"
import {useResponsive} from "../../../contexts/ResponsiveContext"
import PrimaryButton from "../../buttons/PrimaryButton"
import SecondaryButton from "../../buttons/SecondaryButton"
import {CreateTransactionForm} from "../../transactions/TransactionForms"

export default function AddTransactionPopup({
  buttonColor = "var(--primary)",
  buttonType = "primary",
}) {
  const {showPopup, hidePopup} = usePopup()
  const {isPhone} = useResponsive()
  const handleClick = () =>
    showPopup({
      content: (
        <>
          <CreateTransactionForm hideForm={undefined} />
          <span
            style={{
              display: "block",
              fontWeight: "600",
              color: "var(--light-gray)",
              textAlign: "center",
              cursor: "pointer",
              width: "100%",
              fontSize: "0.9rem",
              paddingTop: "0.8rem",
            }}
            onClick={() => hidePopup()}
          >
            Close
          </span>
        </>
      ),
      styles: {
        padding: isPhone ? "1rem" : "1.2rem 2rem",
        maxHeight: "none",
      },
      containerStyles: {
        padding: "0.8rem",
      },
    })

  let cta

  switch (buttonType) {
    case "secondary":
      cta = (
        <SecondaryButton
          buttonText={"Add transaction"}
          onClick={handleClick}
          backgroundColor={buttonColor}
          borderColor={buttonColor}
          textColor={buttonColor}
        />
      )
      break
    default:
      cta = (
        <PrimaryButton
          buttonText={"Add transaction"}
          onClick={handleClick}
          backgroundColor={buttonColor}
        />
      )
      break
  }

  return <>{cta}</>
}
