import {useEffect, useState} from "react"
import {useKPIs} from "../../../contexts/KpisContext"
import KpiAreaChart from "../KpiAreaChart"

export default function DailyRecurrences() {
  const {dailyRecurrences} = useKPIs()
  const [todayRecurrences, setTodayRecurrences] = useState()
  const [yesterdayRecurrences, setYesterdayRecurrences] = useState()

  useEffect(() => {
    if (dailyRecurrences.length > 0) {
      setTodayRecurrences(dailyRecurrences[0].new_recurrence)
      setYesterdayRecurrences(dailyRecurrences[1].new_recurrence)
    }
  }, [dailyRecurrences])

  const data = dailyRecurrences
    .map((transaction) => transaction.new_recurrence)
    .reverse()

  const dataLabels = dailyRecurrences
    .map((transaction) => transaction.date)
    .reverse()
  return (
    <div className="kpi">
      <div className="kpi-header">
        <p className="kpi-title">Daily Recurrences</p>

        <div className="today-kpi-value">
          <h3>{todayRecurrences}</h3>
          <p>{todayRecurrences - yesterdayRecurrences}</p>
        </div>
      </div>

      <KpiAreaChart
        data={data}
        dataLabels={dataLabels}
        datasetLabel="New recurrences"
      />
    </div>
  )
}
