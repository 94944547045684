import "./Loading.css"

import Logo from "../static/resources/images/logo512.png"

export default function Loading() {
  return (
    <div className="loading-page">
      <img style={{width: "40dvw", height: "auto"}} src={Logo} />
      <h3>
        FINANZ
        <span style={{color: "var(--primary-green)", fontWeight: "700"}}>
          IA
        </span>{" "}
        is loading...
      </h3>
    </div>
  )
}
