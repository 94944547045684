import React, {useEffect, useState} from "react"
import {Doughnut} from "react-chartjs-2"
import "./Budgets.css"
import {useNavigate} from "react-router"
import AddIcon from "../icons/AddIcon"
import {useAuth} from "../../contexts/AuthContext"
import {usePopup} from "../../contexts/PopupContext"
import {AddBudgetPopup} from "../../pages/app/BudgetsPage"

const DashboardBudgets = () => {
  const [budgetNumber, setBudgetNumber] = useState(0)
  const [satisfiedBudgets, setSatisfiedBudgets] = useState(0)
  const {currentUser} = useAuth()

  const {showPopup} = usePopup()

  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) return

    fetch(
      `https://backend.finanzia.app/api/budgets/summary?userID=${currentUser.uid}`
    ) // TODO -> Make this dynamic on the backend
      .then((response) => response.json())
      .then((data) => {
        setBudgetNumber(data.budgetNumber)
        setSatisfiedBudgets(data.satisfiedBudgets)
      })
      .catch((error) => {
        console.error("Error fetching budget data:", error)
      })
  }, [currentUser])

  const data = {
    labels: ["Satisfied", "Unsatisfied"],
    datasets: [
      {
        data: [satisfiedBudgets, budgetNumber - satisfiedBudgets],
        backgroundColor: ["#00dca2", "#465160"],
        hoverBorderColor: ["#f9f9f9", "#f9f9f9"],
        borderRadius: 90,
        borderWidth: 3,
        borderColor: "#f9f9f9",
      },
    ],
  }

  const optionObject = {
    cutout: "85%",
    plugins: {
      legend: {
        display: false,
      },
      dataLabels: {
        display: false,
      },

      tooltip: {
        enabled: false,
      },
    },
  }

  const centerDivStyle = {
    position: "relative",
    transform: "translate(0, -150%)",
    textAlign: "center",
    marginTop: "auto",
    marginBottom: "auto",
  }

  if (budgetNumber === 0) {
    return (
      <section className="dashboard-budgets">
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <h2> Budgets</h2>
          <span
            onClick={() =>
              showPopup({
                content: <AddBudgetPopup />,
              })
            }
            style={{cursor: "pointer"}}
          >
            <AddIcon color={"var(--light-gray)"} width={"26px"} />
          </span>
        </div>
        <div className="no-budgets-container">
          <div className="no-budgets-message">
            You have no budgets set up yet. Go and create one now!
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="dashboard-budgets">
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <h2> Budgets</h2>
          <span
            onClick={() =>
              showPopup({
                content: <AddBudgetPopup />,
              })
            }
            style={{cursor: "pointer"}}
          >
            <AddIcon color={"var(--light-gray)"} width={"26px"} />
          </span>
        </div>
        <div className="general-stats-container">
          <div className="non-satisfied-budgets-container">
            <div className="non-satisfied-budgets-number">
              {budgetNumber - satisfiedBudgets}
            </div>
            <div>Non-satisfied Budgets</div>
          </div>
          <div className="budgets-doghnut-chart-container">
            <div className="dashboard-budgets-general-stats">
              <Doughnut data={data} options={optionObject} />
              <div style={centerDivStyle}>
                <div className="total-budgets-number">{budgetNumber}</div>
                <div className="total-budgets-text">Budgets</div>
              </div>
            </div>
          </div>
          <div className="satisfied-budgets-container">
            <div className="satisfied-budgets-number">{satisfiedBudgets}</div>
            <div>Satisfied Budgets</div>
          </div>
        </div>
      </section>
    )
  }
}

export {DashboardBudgets}
