export default function SettingSectionHeader({title, icon, action}) {
  return (
    <div className="setting-section-header">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {icon}
        <h2>{title}</h2>
      </div>
      <div className="setting-section-header-actions">{action}</div>
    </div>
  )
}
