import {useState} from "react"
import UploadIcon from "../icons/UploadIcon"
import {supabase} from "../../supabase"
import toast from "react-hot-toast"
import {useAuth} from "../../contexts/AuthContext"

export default function ImageUpload({setImages, setImageNames}) {
  const {currentUser} = useAuth()
  const [uploading, setUploading] = useState(false) // Optional: state to handle loading

  const uploadToSupabase = async (file) => {
    const fileExtension = file.name.split(".").pop()
    const filePath = `${currentUser.uid}_${Date.now()}.${fileExtension}`

    const {data, error} = await supabase.storage
      .from("feedback_images")
      .upload(filePath, file)

    if (error) {
      toast.error(`Error uploading file: ${file.name}`)
      return null
    }

    // Get the public URL
    const {data: urlData} = supabase.storage
      .from("feedback_images")
      .getPublicUrl(filePath)

    return urlData?.publicUrl || null // Ensure to access publicUrl from data
  }

  const handleImageChange = async (e) => {
    setUploading(true)
    const selectedFiles = Array.from(e.target.files).filter((file) =>
      ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
    )

    const uploadedImageUrls = await Promise.all(
      selectedFiles.map((file) => uploadToSupabase(file))
    )

    // Filter out null or undefined URLs
    const validImageUrls = uploadedImageUrls.filter((url) => url)

    if (validImageUrls.length === 0) {
      toast.error("No valid images were uploaded.")
      setUploading(false)
      return
    }

    // Append new image URLs to the existing images
    setImages((prevImages) => [...prevImages, ...validImageUrls])

    // Append the original file names to the image names
    const fileNames = selectedFiles.map((file) => file.name)
    setImageNames((prevNames) => [...prevNames, ...fileNames])

    setUploading(false)
    toast.success("Images uploaded successfully!")
  }

  return (
    <div className="image-upload-input">
      <label
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
        htmlFor="image-upload"
      >
        <UploadIcon color={"var(--white)"} width={"24px"} />
        Choose images to upload
      </label>
      <input
        id="image-upload"
        type="file"
        multiple
        accept=".jpg, .jpeg, .png"
        onChange={handleImageChange}
        style={{display: "none"}} // hide the default file input button
      />
    </div>
  )
}
