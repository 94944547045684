import {NumberInput} from "./NumberInput"
import SelectSearch from "react-select-search"
import "./SelectSearch.css"
import {useResponsive} from "../../contexts/ResponsiveContext"
import SelectInput from "./SelectInput"

const TransactionAmountInput = ({
  onChange,
  inputId = "transaction_amount",
  inputPlaceholder = "Amount",
  inputName = "Transaction amount",
  inputValue,
}) => {
  return (
    <div className="input-container transaction-amount-input">
      <NumberInput
        inputValue={inputValue}
        inputId={inputId}
        onChange={onChange}
        inputPlaceholder={inputPlaceholder}
        inputName={inputName}
      />
    </div>
  )
}

const TransactionTypeInput = ({onChange, selectedType}) => {
  return (
    <div className="input-container">
      <select
        id="transaction_type"
        name="Transaction type"
        onChange={onChange}
        defaultValue={selectedType}
      >
        <option value="INCOME">Income</option>
        <option value="EXPENSE">Expense</option>
        <option value="TRANSFER">Tranfer</option>
      </select>
    </div>
  )
}

const TransactionAccountInput = ({
  accounts,
  onChange,
  showLabel,
  inputLabel,
  inputId,
  inputName,
  selectedAccountId,
}) => {
  const {isTablet} = useResponsive()

  const options = accounts.map((account) => ({
    name: account.name,
    value: account.id.toString(),
  }))

  const handleChange = (valueOrEvent) => {
    let value
    if (isTablet && valueOrEvent && valueOrEvent.target) {
      // valueOrEvent is an event (from SelectInput)
      value = valueOrEvent.target.value
    } else {
      // valueOrEvent is the value directly (from SelectSearch)
      value = valueOrEvent
    }
    onChange({
      target: {
        value: value,
        name: inputName,
        id: inputId,
      },
    })
  }

  return (
    <div className="input-container account-input">
      {showLabel && inputLabel && <label htmlFor={inputId}>{inputLabel}</label>}
      {!isTablet && (
        <SelectSearch
          id={inputId}
          name={inputName}
          options={options}
          value={selectedAccountId}
          onChange={handleChange}
          search
          placeholder="Account"
        />
      )}

      {isTablet && (
        <SelectInput
          inputID={inputId}
          inputName={inputName}
          onChange={handleChange}
          defaultValue={selectedAccountId}
          options={options}
        />
      )}
    </div>
  )
}

const TransactionCategoryInput = ({
  categories,
  onChange,
  showLabel,
  inputLabel,
  inputId,
  inputName,
  categoryType,
  selectedCategory,
}) => {
  const {isTablet} = useResponsive()
  const options = categories
    .filter((category) => category.type === categoryType)
    .map((category) => ({
      name: category.name,
      value: category.id.toString(),
    }))

  const handleChange = (valueOrEvent) => {
    let value
    if (isTablet && valueOrEvent && valueOrEvent.target) {
      value = valueOrEvent.target.value
    } else {
      value = valueOrEvent
    }
    onChange({
      target: {
        value: value,
        name: inputName,
        id: inputId,
      },
    })
  }

  return (
    <div className="input-container account-input">
      {showLabel && inputLabel && <label htmlFor={inputId}>{inputLabel}</label>}
      {!isTablet && (
        <SelectSearch
          id={inputId}
          name={inputName}
          options={options}
          value={selectedCategory}
          onChange={handleChange}
          search
          placeholder="Category"
        />
      )}
      {isTablet && (
        <SelectInput
          inputID={inputId}
          inputName={inputName}
          onChange={handleChange}
          defaultValue={selectedCategory}
          options={options}
        />
      )}
    </div>
  )
}

export {
  TransactionAmountInput,
  TransactionTypeInput,
  TransactionAccountInput,
  TransactionCategoryInput,
}
