import {useState} from "react"
import ActionButton from "../buttons/ActionButton"
import {CreateTransactionForm} from "../transactions/TransactionForms"
import {CreateAccountForm} from "../accounts/AccountForms"
import Tab from "../buttons/Tabs"
import {CreateCategoryForm} from "../categories/CategoryForms"
import {useResponsive} from "../../contexts/ResponsiveContext"

export function TransactionActions() {
  const [transactionAction, setTransactionAction] = useState("add_transaction")

  let transactionComponent

  const hideForm = () => {
    setTransactionAction("")
  }

  switch (transactionAction) {
    case "add_transaction":
      transactionComponent = <CreateTransactionForm hideForm={undefined} />
      break

    case "add_account":
      transactionComponent = <CreateAccountForm hideForm={hideForm} />
      break
    case "add_category":
      transactionComponent = <CreateCategoryForm hideForm={hideForm} />
      break
    default:
      break
  }
  return (
    <section className="transaction-actions">
      <ActionTabs
        setTransactionAction={setTransactionAction}
        transactionAction={transactionAction}
      />
      {transactionComponent}
    </section>
  )
}

function ActionTabs({setTransactionAction, transactionAction}) {
  const handleActionChange = (actionKey) => {
    setTransactionAction(actionKey)
  }
  const {isPhone} = useResponsive()

  return (
    <div className="action-tabs">
      <div className="tabs-row">
        <Tab
          text={!isPhone ? "Add transaction" : "Transactions"}
          key="add_transaction"
          tabKey="add_transaction" // Note: key props do not propagate to your component. They're handled by React.
          onClick={() => handleActionChange("add_transaction")}
          isActive={transactionAction === "add_transaction"}
        />

        <Tab
          text={!isPhone ? "Add account" : "Accounts"}
          tabKey="add_account"
          key="add_account"
          onClick={() => handleActionChange("add_account")}
          isActive={transactionAction === "add_account"}
        />

        <Tab
          text={!isPhone ? "Add category" : "Categories"}
          tabKey="add_category"
          key="add_category"
          onClick={() => handleActionChange("add_category")}
          isActive={transactionAction === "add_category"}
        />
      </div>
    </div>
  )
}
