import {useEffect, useState} from "react"
import {useTransactionFilters} from "../../../contexts/TransactionFiltersContext"
import {useTransactionContext} from "../../../contexts/TransactionContext"
import "./TransactionsStats.css"
import StatEvolution from "../../charts/StatEvolution"
import {useResponsive} from "../../../contexts/ResponsiveContext"

export default function FilteredTransactionsStats() {
  const {showFilteredStats, filteredTransactions, startDate, endDate} =
    useTransactionFilters()
  const {transactions} = useTransactionContext()
  const {isPhone} = useResponsive()

  return (
    <div className="filtered-transactions-stats-container">
      {!isPhone && <span className="separator narrow"></span>}
      {!isPhone && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 className="title">Transactions stats</h2>
          <p>#{filteredTransactions.length}</p>
        </div>
      )}

      <div className="filtered-transactions-stats">
        {isPhone && (
          <TotalTransactionsMobileStat transactions={filteredTransactions} />
        )}
        <TotalAmounts
          transactions={filteredTransactions}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  )
}

const TotalTransactionsMobileStat = ({transactions}) => {
  const totalTransactions = transactions.length

  return (
    <div
      className="total-transactions-stat stat"
      style={{
        paddingBottom: "0.6rem",
        borderBottom: "1px dashed var(--gray)",
        marginBottom: "0.8rem",
      }}
    >
      <div className="stat-header">
        <h3>Total transactions</h3>
        <p>{totalTransactions}</p>
      </div>
    </div>
  )
}

const TotalAmounts = ({transactions, startDate, endDate}) => {
  const {isPhone} = useResponsive()

  const [totalIncome, setTotalIncome] = useState(null)
  const [totalExpenses, setTotalExpenses] = useState(null)
  const [balance, setBalance] = useState(null)
  const [chartLabels, setChartLabels] = useState([])
  const [chartData, setChartData] = useState({})

  useEffect(() => {
    let income = 0
    let expenses = 0

    for (const transaction of transactions) {
      if (transaction.type === "INCOME") {
        income += parseFloat(transaction.value)
      } else if (transaction.type === "EXPENSE") {
        expenses += parseFloat(transaction.value)
      }
    }

    setTotalIncome(income.toFixed(2))
    setTotalExpenses(expenses.toFixed(2))
    setBalance((income - expenses).toFixed(2))
  }, [transactions])

  // Group transactions by date for chart
  useEffect(() => {
    const grouped = {}
    const dates = new Set()

    // Determine the grouping interval
    const diffInDays =
      (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
    let groupBy = "day"
    if (diffInDays > 120 && diffInDays <= 730) {
      groupBy = "week"
    } else if (diffInDays > 730 || (!endDate && !startDate)) {
      groupBy = "month"
    }

    // Helper function to format date based on the grouping interval
    const formatDate = (date) => {
      const d = new Date(date)
      if (groupBy === "week") {
        const startOfWeek = new Date(d.setDate(d.getDate() - d.getDay()))
        return startOfWeek.toISOString().split("T")[0]
      } else if (groupBy === "month") {
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}`
      }
      return d.toISOString().split("T")[0]
    }

    // Loop through transactions
    transactions.forEach((transaction) => {
      const date = formatDate(transaction.date)
      dates.add(date)

      if (!grouped[date]) {
        grouped[date] = {income: 0, expenses: 0}
      }

      if (transaction.type === "INCOME") {
        grouped[date].income += parseFloat(transaction.value)
      } else if (transaction.type === "EXPENSE") {
        grouped[date].expenses += parseFloat(transaction.value)
      }
    })

    const sortedDates = Array.from(dates).sort(
      (a, b) => new Date(a) - new Date(b)
    )

    setChartLabels(sortedDates)
    setChartData(grouped)
  }, [transactions, startDate, endDate])

  return (
    <div className="total-amounts stat">
      {totalIncome && (
        <div className="total-income">
          <div className="stat-header">
            <h3>Total income</h3>
            <p style={{color: "var(--primary)"}}>{totalIncome}€</p>
          </div>
          {!isPhone && transactions.length > 1 && (
            <span className="chart-container">
              <StatEvolution
                backgroundColor={"rgb(2, 220, 162, 1)"}
                data={chartLabels.map((date) => chartData[date]?.income || 0)}
                dataLabels={chartLabels}
                axisMin={0}
              />
            </span>
          )}
        </div>
      )}

      {totalExpenses && (
        <div className="total-expenses stat">
          <div className="stat-header">
            <h3>Total expenses</h3>
            <p>{totalExpenses}€</p>
          </div>
          {!isPhone && transactions.length > 1 && (
            <span className="chart-container">
              <StatEvolution
                backgroundColor={"rgb(51, 64, 80, 1)"}
                data={chartLabels.map((date) => chartData[date]?.expenses || 0)}
                dataLabels={chartLabels}
                axisMin={0}
              />
            </span>
          )}
        </div>
      )}

      {balance && <BalanceStat balance={balance} />}
    </div>
  )
}

const BalanceStat = ({balance}) => {
  const balanceAmount = parseFloat(balance)

  return (
    <div className="balance">
      <h3>Balance</h3>
      <p
        className="amount"
        style={{color: balanceAmount > 0 ? "#02dca2" : "#334050"}}
      >
        {balanceAmount}€
      </p>
    </div>
  )
}
