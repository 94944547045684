export default function TextPill({
  children,
  borderRadius = "50px",
  border = "none",
  fontWeight = "400",
}) {
  return (
    <div
      className="text-pill"
      style={{
        borderRadius: borderRadius,
        border: border,
        padding: "0.4rem 1.2rem",
        fontWeight: fontWeight,
        maxWidth: "fit-content",
      }}
    >
      {children}
    </div>
  )
}
