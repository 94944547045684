import {useResponsive} from "../../contexts/ResponsiveContext"
import {LogIn, LogOut, MenuItem} from "./NavigationComponents"

import LogOutIcon from "./../../static/resources/icons/logout.svg"
import {useNavigate} from "react-router"
import {useAuth} from "../../contexts/AuthContext"
import {usePopup} from "../../contexts/PopupContext"
import {CreateTransactionForm} from "../transactions/TransactionForms"
import {useState} from "react"
import {TransactionActions} from "../dashboard/TransactionActions"
import {useBottomSheet} from "../../contexts/BottomSheetContext"

export default function BottomMenu() {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  return (
    <div className="bottom-menu">
      <div className="bottom-menu-left">
        <MenuItem
          text={"Dashboard"}
          iconName={"dashboard"}
          onClick={() => navigate("/app/dashboard")}
          toLink={"/app/dashboard"}
          iconWidth="28px"
        />

        <MenuItem
          text={"Transactions"}
          iconName={"transactions"}
          toLink={"/app/transactions"}
          onClick={() => navigate("/app/transactions")}
          iconWidth="28px"
        />

        <MenuItem
          text={"Budgets"}
          iconName={"budgets"}
          toLink={"/app/budgets"}
          onClick={() => navigate("/app/budgets")}
        />
      </div>
      <div className="bottom-menu-center">{currentUser && <AddButton />}</div>
      <div className="bottom-menu-right">
        {currentUser && (
          <MenuItem
            text={"Vote"}
            iconName={"vote"}
            onClick={() => navigate("/app/vote")}
            toLink={"/app/vote"}
          />
        )}
        {currentUser && (
          <MenuItem
            text={"Settings"}
            iconName={"settings"}
            onClick={() => navigate("/app/settings")}
            toLink={"/app/settings"}
          />
        )}
        {currentUser && <LogOut text={"Log out"} icon={LogOutIcon} />}
        {!currentUser && <LogIn text={"Log in"} icon={LogOutIcon} />}
      </div>
    </div>
  )
}

function AddButton() {
  const {showPopup, hidePopup} = usePopup()

  const handleClick = () =>
    showPopup({
      content: (
        <>
          <TransactionActions />
          <span
            style={{
              display: "block",
              fontWeight: "600",
              color: "var(--light-gray)",
              textAlign: "center",
              cursor: "pointer",
              width: "100%",
              fontSize: "0.9rem",
              paddingTop: "0.8rem",
            }}
            onClick={() => hidePopup()}
          >
            Close
          </span>
        </>
      ),
      styles: {
        padding: "1rem",
        maxHeight: "none",
      },
      containerStyles: {
        padding: "0.8rem",
      },
    })

  return <MenuItem iconName={"add_transaction"} onClick={handleClick} />
}
