import {createContext, useContext, useEffect, useState} from "react"
import {useAuth} from "./AuthContext"
import axios from "axios"
import {useStats} from "./StatsContext"
import {apiClient} from "../infraestructure/api/client"

const RecurrentTransactionContext = createContext()

export default function useRecurrenceContext() {
  return useContext(RecurrentTransactionContext)
}

export function RecurrentTransactionProvider({children}) {
  const [recurrentTransactions, setRecurrentTransactions] = useState([])
  const {currentUser, isLoading} = useAuth()
  const [recurrencCategorySummary, setCategorySummary] = useState()
  const [historicalRecurrentData, setHistoricalRecurrentData] =
    useState(undefined)
  const [recurrenceBreakdown, setRecurrenceBreakdown] = useState(undefined)
  const [recurrenceInsights, setRecurrenceInsights] = useState(undefined)
  const {selectedRange} = useStats()

  const createRecurrentTransaction = async (recurrence) => {
    try {
      const response = await apiClient.post(
        "/recurrent-transactions",
        recurrence
      )
      getAllRecurrentTransactions()
      return response
    } catch (error) {
      console.log("Error creating recurrent transaction", error)
      return false
    }
  }

  const deleteRecurrentTransaction = async (id) => {
    try {
      const response = await apiClient.delete(`/recurrent-transactions/${id}`)
      getAllRecurrentTransactions()
      return response
    } catch (error) {
      console.log("Error deleting recurrent transaction", error)
      return false
    }
  }

  const getAllRecurrentTransactions = async (userID) => {
    const params = {
      userID: userID,
    }
    try {
      const response = await apiClient.get("/recurrent-transactions", {
        params: params,
      })
      setRecurrentTransactions(response)
    } catch (error) {
      console.log("Error retrieving recurrent transactions", error)
      return false
    }
  }

  const getCategorySummary = async () => {
    if (!currentUser.uid) return

    const params = {
      userID: currentUser.uid,
    }
    try {
      const response = await apiClient.get("/recurrence-summary/category", {
        params: params,
      })
      setCategorySummary(response)
      return true
    } catch (error) {
      console.log("Error retrieving recurrent transactions", error)
      return false
    }
  }

  const getHistoricalRecurrentData = async () => {
    const endDate = "2024-10-31"

    const params = {
      userID: currentUser.uid,
      endDate: endDate,
    }
    try {
      const response = await apiClient.get("/data/recurrences/historical", {
        params: params,
      })
      setHistoricalRecurrentData(response)
      return true
    } catch (error) {
      console.log("Error retrieving recurrent transactions", error)
      return false
    }
  }

  const getRecurrenceBreakdown = async () => {
    const params = {
      userID: currentUser.uid,
      startDate: selectedRange[0],
      endDate: selectedRange[1],
    }

    try {
      const response = await apiClient.get("/data/recurrences/breakdown", {
        params: params,
      })
      setRecurrenceBreakdown(response)
      return true
    } catch (error) {
      console.log("Error retrieving recurrent breakdown", error)
      return false
    }
  }

  const getRecurrenceInsights = async () => {
    const params = {
      userID: currentUser.uid,
    }

    try {
      const response = await apiClient.get("/data/recurrences/insights", {
        params: params,
      })
      setRecurrenceInsights(response)
      return true
    } catch (error) {
      console.log("Error retrieving recurrent insights", error)
      return false
    }
  }

  useEffect(() => {
    if (isLoading) return

    if (currentUser && currentUser.uid) {
      getAllRecurrentTransactions(currentUser.uid)
    }
  }, [currentUser, isLoading])

  const value = {
    createRecurrentTransaction,
    recurrentTransactions,
    getAllRecurrentTransactions,
    recurrencCategorySummary,
    getCategorySummary,
    historicalRecurrentData,
    getHistoricalRecurrentData,
    recurrenceBreakdown,
    getRecurrenceBreakdown,
    recurrenceInsights,
    getRecurrenceInsights,
    deleteRecurrentTransaction,
  }
  return (
    <RecurrentTransactionContext.Provider value={value}>
      {children}
    </RecurrentTransactionContext.Provider>
  )
}
