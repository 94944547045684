import {useTransactionFilters} from "../../../contexts/TransactionFiltersContext"
import {FormRow} from "../../forms/FormUtils"
import {DateInput} from "../../inputs/DateInput"

export default function DateRangeFilter() {
  const {filterByDateRange, startDate, endDate} = useTransactionFilters()

  const handleStartDateChange = (e) => {
    filterByDateRange(e.target.value, endDate)
  }

  const handleEndDateChange = (e) => {
    filterByDateRange(startDate, e.target.value)
  }

  return (
    <FormRow additionalClasses="responsiveColumn">
      <DateInput
        onChange={handleStartDateChange}
        inputValue={startDate}
        inputLabel="Start date"
      />
      <DateInput
        onChange={handleEndDateChange}
        inputValue={endDate}
        inputLabel="End date"
      />
    </FormRow>
  )
}
