import "./Buttons.css"

export default function PrimaryButton({
  buttonText,
  onClick,
  backgroundColor = "#02dca2",
  color = "var(--white)",
  buttonStyles = {
    width: "70%",
    margin: "auto",
    marginTop: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0.8rem",
  },
  type = "button",
  borderWidth = null,
  borderRadius = "4px",
  isDisabled = false,
}) {
  const buttonProps = onClick ? {onClick} : {}
  const combinedStyles = {
    ...buttonStyles,
    backgroundColor,
    border: borderWidth ? ` ${borderWidth} solid ${backgroundColor}` : null,
    borderRadius: borderRadius,
    color: color,
  } // Combine the styles here
  return (
    <button
      style={combinedStyles}
      type={type}
      className="primary-button"
      {...buttonProps}
      disabled={isDisabled}
    >
      {buttonText || "test"}
    </button>
  )
}
