import {useNavigate} from "react-router"
import {useTransactionContext} from "../../contexts/TransactionContext"
import "./Categories.css"

function CategoryList({title}) {
  const {categories} = useTransactionContext()
  return (
    <section className="category-list-container">
      <div>
        <h3>{title}</h3>
      </div>
      <div
        style={{display: "flex", justifyContent: "space-between", gap: "20px"}}
      >
        <CategoryType type={"INCOME"} categories={categories} />
        <CategoryType type={"EXPENSE"} categories={categories} />
      </div>
    </section>
  )
}

function CategoryType({type, categories}) {
  return (
    <div style={{width: "50%"}}>
      <h3 style={{textTransform: "capitalize"}}>{type}</h3>
      <div className="category-list">
        {categories &&
          categories.map((category) => {
            if (category.type === type)
              return <CategoryRow category={category} />
          })}
      </div>
    </div>
  )
}

function CategoryRow({category}) {
  const navigate = useNavigate()

  const redirectToCategoryPage = () => {
    navigate(`/app/category/${category.id}`)
  }
  return (
    <div
      className="category category-row"
      key={category.id}
      onClick={redirectToCategoryPage}
    >
      <div className="category-left">
        <h4 className="category-name">{category.name}</h4>
        <p>
          {category.description && category.description.length > 25
            ? `${category.description.substring(0, 25)}...`
            : category.description}
        </p>
      </div>
    </div>
  )
}

export {CategoryList}
