export default function HamburgerMenuIcon({color, height, width = "26px"}) {
  return (
    <svg
      width={width}
      height={height ? height : width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M20 7L4 7"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />{" "}
        <path
          d="M20 12L4 12"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />{" "}
        <path
          d="M20 17L4 17"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />{" "}
      </g>
    </svg>
  )
}
