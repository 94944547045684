import {useAuth} from "../../contexts/AuthContext"
import LinkIcon from "../icons/LinkIcon"
import toast from "react-hot-toast"

export default function ResetPassword({styles, text = null, onClick = null}) {
  const {currentUser, resetPassword} = useAuth()

  const handleResetPassword = async (e) => {
    const email = currentUser.email
    const isPasswordResetSent = await resetPassword(email)
    if (isPasswordResetSent) {
      toast.success("Password reset email sent successfully")
    } else {
      toast.error("Could not send password reset email")
    }
  }
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        justifyContent: "space-between",
        flexWrap: "wrap",
        ...styles,
      }}
    >
      <p>Forgot your password?</p>
      <span
        style={{
          display: "flex",
          gap: "0.4rem",
          cursor: "pointer",
          alignItems: "center",
        }}
        onClick={onClick ? onClick : handleResetPassword}
      >
        <LinkIcon color={"var(--primary)"} width="18px" />
        <p style={{margin: "0", color: "var(--primary)"}}>
          {text ? text : "Send reset link"}
        </p>
      </span>
    </div>
  )
}
