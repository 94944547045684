import TransactionsTab from "../../../static/resources/images/onboarding/7_transactions_tab.png"

export default function CreatingTransactionStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Creating transactions</h2>
      </div>
      <div className="step-content">
        <p>
          When it comes time to create a transations, you will do so using the
          crate transation form. Let's go over the fields in the form:
        </p>

        <ul>
          <li>Name </li>
          <li>
            Description, which can be toggled by clicking on the button besides
            the name field
          </li>
          <li>Type </li>
          <li>Amount </li>
          <li>Category </li>
          <li>Account </li>
          <li>Date </li>
          <li>Time </li>
        </ul>
        <p>
          After filling all the fields, you can click on the{" "}
          <i> Create transaction</i> button and the transaction will be
          recorded.{" "}
        </p>
        <img
          style={{
            maxHeight: "350px",
            width: "auto",
            boxShadow: "rgba(51, 64, 80, 0.25) 0px 0px 6px",
            marginLeft: "6px",
            borderRadius: "12px",
          }}
          src={TransactionsTab}
          alt="Accounts tab"
        />
      </div>
    </div>
  )
}
