export default function Delimiter({color, height, width, lineStroke = "0.5", lineOpacity = "0.2"}) {
    return (
        <svg 
            width={width}
            height={height ? height : width}
            viewBox={`0 0 1 ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="0.75"
                y1={height}
                x2="0.75"
                y2="0.898796"
                stroke={color}
                strokeOpacity={lineOpacity}
                strokeWidth={lineStroke}
            />
        </svg>
    )
  }
  