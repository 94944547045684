import {useEffect} from "react"
import useRecurrenceContext from "../../contexts/RecurrentTransactionContext"
import BarChart from "../charts/BarChart"

export default function RecurrentBarChart() {
  const {historicalRecurrentData, getHistoricalRecurrentData} =
    useRecurrenceContext()

  useEffect(() => {
    getHistoricalRecurrentData()
  }, [])

  let data, labels

  if (historicalRecurrentData) {
    data = {
      income: historicalRecurrentData.income,
      expenses: historicalRecurrentData.expenses,
    }
    labels = historicalRecurrentData.historical_labels
  }
  return (
    <div>
      <div className="breakdown-header">
        <h2 className="title">Recurrence overview</h2>
      </div>
      {historicalRecurrentData && (
        <BarChart historicalData={data} historicalLabels={labels} />
      )}
    </div>
  )
}
