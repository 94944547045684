import BudgetsCard from "./../../../static/resources/images/onboarding/17_budgets_card.png"
import BudgetsEditForm from "./../../../static/resources/images/onboarding/18_budgets_edit.png"

export default function ExistingBudgetsStep() {
  return (
    <div className="onboarding-step">
      <div className="step-header">
        <h2>Existing budgets</h2>
      </div>
      <div className="step-content">
        <p>
          After creating a budget, its budget card will appear in the left side of the dashboard. This budget card will show the
          budget's information, such as the amount of money spent out from the budet total, the budget limit, the period during which
          the budget is activet, etc. Moreover, you will see a chart showing the evolution of the budget's expenses over time, which
          will give you more insights about your spending habits.
        </p>

        <img
          style={{maxHeight: "250px", width: "auto"}}
          src={BudgetsCard}
          alt="Budgets Card"
        />

        <div className="note">
          <p>
            <b>Note:</b> By clicking in the edit and delete buttons in the budget card, you will be able to both modify any budget
            and delete it.
          </p>
        </div>

        <img
          style={{maxHeight: "250px", width: "auto"}}
          src={BudgetsEditForm}
          alt="Budgets Edit Form"
        />

      </div>
    </div>
  )
}
