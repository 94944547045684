import {usePopup} from "../../contexts/PopupContext"
import CloseIcon from "../icons/CloseIcon"
import "./Popups.css"
const Popup = ({children, styles, containerStyles}) => {
  return (
    <>
      <div className="popup-container" style={containerStyles}>
        <div className="popup" style={styles}>
          {children}
        </div>
      </div>
    </>
  )
}

const ClosePopupButton = ({text, icon = null}) => {
  const {hidePopup} = usePopup()
  return (
    <button onClick={hidePopup} style={{padding: "0px"}}>
      {icon ? (
        <img src={icon} style={{fill: "#334050", width: "20pt"}} />
      ) : (
        <CloseIcon width="20pt" color="#334050" />
      )}
    </button>
  )
}

export {Popup, ClosePopupButton}
