import {Doughnut} from "react-chartjs-2"
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js"
import {useEffect, useRef, useState} from "react"

ChartJS.register(ArcElement, Tooltip, Legend)

export default function PieChart({data, type}) {
  const [categoryTotal, setCategoryTotal] = useState([])
  const [categoryNames, setCategoryNames] = useState([])
  const [total, setTotal] = useState(0)
  const [breakdownType, setBreakdownType] = useState("")
  const [colors, setColors] = useState([])

  const chartRef = useRef(null)

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update()
    }
  }, [total])

  useEffect(() => {
    if (data && data.length > 0) {
      const totals = data.map((category) => parseFloat(category.total_amount))
      const names = data.map((category) => category.category_name)
      const baseColor =
        type === "EXPENSE" ? "rgba(51, 64, 80, X)" : "rgba(2, 220, 162, X)"
      const colors = totals.map((_, i) => {
        const opacityVariation = 0.7 / (data.length - 0.9999) || 0
        return baseColor.replace("X", `${1 - opacityVariation * i}`)
      })

      setCategoryNames(names)
      setCategoryTotal(totals)
      setColors(colors)
      setTotal(totals.reduce((acc, curr) => acc + curr, 0))
      setBreakdownType(type === "EXPENSE" ? "Expenses" : "Income")
    } else {
      setCategoryNames(["No transactions"])
      setCategoryTotal([1])
      setColors(["rgba(200, 200, 200, 1)"])
      setTotal(0)
      setBreakdownType(type === "EXPENSE" ? "Expenses" : "Income")
    }
  }, [data, type])

  const optionObject = {
    cutout: "85%",
    plugins: {
      legend: {
        display: false,
      },
      dataLabels: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
  }

  const dataObject = {
    labels: categoryNames,
    datasets: [
      {
        label: "Total",
        data: categoryTotal,
        borderRadius: 90,
        spacing: 4,
        backgroundColor: colors,
      },
    ],
  }

  const centerDivStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  }

  return (
    <div className="breakdown-chart-container" style={{position: "relative"}}>
      <Doughnut ref={chartRef} data={dataObject} options={optionObject} />
      <div style={centerDivStyle}>
        <div style={{fontSize: "18px", color: "rgb(51, 64, 80)"}}>
          {breakdownType}
        </div>
        <div
          style={{
            fontSize: "30px",
            color: "#334050",
            fontWeight: "bold",
          }}
        >
          {`${total.toFixed(2)}€`}
        </div>
      </div>
    </div>
  )
}
