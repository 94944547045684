import "./Users.css"
const UserRow = ({user}) => {
  const splitLastActivity = user.last_activity.split(" ")
  return (
    <div className="user-row">
      <div className="user-left">
        <h3>{user.username}</h3>
        <p>{user.email}</p>
      </div>
      <div className="user-right">
        <p className="last-activity-day">{splitLastActivity[1]}</p>
        <p className="last-activity-time">{splitLastActivity[0]}</p>
      </div>
    </div>
  )
}

export {UserRow}
