import {useEffect, useState} from "react"
import {useOnboarding} from "../../contexts/OnboardingContext"
import CloseIcon from "../icons/CloseIcon"
import "./Onboarding.css"
import PrimaryButton from "../buttons/PrimaryButton"
import SecondaryButton from "../buttons/SecondaryButton"
import {useResponsive} from "../../contexts/ResponsiveContext"

import ReportIssue from "../settings/ReportIssue"
import Onboarding_WelcomeStep from "./onboarding_steps/0_Welcome"
import Onboarding_HowItWorks from "./onboarding_steps/1_HowItWorks"
import Onboarding_GoodLuck from "./onboarding_steps/2_GoodLuck"

const OnboardingPopup = () => {
  const {onboardingStep, NUMBER_OF_STEPS} = useOnboarding()
  const {isTablet} = useResponsive()
  const [stepContent, setStepContent] = useState()

  useEffect(() => {
    switch (onboardingStep) {
      case 0:
        setStepContent(<Onboarding_WelcomeStep />)
        break
      case 1:
        setStepContent(<Onboarding_HowItWorks />)
        break
      case 2:
        setStepContent(<Onboarding_GoodLuck />)
        break
    }
  }, [onboardingStep])

  return (
    <div className="onboarding-container">
      <div className="onboarding-popup">
        <div className="popup-header">
          <h4>
            Onboarding tutorial ({onboardingStep + 1}/{NUMBER_OF_STEPS + 1})
          </h4>
          <CloseOnboardingPopupButton />
        </div>
        <div className="onboarding-content">
          {stepContent && stepContent}

          {!isTablet && <OnboardingIndex />}
          {isTablet && <OnboardingPopupFooter />}
        </div>
      </div>
    </div>
  )
}

const CloseOnboardingPopupButton = () => {
  const {hidePopup} = useOnboarding()
  return (
    <button onClick={hidePopup}>
      <CloseIcon width="24px" color={"#334050"} />
    </button>
  )
}

const OnboardingPopupFooter = () => {
  const {increaseStep, decreaseStep, onboardingStep, NUMBER_OF_STEPS} =
    useOnboarding()

  return (
    <div
      className="onboarding-popup-footer"
      style={{display: "flex", gap: "1rem"}}
    >
      <SecondaryButton
        buttonText={"Previous"}
        onClick={() => decreaseStep()}
        textColor="var(--primary)"
      />
      <PrimaryButton
        buttonText={onboardingStep !== NUMBER_OF_STEPS ? "Next" : "Close"}
        onClick={() => increaseStep()}
        borderWidth={"2px"}
      />
    </div>
  )
}

const OnboardingIndex = () => {
  return (
    <div className="onboarding-index">
      <div>
        <h3> Index </h3>
        <div className="index">
          <IndexItem step={0} stepName={"Welcome"}></IndexItem>
          <IndexItem step={1} stepName={"How it works?"}></IndexItem>
          <IndexItem step={2} stepName={"Try it out!"}></IndexItem>
        </div>
        <OnboardingPopupFooter />
      </div>
      <ReportIssue title={"Any doubts? "} text="Get in touch" />
    </div>
  )
}

const IndexItem = ({children, step, stepName}) => {
  const {onboardingStep, setOnboardingStep} = useOnboarding()
  return (
    <div className={`index-item ${step === onboardingStep ? "active" : ""}`}>
      <p onClick={() => setOnboardingStep(step)}>{stepName}</p>
      {children}
    </div>
  )
}

export {OnboardingPopup}
